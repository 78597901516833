import { Form } from "react-bootstrap";
import styled from "styled-components";
import colors from "../../theme/colors";
import Button from "../Button";
import DatePicker from "../DatePicker";
import Checkbox from "../Checkbox";
import PhoneNumberInput from "../PhoneNumberInput";

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const ErrorContainer = styled.div`
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const SendToContainer = styled.div`
  margin-bottom: 10px;
  > div {
    margin-bottom: 8px;
    &:not(:first-of-type) {
      margin-bottom: 5px !important;
    }
  }

  input,
  label {
    cursor: pointer;
  }
`;

const StyledCheckbox = styled.input.attrs((props) => ({
  type: props.type,
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const StyledCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin: 10px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    span {
      border-width: 1px;
    }
  }
`;

const StyledCheckboxCheckmark = styled.span`
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid ${colors.purple};
  background-color: ${(props) => (props.checked ? colors.purple : "#fff")};
  border-radius: 50%;
`;

const StyledCheckboxLabel = styled.span`
  width: 100%;
  border: ${(props) =>
    props.checked ? `1px solid ${colors.purple}` : "1px solid #F1EDFF"};
  padding: 7px;
  border-radius: 5px;
`;

const NewSurveyForm = ({
  validated,
  onSubmit,
  onCancel,
  submitting,
  submittingError,
  survey,
  patient,
  setSurvey,
}) => {
  let insuranceTextValue = "No Insurance";

  if (
    !patient.hasNoInsurance &&
    patient.primaryInsurance &&
    patient.primaryInsurance.id
  ) {
    insuranceTextValue = patient.primaryInsurance.id;
  }

  return (
    <Form noValidate validated={validated} onSubmit={onSubmit}>
      <div>
        To create a new survey for this patient, fill out the information below.
      </div>
      <br />
      <div>
        <b>Select all survey panels you would like to include:</b>
        <div>You must select at least one</div>
      </div>
      <div>
        {survey.panelOptions.map((panel) => {
          return (
            <StyledCheckboxContainer key={panel.name} checked={panel.selected}>
              <StyledCheckboxLabel checked={panel.selected}>
                {panel.name}
              </StyledCheckboxLabel>
              <StyledCheckbox
                type="checkbox"
                value={panel.name}
                checked={panel.selected}
                onChange={(e) => {
                  panel.selected = e.target.checked;
                  debugger;
                  if (!survey.panelOptions.filter((p) => p.selected).length) {
                    survey.panelOptions[0].selected = true;
                  }
                  setSurvey({ ...survey });
                }}
              />
              <StyledCheckboxCheckmark
                checked={panel.selected}
              ></StyledCheckboxCheckmark>
            </StyledCheckboxContainer>
          );
        })}
      </div>

      <Form.Group className="mb-3" controlId="addSurveyForm.appointmentDate">
        <Form.Label>Date of Appointment</Form.Label>
        <Form.Control
          style={{ display: "none" }}
          required
          type="text"
          value={survey.appointmentDate}
          onChange={(value) => {}}
        />

        <DatePicker
          value={survey.appointmentDate}
          minDate={new Date()}
          onChange={(value) => {
            value = value || "";
            setSurvey({
              ...survey,
              appointmentDate: value,
            });
          }}
        />

        <Form.Control.Feedback type="invalid">
          Date of Appointment Required
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="addSurveyForm.name">
        <Form.Label>Patient Name</Form.Label>
        <Form.Control
          disabled
          type="text"
          value={`${patient.firstName} ${patient.lastName}`}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="addSurveyForm.primaryInsuranceId">
        <Form.Label>Primary Insurance ID</Form.Label>
        <Form.Control disabled type="text" value={insuranceTextValue} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="addSurveyForm.email">
        <Form.Label>Patient Email Address</Form.Label>
        <Form.Control disabled type="email" value={patient.email} />
      </Form.Group>

      <PhoneNumberInput
        disabled
        value={patient.phone}
        label="Patient Phone Number"
        onChange={() => {}}
      />

      <SendToContainer>
        <div>Send Survey to</div>

        <Form.Group className="mb-3" controlId="addSurveyForm.sendEmail">
          <Form.Check
            type="checkbox"
            checked={survey.sendPatientEmail}
            label="Email"
            onChange={(e) => {
              setSurvey({
                ...survey,
                sendPatientEmail: e.target.checked,
              });
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="addSurveyForm.sendText">
          <Form.Check
            type="checkbox"
            checked={survey.sendPatientText}
            label="Phone"
            onChange={(e) => {
              setSurvey({
                ...survey,
                sendPatientText: e.target.checked,
              });
            }}
          />
        </Form.Group>
      </SendToContainer>

      <SubmitContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <Button type="submit" disabled={submitting}>
          Send
        </Button>
      </SubmitContainer>
      <ErrorContainer>{submittingError}</ErrorContainer>
    </Form>
  );
};

export default NewSurveyForm;
