import { useEffect, useState } from "react";
import queryString from "query-string";
import styled from "styled-components";
import colors from "../../theme/colors";
import API from "../../api";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import Breadcrumbs from "../../components/BreadCrumbs";
import { Table, Form, Dropdown, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import Models from "../../models";
import { addPopupNotification } from "../../components/PopupNotifications";

const OrganizationContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TableActionsDropdown = styled(Dropdown)`
  .dropdown-toggle:after {
    display: none;
  }
`;

const ErrorContainer = styled.div`
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

const TableHeaderCell = styled.th`
  border: none;
  color: #a1a1a1 !important;
  background-color: #fafafa !important;
  font-weight: normal;
`;

const TableCell = styled.td`
  vertical-align: middle;
  white-space: nowrap;
`;

const TableBody = styled.tbody`
  border-top: none !important;
`;

const CardBody = styled(Card.Body)`
  overflow: scroll;
`;

const CardCustom = styled(Card)`
  margin-bottom: 50px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const DashboardStatsContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  gap: 50px;
`;

const DashboardStat = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StatType = styled.div``;

const StatValue = styled.div`
  color: ${colors.purple};
  font-size: 45px;
  padding: 0 20px;
`;

const Organization = () => {
  //Redux state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //State
  const [organizationId, setOrganizationId] = useState();
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(false);
  const [crumbs, setCrumbs] = useState([]);

  const _organization = new Models.Organization({
    id: organizationId,
  });

  const [organization, setOrganization] = useState({
    ..._organization,
  });

  const [newProvider, setNewProvider] = useState({
    name: "",
    email: "",
    npi: "",
  });
  const [showAddProvider, setShowAddProvider] = useState(false);

  //Effects
  useEffect(() => {
    console.log("Organization Did Mount");
  }, []);

  useEffect(() => {
    if (organizationId) {
      data.getOrganization();
    }
  }, [organizationId]);

  useEffect(() => {
    if (user && user.role === "ADMIN") {
      const _organizationId = localStorage.organizationId;
      if (!_organizationId) {
        window.location = "/";
        return;
      }
      setOrganizationId(_organizationId);
    } else if (user && user.organizationId) {
      setOrganizationId(user.organizationId);
    }
  }, [user]);

  useEffect(() => {
    if (organization && organization.name && user.role === "ADMIN") {
      setCrumbs([
        {
          title: "Administration",
          link: `/`,
        },
        {
          title: organization.name,
          link: `/organization`,
        },
      ]);
    }
  }, [organization, user]);

  //Data API
  const data = {
    getOrganization: async () => {
      if (!organizationId) {
        return;
      }
      const _organization = await API.Organization.OrganizationGet({
        organizationId,
      });
      setOrganization(_organization);
    },
  };

  //Handlers
  const handlers = {
    addProviderOnClick: async () => {
      setShowAddProvider(true);
    },
    addProviderClose: async () => {
      setShowAddProvider(false);
      setValidated(false);
      setSubmittingError("");
      setNewProvider({
        name: "",
        email: "",
        npi: "",
      });
    },
    addProviderSubmit: async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const createUserData = {
        name: newProvider.name,
        email: newProvider.email,
        npi: newProvider.npi,
        role: "PROVIDER",
        organizationId: organization.id,
      };

      const form = e.currentTarget;
      const valid = form.checkValidity();
      setValidated(true);
      if (valid) {
        setSubmitting(true);
        try {
          const res = await API.User.UserCreate({
            createUserData,
          });
          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Success!",
            message: "Provider added to your organization.",
            type: "success",
          });
          handlers.addProviderClose();
          data.getOrganization();
        } catch (err) {
          console.log(err);
          setSubmitting(false);
          switch (err) {
            case "UsernameExistsException":
              setSubmittingError("Account with that Email already exists");
              addPopupNotification({
                dispatch,
                title: "Action Failed",
                message:
                  "Provider was not added. Account with that email already exists. please try again.",
                type: "danger",
              });
              break;
            default:
              addPopupNotification({
                dispatch,
                title: "Action Failed",
                message: "Provider was not added, please try again.",
                type: "danger",
              });
              setSubmittingError("Error Saving");
              break;
          }
        }
      } else {
        addPopupNotification({
          dispatch,
          title: "Warning",
          message: "Please check required fields.",
          type: "warning",
        });
      }
    },
  };

  return (
    <>
      <OrganizationContainer>
        <Breadcrumbs crumbs={crumbs} />
        <PageHeader header1="Organization" header2={organization.name} />

        <DashboardStatsContainer>
          <DashboardStat>
            <StatType>
              Number of
              <br /> Providers
            </StatType>
            <StatValue>
              {(organization.providers && organization.providers.length) || "0"}
            </StatValue>
          </DashboardStat>
          <DashboardStat>
            <StatType>
              Number of
              <br /> Patients
            </StatType>
            <StatValue>{organization.patientCount || "0"}</StatValue>
          </DashboardStat>
          <DashboardStat>
            <StatType>
              Total Surveys
              <br />
              Outstanding
            </StatType>
            <StatValue>{organization.surveyCount || "0"}</StatValue>
          </DashboardStat>
        </DashboardStatsContainer>

        <CardCustom>
          <Card.Header style={{ background: "white" }}>
            <TableHeaderContainer>
              <h4>Providers</h4>
              <Button onClick={handlers.addProviderOnClick}>
                Add Provider
              </Button>
            </TableHeaderContainer>
          </Card.Header>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <TableHeaderCell>Provider Name</TableHeaderCell>
                  <TableHeaderCell>Patients</TableHeaderCell>
                  <TableHeaderCell>
                    Surveys
                    <br />
                    Outstanding
                  </TableHeaderCell>
                  <TableHeaderCell>
                    Surveys
                    <br />
                    Need Review
                  </TableHeaderCell>
                  <TableHeaderCell>
                    Surveys
                    <br />
                    Ready for Billing
                  </TableHeaderCell>
                  <TableHeaderCell>
                    Surveys
                    <br />
                    Waiting Payment
                  </TableHeaderCell>
                  <TableHeaderCell>
                    Surveys
                    <br />
                    Paid
                  </TableHeaderCell>
                  <TableHeaderCell>NPI</TableHeaderCell>
                </tr>
              </thead>
              <TableBody>
                {organization.providers.map((provider, index) => {
                  return (
                    <tr key={`provider-${index}`}>
                      <TableCell>
                        <Link to={`/provider/${provider.id}`}>
                          {provider.name}
                        </Link>
                      </TableCell>
                      <TableCell>{provider.patientCount}</TableCell>
                      <TableCell>
                        {provider.surveyMetrics.surveysPending}
                      </TableCell>
                      <TableCell>
                        {provider.surveyMetrics.surveysSubmitted}
                      </TableCell>
                      <TableCell>
                        {provider.surveyMetrics.surveysReviewd}
                      </TableCell>
                      <TableCell>
                        {provider.surveyMetrics.surveysBilled}
                      </TableCell>
                      <TableCell>
                        {provider.surveyMetrics.surveysPaid}
                      </TableCell>
                      <TableCell>{provider.npi}</TableCell>
                      {/*<TableCell>
                        <TableActionsDropdown align="end">
                          <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <FontAwesomeIcon
                              style={{ color: "#D8D8D8", fontSize: "25px" }}
                              size="lg"
                              icon={faEllipsisH}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                        <Dropdown.Item>Disable</Dropdown.Item>
                      </Dropdown.Menu>
                        </TableActionsDropdown>
                      </TableCell>*/}
                    </tr>
                  );
                })}
              </TableBody>
            </Table>
          </CardBody>
        </CardCustom>
      </OrganizationContainer>

      <Modal
        show={showAddProvider}
        headerText={"Add New Provider"}
        onClose={handlers.addProviderClose}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handlers.addProviderSubmit}
        >
          <Form.Group className="mb-3" controlId="addProviderForm.name">
            <Form.Label>Provider Name</Form.Label>
            <Form.Control
              required
              type="text"
              value={newProvider.name}
              placeholder="enter name"
              onChange={(e) =>
                setNewProvider({ ...newProvider, name: e.target.value })
              }
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="addProviderForm.email">
            <Form.Label>Provider Email</Form.Label>
            <Form.Control
              required
              type="email"
              value={newProvider.email}
              placeholder="enter email"
              onChange={(e) =>
                setNewProvider({ ...newProvider, email: e.target.value })
              }
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="addProviderForm.npi">
            <Form.Label>Provider NPI</Form.Label>
            <Form.Control
              required
              type="text"
              minLength={10}
              maxLength={10}
              value={newProvider.npi}
              placeholder="enter npi"
              onChange={(e) =>
                setNewProvider({ ...newProvider, npi: e.target.value })
              }
            />
            <Form.Control.Feedback type="invalid">
              NPI length required to be 10 characters
            </Form.Control.Feedback>
          </Form.Group>
          <SubmitContainer>
            <CancelButton onClick={handlers.addProviderClose}>
              Cancel
            </CancelButton>
            <Button type="submit" disabled={submitting}>
              Save
            </Button>
          </SubmitContainer>
          <ErrorContainer>{submittingError}</ErrorContainer>
        </Form>
      </Modal>
    </>
  );
};

export default Organization;
