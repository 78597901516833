import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import store from "../../redux-store";

const NotificationWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 20px;
  right: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const PopupNotifications = () => {
  const popupNotifications = useSelector((state) => state.popupNotifications);
  const dispatch = useDispatch();
  return (
    <NotificationWrapper>
      {popupNotifications.popupMessages.map((popupMessage, i) => {
        return (
          <Alert
            key={`popup-${i}`}
            variant={popupMessage.type}
            onClose={() => {
              dispatch({
                type: "POPUP_NOTIFICATION_REMOVE",
                data: popupMessage,
              });
            }}
            dismissible
          >
            {popupMessage.message}
          </Alert>
        );
      })}
    </NotificationWrapper>
  );
};

const addPopupNotification = ({ dispatch, message, type }) => {
  const notification = {
    id: new Date().getTime(),
    message,
    type,
    remove: () => {
      dispatch({
        type: "POPUP_NOTIFICATION_REMOVE",
        data: notification,
      });
    },
  };
  dispatch({ type: "POPUP_NOTIFICATION_ADD", data: notification });
  return null;
};

export { PopupNotifications, addPopupNotification };
