class Organization {
  constructor({ id }) {
    this.id = id;
    this.name = "";
    this.created = "";
    this.createdByUserId = "";
    this.providers = [];
    this.staff = [];
    this.patients = [];
    this.surveys = [];
    this.members = [];
    this.patientCount = 0;
    this.pendingInvites = 0;
  }
}

export default Organization;
