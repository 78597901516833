import styled from "styled-components";
import colors from "../../theme/colors";

const SubheaderContainer = styled.div`
  font-size: 14px;
  margin: 30px 0;
  color: #a1a1a1;
`;

const PageSubheader = ({ children }) => {
  return <SubheaderContainer>{children}</SubheaderContainer>;
};

export default PageSubheader;
