class Vitals {
  constructor({
    id = "",
    temperature = "",
    height = "",
    weight = "",
    bloodpressure = "",
    bmi = "",
    visualacuity = ""
  }) {
    this.id = id;
    this.temperature = temperature;
    this.height = height;
    this.weight = weight;
    this.bloodpressure = bloodpressure;
    this.bmi = bmi;
    this.visualacuity = visualacuity;
  }
}

export default Vitals;
