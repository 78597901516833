import { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import colors from "../../theme/colors";
import { useSelector, useDispatch } from "react-redux";
import API from "../../api";
import { useParams } from "react-router-dom";
import { DateHelper } from "../../helpers";
import SurveyVitalsForm from "../../components/SurveyVitalsForm";
import Modal from "../../components/Modal";
import Models from "../../models";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import Breadcrumbs from "../../components/BreadCrumbs";
import SurveyStatus from "../../components/SurveyStatus";
import {
  Table,
  Card,
  Accordion,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import NoRiskImage from "../../theme/images/norisk-icon.svg";
import RiskImage from "../../theme/images/risk-icon.svg";
import UnansweredImage from "../../theme/images/pencil-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { addPopupNotification } from "../../components/PopupNotifications";
import ActivityLog from "../../components/ActivityLog";

const noPrintMixIn = css`
  @media print {
    display: none !important;
    * {
      display: none !important;
    }
  }
`;

const ModalBody = styled.div`
  margin: 30px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const PatientInfoBoxContainer = styled.div`
  display: felx;
  flex-wrap: wrap;
  gap: 10px;
`;

const InfoBox = styled.div`
  margin-top: 10px;
  display: flex;
  border-radius: 5px;
  padding: 10px;
  gap: 10px;
  background-color: ${colors.lightgray};
`;

const InfoLabel = styled.div`
  color: #949698;
`;
const InfoValue = styled.div``;

const NotesTextArea = styled.textarea.attrs({
  "vertical-align": "top",
})`
  width: 100%;
  min-height: 200px;
  margin-bottom: 20px;
`;

const Purple = styled.p`
  color: ${colors.purple};
`;

const NotesContainer = styled.div`
  margin: 20px 0 5px 0;
  background: ${colors.lightgray};
  border-radius: 4px;
  padding: 5px;
  min-height: 100px;
`;

const Subheader = styled.div`
  font-size: 12px;
  color: ${colors.gray};
`;

const SideBySideCards = styled.div`
  display: flex;
  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
  gap: 10px;
`;

const SurveyPageContainer = styled.div`
  min-height: 200px;
  margin: 20px;
  @media print {
    margin: 0 !important;
  }
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

  h5 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
`;

const TableHeaderContainerContent = styled.div`
  display: flex;
  button {
    margin-left: 10px;
  }
`;

const CardBody = styled(Card.Body)`
  overflow: scroll;
`;

const CardCustom = styled(Card)`
  width: 100%;
  margin-bottom: 20px;
  @media print {
    margin-bottom: 0px !important;
  }
`;

const PatientInfoLineItem = styled.div`
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  padding: 12px;
  margin: 12px 0;
`;

const TableNoRecordsPlaceHolderContainer = styled.div`
  margin: 20px 10px;
  min-height: 233px;
  color: #ccc;
  > * {
    margin-bottom: 10px;
  }
`;

//PAGE components
const SurveyResultsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SurveyResultsHeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const SurveyResultsHeader = styled.div`
  font-size: 30px;
  color: #c8c8c8;
  @media print {
    color: black;
  }
`;

const ExpandAllButton = styled.div`
  cursor: pointer;
  text-decoration: underline;
  margin: 0 6px;
  font-size: 14px;
  color: #a1a1a1;
  @media print {
    display: none !important;
  }
`;

const PrintButton = styled(Button)`
  @media print {
    display: none !important;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

const SideBarContainer = styled.div`
  min-width: 300px;
  max-width: 400px;
  margin-right: 20px;
  @media (max-width: 800px) {
    max-width: 100%;
    margin-right: 0px;
  }
  ${noPrintMixIn}
`;

const SurveyResultsContainer = styled.div`
  width: 100%;
`;

const SurveyActionBarContainer = styled.div`
  @media print {
    display: none !important;
  }
`;

const PanelSectionContainer = styled.div`
  margin: 10px 0 20px 0px;
`;

const PanelSectionTitle = styled.div`
  font-size: 17px;
  border-bottom: 1px solid #d3d3d3;
`;

const SectionQuestionContainer = styled.div`
  margin: 10px;
`;

const QuestionTitle = styled.span`
  margin-right: 5px;
  color: #585858;
  font-size: 15px;
  font-weight: bold;
`;

const QuestionAnswer = styled.div`
  color: #585858;
  font-size: 15px;
`;

const QuestionAnswerFlex = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 15px 0;
  color: #585858;
  font-size: 15px;
`;

const DynamicTable = styled(Table)`
  margin: 0;
`;
const DynamicTableHead = styled.thead``;
const DynamicTableBody = styled.tbody``;
const DynamicTableRow = styled.tr``;
const DynamicTableHeader = styled.th`
  border: none;
  color: #a1a1a1 !important;
  background-color: #fafafa !important;
  font-weight: normal;
`;
const DynamicTableCell = styled.td``;

const PanelPageContainer = styled(Accordion)`
  margin: 10px 0;
`;

const CustomAccordionHeader = styled(Accordion.Header)`
  button {
    color: #a1a1a1;
    background: #f7f7f7;
    background-color: #f7f7f7;
  }
`;
const CustomAccordionHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;

const CustomAccordionBody = styled(Accordion.Body)`
  background: #f7f7f7;
  background-color: #f7f7f7;
  @media print {
    background: white !important;
    background-color: white !important;
  }
`;

const PanelTitle = styled.div`
  margin: 10px 0;
  font-size: 14px;
`;

const PanelTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-top: 20px;
`;

const PanelRiskIcon = styled.img`
  margin-right: 10px;
  height: 20px;
  width: 20px;
`;

const QuestionRiskIcon = styled.img`
  margin: 0 10px 2px 0;
  height: 14px;
  width: 14px;
  cursor: pointer;
`;

const CurrentMedsText = styled.div`
  margin: 10px 0;
  font-size: 14px;
  color: #a1a1a1;
`;

const renderTooltip = ({ text, ...props }) => (
  <Tooltip id="button-tooltip" show={true} {...props}>
    {text}
  </Tooltip>
);

const RiskIcon = ({
  tooltip = "Answer marked as risk",
  inPanelHeader = false,
}) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 100 }}
      overlay={renderTooltip({
        text: tooltip,
      })}
      defaultShow={false}
    >
      {inPanelHeader ? (
        <PanelRiskIcon
          src={RiskImage}
          alt="questions marked for risk in panel"
        />
      ) : (
        <QuestionRiskIcon
          src={RiskImage}
          alt="questions marked for risk in panel"
        />
      )}
    </OverlayTrigger>
  );
};

const NoRiskIcon = ({
  tooltip = "No risk detected",
  inPanelHeader = false,
}) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 100 }}
      overlay={renderTooltip({
        text: tooltip,
      })}
      defaultShow={false}
    >
      {inPanelHeader ? (
        <PanelRiskIcon src={NoRiskImage} alt="No risk detected in panel" />
      ) : (
        <QuestionRiskIcon src={NoRiskImage} alt="No risk detected in panel" />
      )}
    </OverlayTrigger>
  );
};

const UnansweredIcon = ({
  tooltip = "Unaswered question",
  inPanelHeader = false,
}) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 100 }}
      overlay={renderTooltip({
        text: tooltip,
      })}
      defaultShow={false}
    >
      {inPanelHeader ? (
        <PanelRiskIcon
          src={UnansweredImage}
          alt="unanswered questions in panel"
        />
      ) : (
        <QuestionRiskIcon
          src={UnansweredImage}
          alt="unanswered questions in panel"
        />
      )}
    </OverlayTrigger>
  );
};

const Survey = () => {
  //Redux State
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //Utils
  const { surveyId } = useParams();

  //State
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(false);
  const [crumbs, setCrumbs] = useState([]);
  const [expandAll, setExpandAll] = useState(true);
  const [currentMeds, setCurrentMeds] = useState([]);
  const [editNotes, setEditNotes] = useState({
    show: false,
    notes: "",
  });

  const [showConfirmReviewdModal, setShowConfirmReviewdModal] = useState(false);

  const [editAssessment, setEditAssessment] = useState({
    show: false,
    assessment: "",
  });

  const [editPlan, setEditPlan] = useState({
    show: false,
    plan: "",
  });
  const [editVitals, setEditVitals] = useState({
    show: false,
    vitals: new Models.Vitals({}),
  });
  const [survey, setSurvey] = useState({});
  const [activityLog, setActivityLog] = useState([]);

  //Data API
  const data = {
    getSurvey: async () => {
      if (!surveyId) {
        return;
      }

      let _surveyRes = await API.Survey.SurveyGet({
        surveyId,
      });

      let status = "N/A";
      switch (_surveyRes.status) {
        case "PENDING":
          status = "Outstanding";
          break;
        case "SUBMITTED":
          status = "Needs Review";
          break;
        case "REVIEWED":
          status = "Ready for Billing";
          break;
        case "BILLED":
          status = "Waiting Payment";
          break;
        case "PAID":
          status = "Paid";
          break;
        default:
          status = "N/A";
      }

      _surveyRes.statusString = status;

      if (_surveyRes.status !== "PENDING") {
        let ippePanel = _surveyRes.panels.find((p) => p.type === "IPPE");
        let pages = [];
        let currentMeds = [];
        if (ippePanel) {
          //combine all the pages with the same title for the IPPE survey
          ippePanel.pages.forEach((page, pageIndex) => {
            if (pageIndex === 0) {
              pages.push(JSON.parse(JSON.stringify(page)));
            } else {
              //does page with same title exist in pages? if so combine the sections
              const combinedPage = pages.find((p) => p.title === page.title);

              if (combinedPage) {
                combinedPage.sections = [
                  ...combinedPage.sections,
                  ...page.sections,
                ];
              } else {
                pages.push(JSON.parse(JSON.stringify(page)));
              }
            }
          });

          ippePanel.pages = pages;

          const currentHealthPage = ippePanel.pages.find(
            (p) => p.title === "Current Health"
          );
          if (currentHealthPage) {
            const medsSection = currentHealthPage.sections.find(
              (s) => s.title === "Current Medications"
            );
            if (medsSection && medsSection.questions) {
              setCurrentMeds(medsSection.questions[0]);
            }
          }
        }
        //Look for unanswered questions
        //Look for risks to mark a panel page as having a risk
        _surveyRes.panels.forEach((panel) => {
          panel.pages.forEach((page) => {
            page.risk = false;
            page.unansweredQuestions = false;
            page.sections.forEach((section) => {
              section.questions = section.questions.filter((q) => {
                if (
                  q.requiredGender &&
                  q.requiredGender !== _surveyRes.patient.gender
                ) {
                  return false;
                } else {
                  return true;
                }
              });
              section.questions.forEach((question) => {
                //look for risk factors in answers
                //look for unanswered questions
                switch (question.type) {
                  case "multiple-select":
                    //risk
                    const risks = question.options.filter(
                      (o) => o.answer === true && o.risk
                    );
                    if (risks.length > 0) {
                      page.risk = true;
                    }
                    //unanswered questions
                    if (
                      question.options.filter((o) => o.answer === true)
                        .length === 0
                    ) {
                      page.unansweredQuestions = true;
                    }

                    break;

                  case "single-select":
                    //risk
                    const option = question.options.find(
                      (o) => o.value === question.answer
                    );

                    if (option && option.risk) {
                      page.risk = true;
                    }

                    //unanswered questions
                    if (!option) {
                      page.unansweredQuestions = true;
                    }

                    break;

                  case "dynamic":
                    //Leave this out for now, it's hard to tell if these are unanswered
                    // if (!question.answers) {
                    //   page.unansweredQuestions = true;
                    // }
                    break;
                  default: //blank
                    if (!question.answer) {
                      page.unansweredQuestions = true;
                    }

                    break;
                }
              });
            });
          });
        });
      }

      setSurvey({
        ..._surveyRes,
        vitals: new Models.Vitals({ ..._surveyRes.vitals }),
      });
      setEditNotes({
        show: false,
        notes: _surveyRes.notes,
      });
      let vitals = new Models.Vitals({ ..._surveyRes.vitals });
      setEditVitals({
        show: false,
        vitals,
      });
    },
    getActivity: async () => {
      if (!surveyId) {
        return;
      }

      let _surveyActivity = await API.Activity.ActivityGet({
        type: "patient-survey",
        typeId: surveyId,
      });

      setActivityLog(_surveyActivity);
    },
  };

  //Effects
  useEffect(() => {
    console.log("Survey Did Mount");
    data.getSurvey();
    data.getActivity();
    return () => {
      document.title = "HCRpath Portal";
    };
  }, []);

  useEffect(() => {
    console.log(currentMeds);
  }, [currentMeds]);

  useEffect(() => {
    if (survey && survey.patient) {
      document.title = `${survey.patient.firstName} ${
        survey.patient.lastName
      } ${DateHelper.formatUTCEpochDateMMDDYYYY(survey.appointmentDate)}`;
    }
  }, [survey]);

  useEffect(() => {
    if (survey.patient && survey.provider && survey.organization) {
      if (user && user.role === "ADMIN") {
        setCrumbs([
          {
            title: "Administration",
            link: `/`,
          },
          {
            title: survey.organization.name,
            link: `/organization`,
          },
          {
            title: survey.provider.name,
            link: `/provider/${survey.provider.id}`,
          },
          {
            title: `${survey.patient.firstName} ${survey.patient.lastName}`,
            link: `/patient/${survey.patient.id}`,
          },
          {
            title: `Survey ${DateHelper.formatUTCEpochDateMMDDYYYY(
              survey.appointmentDate
            )}`,
            link: `/survey/${survey.id}`,
          },
        ]);
      } else {
        setCrumbs([
          {
            title: survey.organization.name,
            link: `/organization`,
          },
          {
            title: survey.provider.name,
            link: `/provider/${survey.provider.id}`,
          },
          {
            title: `${survey.patient.firstName} ${survey.patient.lastName}`,
            link: `/patient/${survey.patient.id}`,
          },
          {
            title: `Survey ${DateHelper.formatUTCEpochDateMMDDYYYY(
              survey.appointmentDate
            )}`,
            link: `/survey/${survey.id}`,
          },
        ]);
      }
    }
  }, [survey, user]);

  //Handlers
  const handlers = {
    completeInPersonClick: async () => {
      window.open(
        `/patient-survey/${survey.id}/${survey.patient.lastName}/${
          survey.patient.phone || "0000000000"
        }/${survey.patient.dateOfBirth}`,
        "_blank"
      );
    },
    editNotesClick: async () => {
      setEditNotes({
        show: true,
        notes: survey.notes,
      });
    },
    editNotesClose: async () => {
      setValidated(false);
      setEditNotes({
        show: false,
        notes: survey.notes,
      });
    },
    editNotesSubmit: async () => {
      try {
        setSubmitting(true);
        let _res = await API.Survey.SurveyUpdateNotes({
          surveyId,
          notes: editNotes.notes || " ",
        });
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Success!",
          message: "Survey notes saved.",
          type: "success",
        });
        console.log(_res);
        setEditNotes({
          ...editNotes,
          show: false,
        });
        data.getSurvey();
      } catch (err) {
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Action Failed",
          message: "Survey notes not saved, please try again.",
          type: "danger",
        });
        console.log(err);
      }
    },

    editAssessmentClick: async () => {
      setEditAssessment({
        show: true,
        assessment: survey.assessment,
      });
    },
    editAssessmentClose: async () => {
      setValidated(false);
      setEditAssessment({
        show: false,
        assessment: survey.assessment,
      });
    },
    editAssessmentSubmit: async () => {
      try {
        setSubmitting(true);
        let _res = await API.Survey.SurveyUpdateAssessment({
          surveyId,
          assessment: editAssessment.assessment || " ",
        });
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Success!",
          message: "Survey assessment saved.",
          type: "success",
        });
        console.log(_res);
        setEditAssessment({
          ...editAssessment,
          show: false,
        });
        data.getSurvey();
      } catch (err) {
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Action Failed",
          message: "Survey assessment not saved, please try again.",
          type: "danger",
        });
        console.log(err);
      }
    },

    editPlanClick: async () => {
      setEditPlan({
        show: true,
        plan: survey.plan,
      });
    },
    editPlanClose: async () => {
      setValidated(false);
      setEditPlan({
        show: false,
        plan: survey.plan,
      });
    },
    editPlanSubmit: async () => {
      try {
        setSubmitting(true);
        let _res = await API.Survey.SurveyUpdatePlan({
          surveyId,
          plan: editPlan.plan || " ",
        });
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Success!",
          message: "Survey plan saved.",
          type: "success",
        });
        console.log(_res);
        setEditPlan({
          ...editPlan,
          show: false,
        });
        data.getSurvey();
      } catch (err) {
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Action Failed",
          message: "Survey plan not saved, please try again.",
          type: "danger",
        });
        console.log(err);
      }
    },

    editVitalsClick: async () => {
      setEditVitals({
        show: true,
        vitals: { ...survey.vitals },
      });
    },
    editVitalsClose: async () => {
      setValidated(false);
      setEditVitals({
        show: false,
        vitals: { ...survey.vitals },
      });
    },
    editVitalsSubmit: async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const form = e.currentTarget;
      const valid = form.checkValidity();
      setValidated(true);
      if (valid) {
        setSubmitting(true);
        try {
          const res = await API.Survey.SurveyUpdateVitals({
            surveyId,
            vitals: editVitals.vitals,
          });
          console.log(res);
          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Success!",
            message: "Survey vitals saved.",
            type: "success",
          });
          handlers.editVitalsClose();
          data.getSurvey();
        } catch (err) {
          console.log(err);
          setSubmitting(false);
          switch (err) {
            default:
              addPopupNotification({
                dispatch,
                title: "Action Failed",
                message: "Survey vitals not saved, please try again.",
                type: "danger",
              });
              setSubmittingError("Error Saving");
              break;
          }
        }
      }
    },
    markSurveyAsReviewdClick: async () => {
      //Open modal
      setShowConfirmReviewdModal(true);
    },
    submitMarkSurveyAsReviewd: async () => {
      //submit api request
      setShowConfirmReviewdModal(false);
      let _res = await API.Survey.SurveyUpdateStatus({
        surveyId,
        status: "REVIEWED",
      });
      addPopupNotification({
        dispatch,
        title: "Success!",
        message: "Survey Reviewed.",
        type: "success",
      });
      console.log(_res);
      data.getSurvey();
    },
    cancelMarkSurveyAsReviewd: async () => {
      //Close modal
      setShowConfirmReviewdModal(false);
    },
    markSurveyAsBilledClick: async () => {
      let _res = await API.Survey.SurveyUpdateStatus({
        surveyId,
        status: "BILLED",
      });
      addPopupNotification({
        dispatch,
        title: "Success!",
        message: "Survey Billed.",
        type: "success",
      });
      console.log(_res);
      data.getSurvey();
    },
    markSurveyAsPaidClick: async () => {
      let _res = await API.Survey.SurveyUpdateStatus({
        surveyId,
        status: "PAID",
      });
      addPopupNotification({
        dispatch,
        title: "Success!",
        message: "Survey Paid.",
        type: "success",
      });
      console.log(_res);
      data.getSurvey();
    },
    expandAllPanels: async () => {
      const buttons = document.getElementsByClassName("accordion-button");
      for (let button of buttons) {
        if (
          (button.getAttribute("aria-expanded") === "false" && expandAll) ||
          (button.getAttribute("aria-expanded") === "true" && !expandAll)
        ) {
          button.click();
        }
      }
      setExpandAll(!expandAll);
    },
    exportSurvey: async () => {
      const buttons = document.getElementsByClassName("accordion-button");
      for (let button of buttons) {
        if (button.getAttribute("aria-expanded") === "false") {
          button.click();
        }
      }
      setExpandAll(false);
      setTimeout(() => {
        window.print();
      }, 500);
    },
  };

  const renderSummaryQuestion = ({ question, questionIndex }) => {
    const questionTitleLastChar = question.title.substring(
      question.title.length - 1,
      question.title.length
    );

    let questionTitle = question.title;
    if (questionTitleLastChar !== "?" && questionTitleLastChar !== ":") {
      questionTitle = `${question.title}:`;
    }

    switch (question.type) {
      case "multiple-select":
        const options = question.options.filter((o) => o.answer === true);
        if (options.length) {
          return (
            <SectionQuestionContainer>
              <QuestionTitle>{questionTitle}</QuestionTitle>
              <div>
                {options.map((option) => {
                  return (
                    <div>
                      <QuestionAnswerFlex>
                        {option.risk && <RiskIcon />}
                        {option.title}
                      </QuestionAnswerFlex>
                    </div>
                  );
                })}
              </div>
            </SectionQuestionContainer>
          );
        } else {
          return (
            <SectionQuestionContainer>
              <UnansweredIcon />
              <QuestionTitle>{questionTitle}</QuestionTitle>
              <QuestionAnswer>N/A</QuestionAnswer>
            </SectionQuestionContainer>
          );
        }
      case "single-select":
        const option = question.options.find(
          (o) => o.value === question.answer
        );
        if (option) {
          var answer = option ? option.title : "N/A";
        }

        return (
          <SectionQuestionContainer>
            <QuestionTitle>
              {option && option.risk && <RiskIcon />}
              {!answer && <UnansweredIcon />}
              {questionTitle}
            </QuestionTitle>
            <QuestionAnswer>{answer}</QuestionAnswer>
          </SectionQuestionContainer>
        );
      case "dynamic":
        return (
          <SectionQuestionContainer>
            {!question.answers && <UnansweredIcon />}
            <QuestionTitle>{questionTitle}</QuestionTitle>
            <DynamicTable>
              <DynamicTableHead>
                <DynamicTableRow>
                  {question.options.map((header) => {
                    return (
                      <DynamicTableHeader>{header.title}</DynamicTableHeader>
                    );
                  })}
                </DynamicTableRow>
              </DynamicTableHead>
              <DynamicTableBody>
                {question.answers.map((answer) => {
                  return (
                    <DynamicTableRow>
                      {answer.map((item) => {
                        return (
                          <DynamicTableCell>{item.value}</DynamicTableCell>
                        );
                      })}
                    </DynamicTableRow>
                  );
                })}
              </DynamicTableBody>
            </DynamicTable>
          </SectionQuestionContainer>
        );
      default:
        return (
          <SectionQuestionContainer>
            {!question.answer && <UnansweredIcon />}
            <QuestionTitle>{questionTitle}</QuestionTitle>
            <QuestionAnswer>{question.answer}</QuestionAnswer>
          </SectionQuestionContainer>
        );
    }
  };

  return (
    <>
      {survey && survey.patient ? (
        <>
          <SurveyPageContainer>
            <Breadcrumbs crumbs={crumbs} />

            <PageHeader
              header1={`${survey.patient.firstName} ${survey.patient.lastName}`}
              header2={`Survey ${DateHelper.formatUTCEpochDateMMDDYYYY(
                survey.appointmentDate
              )}`}
            />

            <SurveyActionBarContainer>
              <CardCustom>
                <CardBody>
                  <TableHeaderContainer>
                    <h4>
                      Visit Summary - Survey{" "}
                      {DateHelper.formatUTCEpochDateMMDDYYYY(
                        survey.appointmentDate
                      )}
                    </h4>

                    <TableHeaderContainerContent>
                      {survey.status === "PENDING" && (
                        <Button onClick={handlers.completeInPersonClick}>
                          Complete In Person
                        </Button>
                      )}
                      {survey.status === "SUBMITTED" && (
                        <>
                          <Button onClick={handlers.completeInPersonClick}>
                            Edit Submitted Survey
                          </Button>
                          {user.role === "PROVIDER" && (
                            <Button
                              backgroundcolor={colors.green}
                              fontcolor={colors.white}
                              bordercolor={colors.green}
                              onClick={handlers.markSurveyAsReviewdClick}
                            >
                              Mark as Reviewed
                            </Button>
                          )}
                        </>
                      )}
                      {survey.status === "REVIEWED" && (
                        <Button
                          backgroundcolor={colors.green}
                          fontcolor={colors.white}
                          bordercolor={colors.green}
                          onClick={handlers.markSurveyAsBilledClick}
                        >
                          Mark as Billed
                        </Button>
                      )}
                      {survey.status === "BILLED" && (
                        <Button
                          backgroundcolor={colors.green}
                          fontcolor={colors.white}
                          bordercolor={colors.green}
                          onClick={handlers.markSurveyAsPaidClick}
                        >
                          Mark as Paid
                        </Button>
                      )}
                    </TableHeaderContainerContent>
                  </TableHeaderContainer>
                </CardBody>
              </CardCustom>
            </SurveyActionBarContainer>

            <BodyContainer>
              <SideBarContainer>
                <CardCustom>
                  <CardBody>
                    <TableHeaderContainer>
                      <h5>Survey Details</h5>
                    </TableHeaderContainer>
                    <div>
                      <PatientInfoLineItem>
                        <div>Appointment</div>
                        <div>
                          {DateHelper.formatUTCEpochDateMMDDYYYY(
                            survey.appointmentDate
                          )}
                        </div>
                      </PatientInfoLineItem>
                      <PatientInfoLineItem>
                        <div>Survey Type</div>
                        <div>{survey.type}</div>
                      </PatientInfoLineItem>
                      <PatientInfoLineItem>
                        <div>Created</div>
                        <div>
                          {DateHelper.formatUTCEpochDateMMDDYYYY(
                            survey.created
                          )}
                        </div>
                      </PatientInfoLineItem>
                      <PatientInfoLineItem>
                        <div>Status</div>
                        <SurveyStatus status={survey.status}>
                          {survey.statusString}
                        </SurveyStatus>
                      </PatientInfoLineItem>
                    </div>
                  </CardBody>
                </CardCustom>

                <CardCustom>
                  <CardBody>
                    <TableHeaderContainer>
                      <div>
                        <div>
                          <h5>Vitals</h5>
                        </div>
                        <Subheader>
                          To be added during an in-person appointment
                        </Subheader>
                      </div>
                      <TableHeaderContainerContent>
                        <Button onClick={handlers.editVitalsClick}>
                          Edit Vitals
                        </Button>
                      </TableHeaderContainerContent>
                    </TableHeaderContainer>
                    <div>
                      <PatientInfoLineItem>
                        <div>Temperature (F)</div>
                        <div>{survey.vitals.temperature}</div>
                      </PatientInfoLineItem>
                      <PatientInfoLineItem>
                        <div>Height (Inches)</div>
                        <div>{survey.vitals.height}</div>
                      </PatientInfoLineItem>
                      <PatientInfoLineItem>
                        <div>Weight (lbs)</div>
                        <div>{survey.vitals.weight}</div>
                      </PatientInfoLineItem>
                      <PatientInfoLineItem>
                        <div>BMI</div>
                        <div>{survey.vitals.bmi}</div>
                      </PatientInfoLineItem>
                      <PatientInfoLineItem>
                        <div>Blood Pressure</div>
                        <div>{survey.vitals.bloodpressure}</div>
                      </PatientInfoLineItem>
                      <PatientInfoLineItem>
                        <div>Visual Acuity</div>
                        <div>{survey.vitals.visualacuity}</div>
                      </PatientInfoLineItem>
                    </div>
                  </CardBody>
                </CardCustom>

                <CardCustom>
                  <CardBody>
                    <TableHeaderContainer>
                      <h5>Current Medications</h5>
                    </TableHeaderContainer>
                    <CurrentMedsText>
                      Information will be pulled from the AWV survey.
                    </CurrentMedsText>
                    {currentMeds.answers && (
                      <DynamicTable>
                        <DynamicTableHead>
                          <DynamicTableRow>
                            <DynamicTableHeader>
                              Medication Name
                            </DynamicTableHeader>
                            <DynamicTableHeader>Dosage</DynamicTableHeader>
                          </DynamicTableRow>
                        </DynamicTableHead>
                        <DynamicTableBody>
                          {currentMeds.answers.map((answer) => {
                            return (
                              <DynamicTableRow>
                                <DynamicTableCell>
                                  {
                                    answer.find(
                                      (a) => a.title === "Medication Name"
                                    ).value
                                  }
                                </DynamicTableCell>
                                <DynamicTableCell>
                                  {
                                    answer.find((a) => a.title === "Dosage")
                                      .value
                                  }
                                </DynamicTableCell>
                              </DynamicTableRow>
                            );
                          })}
                        </DynamicTableBody>
                      </DynamicTable>
                    )}
                  </CardBody>
                </CardCustom>

                <CardCustom>
                  <CardBody>
                    <TableHeaderContainer>
                      <div>
                        <div>
                          <h5>Notes</h5>
                        </div>
                        <Subheader>
                          To be added during an in-person appointment
                        </Subheader>
                      </div>
                      <TableHeaderContainerContent>
                        <Button onClick={handlers.editNotesClick}>
                          Edit Notes
                        </Button>
                      </TableHeaderContainerContent>
                    </TableHeaderContainer>
                    <NotesContainer>{survey.notes}</NotesContainer>
                  </CardBody>
                </CardCustom>
              </SideBarContainer>

              <SurveyResultsContainer>
                <CardCustom>
                  <CardBody>
                    <TableHeaderContainer>
                      <h5>Patient Information</h5>
                    </TableHeaderContainer>
                    <PatientInfoBoxContainer>
                      <InfoBox>
                        <InfoLabel>Date of Birth</InfoLabel>
                        <InfoValue>
                          {DateHelper.formatUTCEpochDateMMDDYYYY(
                            survey.patient.dateOfBirth
                          )}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>Gender</InfoLabel>
                        <InfoValue>{survey.patient.gender}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>Age</InfoLabel>
                        <InfoValue>{survey.patient.age}</InfoValue>
                      </InfoBox>
                    </PatientInfoBoxContainer>
                  </CardBody>
                </CardCustom>
                <CardCustom>
                  <CardBody>
                    {survey.status === "PENDING" && (
                      <TableNoRecordsPlaceHolderContainer>
                        <h2>Survey results not yet available</h2>
                        <div>The Patient Survey has not been submitted yet</div>
                        <Button onClick={handlers.completeInPersonClick}>
                          Complete In Person
                        </Button>
                      </TableNoRecordsPlaceHolderContainer>
                    )}
                    {survey.status !== "PENDING" && (
                      <div>
                        <SurveyResultsHeaderContainer>
                          <SurveyResultsHeader>
                            Survey Results Summary
                          </SurveyResultsHeader>
                          <SurveyResultsHeaderActions>
                            <PrintButton onClick={handlers.exportSurvey}>
                              <span>Print / Export</span>
                              <FontAwesomeIcon
                                style={{
                                  color: "#ffffff",
                                  fontSize: "18px",
                                  marginLeft: "8px",
                                }}
                                size="lg"
                                icon={faDownload}
                              />
                            </PrintButton>
                          </SurveyResultsHeaderActions>
                        </SurveyResultsHeaderContainer>
                        {survey.panels.map((panel, i) => {
                          return (
                            <div>
                              <PanelTitleContainer>
                                <PanelTitle>{panel.title}</PanelTitle>
                                {i === 0 && (
                                  <ExpandAllButton
                                    onClick={handlers.expandAllPanels}
                                  >
                                    {expandAll ? "Expand All" : "Collapse All"}
                                  </ExpandAllButton>
                                )}
                              </PanelTitleContainer>
                              {panel.pages.map((page) => {
                                return (
                                  <PanelPageContainer>
                                    <Accordion.Item eventKey="0">
                                      <CustomAccordionHeader>
                                        {page.risk ? (
                                          <RiskIcon
                                            tooltip="Risk detected in panel"
                                            inPanelHeader={true}
                                          />
                                        ) : (
                                          <NoRiskIcon
                                            tooltip="No risk detected in panel"
                                            inPanelHeader={true}
                                          />
                                        )}

                                        {page.unansweredQuestions && (
                                          <UnansweredIcon
                                            tooltip="Unanswered questions in panel"
                                            inPanelHeader={true}
                                          />
                                        )}

                                        <CustomAccordionHeaderContent>
                                          <div>{page.title}</div>
                                          {panel.score && (
                                            <div>Score {panel.score}</div>
                                          )}
                                        </CustomAccordionHeaderContent>
                                      </CustomAccordionHeader>
                                      <CustomAccordionBody>
                                        {page.sections.map(
                                          (section, sectionindex) => {
                                            return (
                                              <PanelSectionContainer>
                                                <PanelSectionTitle>
                                                  {section.title}
                                                </PanelSectionTitle>
                                                {section.questions.map(
                                                  (question, questionIndex) => {
                                                    return renderSummaryQuestion(
                                                      {
                                                        question,
                                                        questionIndex,
                                                      }
                                                    );
                                                  }
                                                )}
                                              </PanelSectionContainer>
                                            );
                                          }
                                        )}
                                      </CustomAccordionBody>
                                    </Accordion.Item>
                                  </PanelPageContainer>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </CardBody>
                </CardCustom>
                <SideBySideCards>
                  <CardCustom>
                    <CardBody>
                      <TableHeaderContainer>
                        <div>
                          <div>
                            <h5>Assessment</h5>
                          </div>
                          <Subheader>
                            To be added during an in-person appointment
                          </Subheader>
                        </div>

                        <TableHeaderContainerContent>
                          <Button onClick={handlers.editAssessmentClick}>
                            Edit
                          </Button>
                        </TableHeaderContainerContent>
                      </TableHeaderContainer>
                      <NotesContainer>{survey.assessment}</NotesContainer>
                    </CardBody>
                  </CardCustom>
                  <CardCustom>
                    <CardBody>
                      <TableHeaderContainer>
                        <div>
                          <div>
                            <h5>Plan</h5>
                          </div>
                          <Subheader>
                            To be added during an in-person appointment
                          </Subheader>
                        </div>
                        <TableHeaderContainerContent>
                          <Button onClick={handlers.editPlanClick}>Edit</Button>
                        </TableHeaderContainerContent>
                      </TableHeaderContainer>
                      <NotesContainer>{survey.plan}</NotesContainer>
                    </CardBody>
                  </CardCustom>
                </SideBySideCards>
                <CardCustom>
                  <CardBody>
                    <TableHeaderContainer>
                      <h5>Survey Activity Log</h5>
                    </TableHeaderContainer>
                    <CurrentMedsText>
                      This info is updated in real time.
                    </CurrentMedsText>
                    <ActivityLog activityLog={activityLog} />
                  </CardBody>
                </CardCustom>
              </SurveyResultsContainer>
            </BodyContainer>
          </SurveyPageContainer>

          <Modal
            show={editNotes.show}
            headerText={"Enter Notes"}
            onClose={handlers.editNotesClose}
          >
            <NotesTextArea
              value={editNotes.notes}
              onChange={(e) => {
                setEditNotes({
                  ...editNotes,
                  notes: e.target.value,
                });
              }}
            />
            <SubmitContainer>
              <CancelButton onClick={handlers.editNotesClose}>
                Cancel
              </CancelButton>
              <Button disabled={submitting} onClick={handlers.editNotesSubmit}>
                Save
              </Button>
            </SubmitContainer>
          </Modal>

          <Modal
            show={editAssessment.show}
            headerText={"Enter Assessment"}
            onClose={handlers.editAssessmentClose}
          >
            <NotesTextArea
              value={editAssessment.assessment}
              onChange={(e) => {
                setEditAssessment({
                  ...editAssessment,
                  assessment: e.target.value,
                });
              }}
            />
            <SubmitContainer>
              <CancelButton onClick={handlers.editAssessmentClose}>
                Cancel
              </CancelButton>
              <Button
                disabled={submitting}
                onClick={handlers.editAssessmentSubmit}
              >
                Save
              </Button>
            </SubmitContainer>
          </Modal>

          <Modal
            show={editPlan.show}
            headerText={"Enter Plan"}
            onClose={handlers.editPlanClose}
          >
            <NotesTextArea
              value={editPlan.assessment}
              onChange={(e) => {
                setEditPlan({
                  ...editPlan,
                  plan: e.target.value,
                });
              }}
            />
            <SubmitContainer>
              <CancelButton onClick={handlers.editPlanClose}>
                Cancel
              </CancelButton>
              <Button disabled={submitting} onClick={handlers.editPlanSubmit}>
                Save
              </Button>
            </SubmitContainer>
          </Modal>

          <Modal
            show={editVitals.show}
            headerText={"Enter Vitals"}
            onClose={handlers.editVitalsClose}
          >
            <SurveyVitalsForm
              validated={validated}
              onSubmit={handlers.editVitalsSubmit}
              onCancel={handlers.editVitalsClose}
              submitting={submitting}
              submittingError={submittingError}
              editVitals={editVitals}
              setEditVitals={setEditVitals}
            />
          </Modal>

          <Modal
            show={showConfirmReviewdModal}
            headerText={"Please Confirm Your Review"}
            onClose={handlers.cancelMarkSurveyAsReviewd}
          >
            <ModalBody>
              <p>
                <b>
                  Once you click confirm, this record will be marked as reviewed
                </b>
              </p>
              <Purple>By clicking confirm:</Purple>
              <p>
                You, the Provider, confirm that you have reviewed this patient’s
                record in full, to the best of your ability, with this patient.
                You confirm that the information you have received is correct to
                the best of your knowledge.
              </p>
              <p>
                You acknowledge that once reviewed, changes can no longer be
                made to any existing information within this record.
              </p>
            </ModalBody>
            <SubmitContainer>
              <CancelButton onClick={handlers.cancelMarkSurveyAsReviewd}>
                Cancel
              </CancelButton>
              <Button
                disabled={submitting}
                onClick={handlers.submitMarkSurveyAsReviewd}
              >
                Confirm
              </Button>
            </SubmitContainer>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default Survey;
