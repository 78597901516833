import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import API from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import PatientForm from "../../components/PatientForm";
import PageHeader from "../../components/PageHeader";
import PageSubheader from "../../components/PageSubheader";
import Breadcrumbs from "../../components/BreadCrumbs";
import Models from "../../models";
import DateHelpers from "../../helpers/date";
import { addPopupNotification } from "../../components/PopupNotifications";

const PageContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const EditPatient = () => {
  const navigate = useNavigate();
  //Redux State
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //Params
  const { patientId } = useParams();

  //State
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [patientLoaded, setPatientLoaded] = useState(false);
  const [crumbs, setCrumbs] = useState([]);

  const [organization, setOrganization] = useState({
    providers: [],
  });

  const [formUX, setFormUX] = useState({
    addAnotherAfterSave: false,
    submitForm: false,
  });

  const [patient, setPatient] = useState(new Models.Patient({}));
  const [editPatient, setEditPatient] = useState(new Models.Patient({}));

  //Effects
  useEffect(() => {
    console.log("Edit Patient Did Mount");
    data.getPatient();
  }, []);

  useEffect(() => {
    if (
      patient &&
      patient.provider &&
      patient.organization &&
      patient.provider.name &&
      patient.organization.name
    ) {
      data.getOrganization({ organizationId: patient.organization.id });

      if (user && user.role === "ADMIN") {
        setCrumbs([
          {
            title: "Administration",
            link: `/`,
          },
          {
            title: patient.organization.name,
            link: `/organization`,
          },
          {
            title: patient.provider.name,
            link: `/provider/${patient.provider.id}`,
          },
          {
            title: `${patient.firstName} ${patient.lastName}`,
            link: `/patient/${patient.id}`,
          },
          {
            title: `Edit Patient`,
            link: ``,
          },
        ]);
      } else {
        setCrumbs([
          {
            title: patient.organization.name,
            link: `/organization`,
          },
          {
            title: patient.provider.name,
            link: `/provider/${patient.provider.id}`,
          },
          {
            title: `${patient.firstName} ${patient.lastName}`,
            link: `/patient/${patient.id}`,
          },
          {
            title: `Edit Patient`,
            link: ``,
          },
        ]);
      }
    }
  }, [patient, user]);

  //Data API
  const data = {
    getPatient: async () => {
      if (!patientId) {
        return;
      }
      setPatientLoaded(false);
      const _patientRes = await API.Patient.PatientGet({
        patientId,
      });
      const patientModel = new Models.Patient({
        ..._patientRes,
        dateOfBirth: DateHelpers.formatUTCEpochDateMMDDYYYY(
          _patientRes.dateOfBirth
        ),
      });
      setPatient({ ...patientModel });
      setEditPatient({ ...patientModel });
      setPatientLoaded(true);
    },
    getOrganization: async ({ organizationId }) => {
      const _organizationProviders = await API.Organization.OrganizationGet({
        organizationId,
      });

      setOrganization({
        ..._organizationProviders,
      });
    },
  };

  //Handlers
  const handlers = {
    editPatientSubmit: async (e) => {
      e.preventDefault();
      e.stopPropagation();

      setFormUX({
        ...formUX,
        submitForm: false,
      });

      const form = e.currentTarget;
      const valid = form.checkValidity();
      setValidated(true);

      if (valid) {
        setSubmitting(true);
        try {
          const editPatientData = {
            ...editPatient,
            patientId: editPatient.id,
            dateOfBirth: DateHelpers.MMDDYYYYtoEpochUTC(
              editPatient.dateOfBirth
            ),
          };
          const res = await API.Patient.PatientUpdate({
            editPatientData,
          });
          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Success!",
            message: "Your changes were saved.",
            type: "success",
          });
          navigate(`/patient/${res.id}`);
        } catch (err) {
          console.log(err);
          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Action Failed",
            message: "Your patient was not saved. Please try again.",
            type: "danger",
          });
        }
      } else {
        addPopupNotification({
          dispatch,
          title: "Warning",
          message: "Please check required fields.",
          type: "warning",
        });
      }
    },
    editPatientCancel: async () => {
      navigate(`/patient/${patientId}`);
    },
  };
  return (
    <PageContainer>
      <Breadcrumbs crumbs={crumbs} />

      <PageHeader
        header1="Edit Patient"
        header2={`${patient.firstName} ${patient.lastName}`}
      />
      <PageSubheader>
        <div>
          Add a new patient to the system by entering the information below.
        </div>
        <div>* required field</div>
      </PageSubheader>

      {patientLoaded && (
        <PatientForm
          validated={validated}
          onCancel={handlers.editPatientCancel}
          onSubmit={handlers.editPatientSubmit}
          submitting={submitting}
          organization={organization}
          patient={editPatient}
          setPatient={setEditPatient}
          formUX={formUX}
          setFormUX={setFormUX}
          showSaveAddAnother={false}
        />
      )}
    </PageContainer>
  );
};

export default EditPatient;
