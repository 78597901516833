import NavigationSurvey from "../NavigationSurvey";
import Footer from "../Footer";
import styled from "styled-components";
import { PopupNotifications } from "../PopupNotifications";
const PageWrapper = styled.div`
  margin-bottom: 80px;
  background-color: #fafafa;
`;

const Layout = ({ children }) => {
  return (
    <>
      <PopupNotifications />
      <PageWrapper>
        <NavigationSurvey />
        {children}
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Layout;
