import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../theme/colors";
import { Table } from "react-bootstrap";
import { DateHelper } from "../../helpers";
import UserReducer from "../../redux-store/userReducer";

const ActivityLogContainer = styled.div``;

const BodyContainer = styled.div`
  max-height: 307px;
  overflow: scroll;
`;

const TableBody = styled.tbody`
  border-top: none !important;
`;

const TableHeaderCell = styled.th`
  border: none;
  color: #a1a1a1 !important;
  background-color: #fafafa !important;
  font-weight: normal;
`;

const TableCell = styled.td`
  vertical-align: middle;
  white-space: nowrap;
`;

const TableHead = styled.thead`
  position: sticky;
  top: 0;
`;

const ActivityLog = ({ activityLog = [] }) => {
  return (
    <ActivityLogContainer>
      <BodyContainer>
        <Table>
          <TableHead>
            <tr>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>NPI</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
              <TableHeaderCell>Date Time</TableHeaderCell>
            </tr>
          </TableHead>
          <TableBody>
            {activityLog.map((activity, index) => {
              return (
                <tr key={`activity-${index}`}>
                  <TableCell>{activity.user.name}</TableCell>
                  <TableCell>{activity.user.npi || "n/a"}</TableCell>
                  <TableCell>{activity.actionString}</TableCell>
                  <TableCell>
                    {DateHelper.formatUTCEpochDateMMDDYYYYHHMMSS(activity.date)}
                  </TableCell>
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </BodyContainer>
    </ActivityLogContainer>
  );
};

export default ActivityLog;
