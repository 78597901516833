import styled from "styled-components";
import colors from "../../theme/colors";

const StyledCheckbox = styled.input.attrs((props) => ({
  type: props.type,
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const StyledCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin: 10px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  &:hover {
    span {
      border-width: 1px;
    }
  }
`;

const StyledCheckboxCheckmark = styled.span`
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid ${colors.purple};
  background-color: ${(props) => (props.checked ? colors.purple : "#fff")};
  border-radius: 50%;
`;

const StyledCheckboxLabel = styled.span`
  width: 100%;
  border: ${(props) =>
    props.checked ? `1px solid ${colors.purple}` : "1px solid #F1EDFF"};
  padding: 7px;
  border-radius: 5px;
`;

const CheckboxRound = ({ ...props }) => {
  return (
    <StyledCheckboxContainer
      maxWidth={props.maxWidth}
      key={props.key}
      checked={props.checked}
    >
      <StyledCheckboxLabel checked={props.checked}>
        {props.label}
      </StyledCheckboxLabel>
      <StyledCheckbox
        type="checkbox"
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
      />
      <StyledCheckboxCheckmark
        checked={props.checked}
      ></StyledCheckboxCheckmark>
    </StyledCheckboxContainer>
  );
};

export default CheckboxRound;
