import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import API from "./api";
import { Auth } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userState, setUserState] = useState({
    loggedIn: false,
    loading: true,
  });
  const [user, setUser] = useState();
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.user);

  const loadAuthState = async () => {
    try {
      // this.props.authUpdateIsAuthenticating({ value: true });
      const session = await Auth.currentSession();
      setUserState({
        loggedIn: session ? true : false,
        loading: false,
      });
    } catch (e) {
      //Log true errors
      if (e !== "No current user") {
        console.log(e);
      }
      setUserState({
        loggedIn: false,
        loading: false,
      });
    }
  };

  useEffect(() => {
    console.log("App Did Mount");
    loadAuthState();
    return onAuthUIStateChange((nextAuthState, authData) => {
      setUserState({
        loggedIn: nextAuthState === AuthState.SignedIn,
        loading: false,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function GetUser() {
      const user = await API.User.UserGet();
      dispatch({ type: "USER_UPDATE_USER", data: user });
      setUser(user);
    }
    if (!userState.loading && userState.loggedIn) {
      dispatch({
        type: "USER_UPDATE_USER",
        data: {
          ...reduxUser,
          loggedIn: true,
        },
      });
      GetUser();
    } else if (!userState.loading && !userState.loggedIn) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);

  useEffect(() => {
    console.log("location.pathname", location.pathname);
    if (userState.loggedIn && user && location.pathname === "/") {
      navigate(user.role === "ADMIN" ? "/admin/dashboard" : `/organization`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userState]);

  return (
    <div>
      {userState.loading ? (
        <div></div>
      ) : (
        <Layout showNavigation={userState.loggedIn}>
          <Outlet />
        </Layout>
      )}
    </div>
  );
};

export default App;
