import { createStore, applyMiddleware, combineReducers } from "redux";

//reducers
import user from "./userReducer";
import popupNotifications from "./popupNotificationsReducer";

const appReducer = combineReducers({
  user,
  popupNotifications,
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_USER_LOGOUT") {
    state = {};
  }
  return appReducer(state, action);
};

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
