import Navigation from "../Navigation";
import Footer from "../Footer";
import { PopupNotifications } from "../PopupNotifications";
import styled from "styled-components";

const PageWrapper = styled.div`
  margin-bottom: 80px;
  background-color: #fafafa;
  @media print {
    background-color: white !important;
    margin-bottom: 0px;
  }
  /* this is the content area for the pages */
  > div:nth-child(2) {
    max-width: 1266px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 12px;
  }
`;

const Layout = ({ children, showNavigation }) => {
  console.log("Rendering Layout");
  return (
    <>
      <PopupNotifications />
      <PageWrapper>
        {showNavigation ? <Navigation /> : null}
        {children}
        {showNavigation ? <Footer /> : null}
      </PageWrapper>
    </>
  );
};

export default Layout;
