import { API } from "aws-amplify";

const UserGet = () => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
      };

      API.get("user", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const UserGetAdmins = () => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
      };

      API.get("user/admins", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const UserCreate = ({ createUserData }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: createUserData,
      };

      API.post("user", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const UserDelete = ({ deleteUserId }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: { deleteUserId },
      };

      API.del("user", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const Methods = {
  UserGet,
  UserDelete,
  UserGetAdmins,
  UserCreate,
};

export default Methods;
