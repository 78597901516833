import { Auth } from "aws-amplify";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import HCRpathLogoImage from "../../theme/images/hcrpath-logo.svg";
import Modal from "../Modal";
import Button from "../Button";
import colors from "../../theme/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

const noPrintMixIn = css`
  @media print {
    display: none !important;
    * {
      display: none !important;
    }
  }
`;

const RightSideLinksWrapper = styled.div`
  display: flex;
`;

const NavigationWrapper = styled.div`
  ${noPrintMixIn}
`;

const HCRpathLogoImageContainer = styled.img`
  max-width: 60px;
`;

const TopLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
  font-size: 16px;
  background: ${(props) =>
    props.showAdminNav ? colors.purple : "transparent"};
  a {
    text-decoration: none;
    color: ${(props) => (props.showAdminNav ? "white" : "#7f7f7f")};
  }
`;
const NavItem = styled(Nav.Item)`
  position: relative;
  margin: 0 20px;
  @media (max-width: 575px) {
    margin: 10px 0;
  }
`;

const NavLink = styled(Link)`
  position: relative;
  text-decoration: none;
  user-select: none;
  color: #7f7f7f;
  font-size: 14px;
  &:hover {
    color: #000000;
  }
  &.active::after {
    content: "";
    height: 4px;
    margin: 10px 0;
    width: 100%;
    background-color: ${colors.purple};
    position: absolute;
    bottom: -29px;
    left: 0;
    border-radius: 2px;
    @media (max-width: 575px) {
      bottom: 0px;
    }
  }
  @media (max-width: 575px) {
    margin: 0;
    padding: 20px 0px;
  }
`;

const KnowledgeBaseLink = styled.a`
  padding-right: 22px;
  display: flex;
  align-items: center;
`;

const CustomNavDropdownItem = styled(NavDropdown.Item)`
  &:active {
    background-color: #ccc !important;
  }
  &:hover {
    background: white;
    color: black;
  }
`;

const NavDropdownCustom = styled(NavDropdown)`
  font-size: 14px;
  padding: 0;
  > a {
    padding: 0 !important;
    &:after {
      vertical-align: top;
      margin-top: 10px;
    }
  }
`;

const NavDropdownTitle = styled.div`
  display: inline-block;
`;

const NavDropdownTitleName = styled.div`
  color: #000000;
  text-align: right;
`;

const NavDropdownTitleRole = styled.div`
  color: #a2a5b3;
  text-align: right;
  font-size: 12px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SupportModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} headerText={"Support"} onClose={handleClose}>
      <p>
        <span>Need help? You can fill out this </span>
        <a
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSd6bto2gJuLc0m5Nywf73O8z2NFFZvM6OYTdJgDy4M3aESwOQ/viewform"
          rel="noreferrer"
        >
          Support Form
        </a>
        <span> and we will get back to you as soon as possible.</span>
      </p>
      <SubmitContainer>
        <Button onClick={handleClose}>Close</Button>
      </SubmitContainer>
    </Modal>
  );
};

const NormalNavBar = ({
  user,
  isAdmin,
  location,
  pathPieces,
  signOut,
  organizationId,
}) => {
  return (
    <Navbar
      style={{ padding: "10px 30px" }}
      collapseOnSelect
      expand="sm"
      bg="white"
      variant="light"
    >
      <Navbar.Brand>
        <Link to="/">
          <HCRpathLogoImageContainer
            src={HCRpathLogoImage}
            alt="HCRpath Logo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <NavItem>
            <NavLink
              className={pathPieces[0] === "organization" ? "active" : null}
              to={`/organization`}
            >
              My Organization
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={pathPieces[0] === "members" ? "active" : null}
              to={`/members`}
            >
              Members
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className="justify-content-end">
          <NavDropdownCustom
            id="header-account-dropdown"
            title={
              <NavDropdownTitle>
                <NavDropdownTitleName>{user.name}</NavDropdownTitleName>
                <NavDropdownTitleRole>{user.roleString}</NavDropdownTitleRole>
              </NavDropdownTitle>
            }
            align="end"
          >
            <CustomNavDropdownItem onClick={signOut}>
              <NavLink to="#">Sign Out</NavLink>
            </CustomNavDropdownItem>
          </NavDropdownCustom>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const AdminNavBar = ({
  user,
  isAdmin,
  location,
  pathPieces,
  signOut,
  organizationId,
}) => {
  return (
    <Navbar
      style={{ padding: "10px 30px" }}
      collapseOnSelect
      expand="sm"
      bg="white"
      variant="light"
    >
      <Navbar.Brand>
        <Link to="/">
          <HCRpathLogoImageContainer
            src={HCRpathLogoImage}
            alt="HCRpath Logo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <NavItem>
            <NavLink
              className={
                location.pathname === "/admin/dashboard" ? "active" : null
              }
              to="/admin/dashboard"
            >
              Organizations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                pathPieces[1] === "user" || location.pathname === "/admin/users"
                  ? "active"
                  : null
              }
              to="/admin/users"
            >
              Admin Users
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className="justify-content-end">
          <NavDropdownCustom
            id="header-account-dropdown"
            title={
              <NavDropdownTitle>
                <NavDropdownTitleName>{user.name}</NavDropdownTitleName>
                <NavDropdownTitleRole>{user.roleString}</NavDropdownTitleRole>
              </NavDropdownTitle>
            }
            align="end"
          >
            <CustomNavDropdownItem onClick={signOut}>
              <NavLink to="#">Sign Out</NavLink>
            </CustomNavDropdownItem>
          </NavDropdownCustom>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const Navigation = () => {
  console.log("Rendering Navigation");
  const [showSupportModal, setShowSupportModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  let pathPieces = location.pathname.split("/").filter((p) => p);

  let organizationId = user && user.organizationId ? user.organizationId : null;
  // if (pathPieces[0] === "organization") {
  //   organizationId = pathPieces[1];
  // }

  if (user && user.role) {
    user.roleString =
      user.role[0].toUpperCase() + user.role.toLowerCase().slice(1);
  }

  //is Admin Area
  const isAdmin = user.role === "ADMIN";
  const showAdminNav = pathPieces[0] === "admin" && isAdmin;

  const signOut = async () => {
    try {
      await Auth.signOut();
      //clear redux store
      dispatch({ type: "AUTH_USER_LOGOUT" });
      window.location.replace("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <NavigationWrapper>
      <SupportModal
        show={showSupportModal}
        handleClose={() => setShowSupportModal(false)}
      />
      <TopLinksWrapper showAdminNav={showAdminNav}>
        <div>
          {user.role === "ADMIN" ? (
            <Link to="/admin/dashboard">Administration</Link>
          ) : null}
        </div>
        <RightSideLinksWrapper>
          <KnowledgeBaseLink
            rel="noreferrer"
            alt="HCRpath Knowledge Base"
            href="https://www.hcrpath.com/knowledgebase/"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{
                fontSize: "14px",
                marginRight: "5px",
              }}
              size="lg"
              icon={faQuestionCircle}
            />
            Knowledge Base
          </KnowledgeBaseLink>
          <Link to="#" onClick={() => setShowSupportModal(true)}>
            Support
          </Link>
        </RightSideLinksWrapper>
      </TopLinksWrapper>
      {showAdminNav ? (
        <AdminNavBar
          user={user}
          isAdmin={isAdmin}
          location={location}
          pathPieces={pathPieces}
          signOut={signOut}
        />
      ) : (
        <NormalNavBar
          user={user}
          isAdmin={isAdmin}
          location={location}
          pathPieces={pathPieces}
          signOut={signOut}
          organizationId={organizationId}
        />
      )}
    </NavigationWrapper>
  );
};

export default Navigation;
