import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams, useLocation } from "react-router-dom";
// import Button from "../../components/Button";
import Breadcrumbs from "../../components/BreadCrumbs";
import PageHeader from "../../components/PageHeader";

const UserContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const User = () => {
  let { userId } = useParams();
  const [crumbs, setCrumbs] = useState([]);
  const [user, setUser] = useState({
    id: userId,
    name: "test user",
  });
  useEffect(() => {
    console.log("User Did Mount");
    setCrumbs([
      {
        title: "Users",
        link: `/users`,
      },
      {
        title: user.name,
        link: `/user/${user.id}`,
      },
    ]);
  }, []);

  return (
    <UserContainer>
      <Breadcrumbs crumbs={crumbs} />
      <PageHeader header1="User" header2={user.name} />
    </UserContainer>
  );
};

export default User;
