import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import colors from "../../theme/colors";

const noPrintMixIn = css`
  @media print {
    display: none !important;
    * {
      display: none !important;
    }
  }
`;

const FooterContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #eeeeee;
  padding: 10px 30px;
  color: #a7a7a7;
  font-size: 12px;
  justify-content: space-between;
  z-index: 1001;
  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
  }
  ${noPrintMixIn}
`;

const CenterLinksContainer = styled.div``;

const StyledLink = styled(Link)`
  color: #a7a7a7;
  margin: 0 5px;
  &:hover {
    color: ${colors.gray};
  }
`;

const Footer = () => {
  const location = useLocation();

  let pathPieces = location.pathname.split("/").filter((p) => p);

  return (
    <FooterContainer>
      <div>&#169; HCRpath 2022. All right reserved.</div>
      <CenterLinksContainer>
        <StyledLink to={`/terms`}>Terms of Service</StyledLink>|
        <StyledLink to={`/privacy`}>Privacy</StyledLink>
      </CenterLinksContainer>
      <div style={{ width: "202px" }}></div>
    </FooterContainer>
  );
};

export default Footer;
