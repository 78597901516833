import Organization from "./organization";
import Survey from "./survey";
import Patient from "./patient";
import Vitals from "./vitals";

const models = {
  Organization,
  Survey,
  Patient,
  Vitals,
};

export default models;
