import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import API from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import PatientForm from "../../components/PatientForm";
import PageHeader from "../../components/PageHeader";
import PageSubheader from "../../components/PageSubheader";
import Breadcrumbs from "../../components/BreadCrumbs";
import DateHelpers from "../../helpers/date";
import { addPopupNotification } from "../../components/PopupNotifications";
import Models from "../../models";

const PageContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const NewPatient = () => {
  const navigate = useNavigate();
  //Redux State
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //Params
  const { providerId } = useParams();

  //State
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(false);
  const [crumbs, setCrumbs] = useState([]);

  const [organization, setOrganization] = useState({
    providers: [],
  });

  const [provider, setProvider] = useState({
    provider: {},
    patients: [],
    filteredPatients: [],
    surveys: [],
    organization: {},
  });

  const [formUX, setFormUX] = useState({
    addAnotherAfterSave: false,
    submitForm: false,
  });

  const [newPatient, setNewPatient] = useState(new Models.Patient({}));

  const resetNewPatientState = () => {
    setNewPatient(
      new Models.Patient({
        organizationId: provider.organization.id,
        providerId: provider.provider.id,
      })
    );
  };

  //Effects
  useEffect(() => {
    console.log("New Patient Did Mount");
    data.getProvider();
  }, []);

  useEffect(() => {
    if (
      provider &&
      provider.provider &&
      provider.organization &&
      provider.provider.name &&
      provider.organization.name
    ) {
      //set form state
      setNewPatient({
        ...newPatient,
        organizationId: provider.organization.id,
        providerId: provider.provider.id,
      });

      data.getOrganization({ organizationId: provider.organization.id });

      if (user && user.role === "ADMIN") {
        setCrumbs([
          {
            title: "Administration",
            link: `/`,
          },
          {
            title: provider.organization.name,
            link: `/organization`,
          },
          {
            title: provider.provider.name,
            link: `/provider/${provider.provider.id}`,
          },
          {
            title: "New Patient",
            link: ``,
          },
        ]);
      } else {
        setCrumbs([
          {
            title: provider.organization.name,
            link: `/organization`,
          },
          {
            title: provider.provider.name,
            link: `/provider/${provider.provider.id}`,
          },
          {
            title: "New Patient",
            link: ``,
          },
        ]);
      }
    }
  }, [provider, user]);

  //Data API
  const data = {
    getProvider: async () => {
      if (!providerId) {
        return;
      }
      const _provider = await API.Provider.ProviderGet({
        providerId,
      });
      setProvider({
        ...provider,
        ..._provider,
      });
    },
    getOrganization: async ({ organizationId }) => {
      const _organizationProviders = await API.Organization.OrganizationGet({
        organizationId,
      });

      setOrganization({
        ..._organizationProviders,
      });
    },
  };

  //Handlers
  const handlers = {
    addPatientSubmit: async (e) => {
      e.preventDefault();
      e.stopPropagation();

      setFormUX({
        ...formUX,
        submitForm: false,
      });

      const form = e.currentTarget;
      const valid = form.checkValidity();

      setValidated(true);

      if (valid) {
        setSubmitting(true);
        try {
          const createPatientData = {
            ...newPatient,
            dateOfBirth: DateHelpers.MMDDYYYYtoEpochUTC(newPatient.dateOfBirth),
          };

          const res = await API.Patient.PatientCreate({
            createPatientData,
          });

          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Success!",
            message: "Your patient was created.",
            type: "success",
          });

          if (formUX.addAnotherAfterSave) {
            setValidated(false);
            resetNewPatientState();
          } else {
            navigate(`/patient/${res.id}`);
          }
        } catch (err) {
          setSubmitting(false);
          console.log(err);
          addPopupNotification({
            dispatch,
            title: "Action Failed",
            message: "Your patient was not created, please try again.",
            type: "danger",
          });
        }
      } else {
        addPopupNotification({
          dispatch,
          title: "Warning",
          message: "Please check required fields.",
          type: "warning",
        });
      }
    },
    cancelAddPatient: async () => {
      navigate(`/provider/${providerId}`);
    },
  };
  return (
    <PageContainer>
      <Breadcrumbs crumbs={crumbs} />

      <PageHeader header1="New Patient" header2={provider.provider.name} />
      <PageSubheader>
        <div>
          Add a new patient to the system by entering the information below.
        </div>
        <div>* required field</div>
      </PageSubheader>

      <PatientForm
        validated={validated}
        onCancel={handlers.cancelAddPatient}
        onSubmit={handlers.addPatientSubmit}
        submitting={submitting}
        submittingError={submittingError}
        organization={organization}
        patient={newPatient}
        setPatient={setNewPatient}
        formUX={formUX}
        setFormUX={setFormUX}
      />
    </PageContainer>
  );
};

export default NewPatient;
