const colors = {
  siteBackground: "#FAFAFA",

  white: "#FFFFFF",
  purple: "#7456e5",
  gray: "#D8D8D8",
  green: "#00CFA2",
  black: "#000000",
  red: "#AD1523",

  lightgray:" #f7f7f7",

  surveyStatusPendingColor: "#7456E5",
  surveyStatusPendingBackground: "#E1CBFF",

  surveyStatusSubmittedColor: "#AD1523",
  surveyStatusSubmittedBackground: "#FDDCE0",

  surveyStatusReviewedColor: "#C59812",
  surveyStatusReviewedBackground: "#FDFFCC",

  surveyStatusBilledColor: "#3377E4",
  surveyStatusBilledBackground: "#BDD6FF",

  surveyStatusPaidColor: "#17A082",
  surveyStatusPaidBackground: "#BEFAED",

  surveyStatusDefaultColor: "black",
  surveyStatusDefaultBackground: "white",
};

export default colors;
