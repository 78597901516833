import React from "react";
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import styled from "styled-components";
import colors from "../../theme/colors";
import Logo from "../../theme/images/hcrpath-logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 40px 0 40px 0;
`;

const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 150px;
`;

const Login = () => {
  const AmplifySignInCustom = styled(AmplifySignIn)``;

  const reduxUser = useSelector((state) => state.user);

  console.log(reduxUser);

  const customCss = `
    :root {
      --amplify-primary-color: ${colors.purple};
      --amplify-primary-tint: ${colors.purple};
      --amplify-primary-shade: ${colors.purple};
    }
    amplify-authenticator {
      --container-height: 100%;
    }
  `;

  return (
    <LoginContainer>
      {!reduxUser.loggedIn && (
        <LogoContainer>
          <LogoImg src={Logo} alt="HCRpath Logo" />
        </LogoContainer>
      )}
      <AmplifyAuthContainer>
        <style>{customCss}</style>
        <AmplifyAuthenticator usernameAlias="email">
          <AmplifySignInCustom
            usernameAlias="email"
            headerText="HCRpath Portal Sign In"
            slot="sign-in"
            hideSignUp
          ></AmplifySignInCustom>
        </AmplifyAuthenticator>
      </AmplifyAuthContainer>
    </LoginContainer>
  );
};

export default Login;
