import React from "react";
import store from "./redux-store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./amplify-configure";
import App from "./App";

import PatientSurvey from "./containers/public-patient-survey";
import LayoutSurvey from "./components/LayoutSurvey";
// import Routing from "./routes";

import AdminDashboard from "./containers/admin-dashboard";
import AdminAccount from "./containers/admin-account";
import AdminUsers from "./containers/admin-users";
import AdminUser from "./containers/admin-user";

import Login from "./components/Login";
import Terms from "./containers/terms";
import Privacy from "./containers/privacy";

import Organization from "./containers/organization";
import Account from "./containers/account";
import ProviderPage from "./containers/provider";
import Users from "./containers/users";
import Patient from "./containers/patient/index";
import NewPatient from "./containers/patient/new-patient";
import EditPatient from "./containers/patient/edit-patient";
import User from "./containers/user";
import Survey from "./containers/survey";

import "./theme/main.css";

const NotFound = () => {
  return <div>Not Found</div>;
};

const SurveyPage = () => {
  return (
    <LayoutSurvey>
      <PatientSurvey />
    </LayoutSurvey>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          {/* Admin Routes  */}
          <Route path="admin/account" element={<AdminAccount />} />
          <Route path="admin/dashboard" element={<AdminDashboard />} />
          <Route path="admin/users" element={<AdminUsers />} />
          <Route path="admin/user/:userId" element={<AdminUser />} />

          {/* General Routes  */}
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />

          {/* Organization Routes  */}
          <Route path="organization" element={<Organization />} />
          <Route path="members" element={<Users />} />
          <Route path="account" element={<Account />} />
          <Route path="provider/:providerId" element={<ProviderPage />} />
          <Route
            path="provider/:providerId/addpatient"
            element={<NewPatient />}
          />
          <Route path="patient/:patientId" element={<Patient />} />
          <Route path="patient/edit/:patientId" element={<EditPatient />} />
          <Route path="user/:userId" element={<User />} />
          <Route path="survey/:surveyId" element={<Survey />} />

          {/* Error Routes */}
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/patient-survey/:surveyId" element={<SurveyPage />} />
        <Route
          path="/patient-survey/:surveyId/:lastName/:phone/:dateOfBirth"
          element={<SurveyPage />}
        />
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

//Unregister service worker
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      console.log("unregistered service worker", registration);
      registration.unregister();
    }
  });
}
