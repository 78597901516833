import styled from "styled-components";
import PageHeader from "../../components/PageHeader";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AdminAccountContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const AdminAccount = () => {
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("User Did Mount");
  }, []);
  return (
    <AdminAccountContainer>
      <PageHeader header1="Admin Account" header2={user.name} />
    </AdminAccountContainer>
  );
};

export default AdminAccount;
