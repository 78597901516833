import { API } from "aws-amplify";

const OrganizationGet = ({ organizationId }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: {
          organizationId,
        },
      };

      API.get("organization", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const OrganizationsGetAll = () => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
      };

      API.get("organizations", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const OrganizationCreate = ({ createOrganizationData }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: createOrganizationData,
      };

      API.post("organization", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const Methods = {
  OrganizationGet,
  OrganizationsGetAll,
  OrganizationCreate,
};

export default Methods;
