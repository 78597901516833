import { API, Auth } from "aws-amplify";

const SurveyCreate = ({ createSurveyData }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: createSurveyData,
      };

      API.post("survey", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveyGet = ({ surveyId }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: { surveyId },
      };

      API.get("survey", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveyDelete = ({ deleteSurveyId }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: { deleteSurveyId },
      };

      API.del("survey", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveysGet = ({ patientId }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: { patientId },
      };

      API.get("surveys", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveyGetProgress = ({ surveyId, phone, lastName, dateOfBirth }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: { surveyId, phone, lastName, dateOfBirth },
      };

      //Check for User Authentication Token. If there is one use the auth endpoint so we have a userId when logging.
      let requestPath = "survey/progress";
      try {
        let session = await Auth.currentSession();
        if (session) {
          requestPath = "survey/progress/auth";
        }
      } catch (e) {
        console.log(e);
        console.log("sending-no-auth-request");
      }

      API.get(requestPath, "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveyUpdateStatus = ({ surveyId, status }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: { surveyId, status },
      };

      API.post("survey/status", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveyUpdateNotes = ({ surveyId, notes }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: { surveyId, notes },
      };

      API.post("survey/notes", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveyUpdateAssessment = ({ surveyId, assessment }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: { surveyId, assessment },
      };

      API.post("survey/assessment", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveyUpdatePlan = ({ surveyId, plan }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: { surveyId, plan },
      };

      API.post("survey/plan", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveyUpdateVitals = ({ surveyId, vitals }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: { surveyId, vitals },
      };

      API.post("survey/vitals", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const SurveySaveProgress = ({
  surveyId,
  survey,
  phone,
  lastName,
  dateOfBirth,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = {
        response: true,
        body: {
          surveyId,
          surveyProgress: survey,
          phone,
          lastName,
          dateOfBirth,
        },
      };

      //Check for User Authentication Token. If there is one use the auth endpoint so we have a userId when logging.
      let requestPath = "survey/progress";
      try {
        let session = await Auth.currentSession();
        if (session) {
          requestPath = "survey/progress/auth";
        }
      } catch (e) {
        console.log(e);
        console.log("sending-no-auth-request");
      }

      API.post(requestPath, "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const Methods = {
  SurveyCreate,
  SurveyDelete,
  SurveyGet,
  SurveyUpdateStatus,
  SurveyUpdateNotes,
  SurveyUpdateAssessment,
  SurveyUpdatePlan,
  SurveyUpdateVitals,
  SurveyGetProgress,
  SurveysGet,
  SurveySaveProgress,
};

export default Methods;
