import { API } from "aws-amplify";

const PatientCreate = ({ createPatientData }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: createPatientData,
      };

      API.post("patient", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const PatientUpdate = ({ editPatientData }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: editPatientData,
      };

      API.put("patient", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const PatientDelete = ({ deletePatientId }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        body: { deletePatientId },
      };

      API.del("patient", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const PatientGet = ({ patientId }) => {
  return new Promise((resolve, reject) => {
    try {
      let data = {
        response: true,
        queryStringParameters: {
          patientId,
        },
      };

      API.get("patient", "", data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            reject(err.response.data.error);
          } else {
            reject("error");
          }
        });
    } catch (err) {
      reject(err);
    }
  });
};

const Methods = {
  PatientCreate,
  PatientUpdate,
  PatientGet,
  PatientDelete,
};

export default Methods;
