import moment from "moment";

const dateHelpers = {
  formatUTCEpochDateMMDDYYYY: (epoch) => {
    return moment.utc(epoch).format("MM/DD/YYYY");
  },
  formatUTCEpochDateMMDDYYYYHHMMSS: (epoch) => {
    return moment(epoch).format("MM/DD/YYYY h:mm:ss a");
  },
  MMDDYYYYtoEpochUTC: (date) => {
    const epochUTC = moment.utc(date, "MM-DD-YYYY");
    const epochUTCValue = epochUTC.valueOf();
    return epochUTCValue;
  },
};

export default dateHelpers;
