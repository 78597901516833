import styled from "styled-components";
import DatePicker from "react-date-picker";

const DatePickerContainer = styled.div``;

const HCRDatePicker = ({ value, onChange, ...props }) => {
  return (
    <DatePickerContainer>
      <DatePicker value={value} onChange={onChange} {...props} />
    </DatePickerContainer>
  );
};

export default HCRDatePicker;
