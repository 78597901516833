import { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import colors from "../../theme/colors";
import { useSelector, useDispatch } from "react-redux";
import API from "../../api";
import { Link, useParams, useLocation } from "react-router-dom";
import { DateHelper, PhoneHelper } from "../../helpers";
import PatientForm from "../../components/PatientForm";
import NewSurveyForm from "../../components/NewSurveyForm";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import ButtonLink from "../../components/Button/link";
import PageHeader from "../../components/PageHeader";
import Breadcrumbs from "../../components/BreadCrumbs";
import SurveyStatus from "../../components/SurveyStatus";
import Models from "../../models";
import { Table, Form, Dropdown, Card, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { addPopupNotification } from "../../components/PopupNotifications";

const Separator = styled.hr`
  margin: 30px 0;
`;

const PatientContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const PatientInfoContainersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0 20px;
`;

const PatientInfoContainer = styled.div`
  width: 610px;
  @media (max-width: 610px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const TableActionsDropdown = styled(Dropdown)`
  .dropdown-toggle:after {
    display: none;
  }
`;

const TableBody = styled.tbody`
  border-top: none !important;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

const TableHeaderContainerContent = styled.div`
  display: flex;
`;

const TableHeaderCell = styled.th`
  border: none;
  color: #a1a1a1 !important;
  background-color: #fafafa !important;
  font-weight: normal;
`;

const TableCell = styled.td`
  vertical-align: middle;
  white-space: nowrap;
`;

const CardBody = styled(Card.Body)`
  overflow: scroll;
`;

const CardCustom = styled(Card)`
  margin-bottom: 20px;
`;

const DashboardStatsContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
`;

const DashboardStat = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StatType = styled.div``;

const StatValue = styled.div`
  color: ${colors.purple};
  font-size: 30px;
  padding: 0 20px;
`;

const SearchBoxContainer = styled.div`
  margin-right: 10px;
`;

const PatientInfoLineItem = styled.div`
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  padding: 12px;
  margin: 12px 0;
`;

const TableNoRecordsPlaceHolderContainer = styled.div`
  margin: 20px 10px;
  color: #ccc;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const Patient = () => {
  //Redux State
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //Utils
  const { patientId } = useParams();

  //State
  const [patientLoaded, setPatientLoaded] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [crumbs, setCrumbs] = useState([]);
  const [searchQuery, setSearchQuery] = useState();

  const [showEditPatient, setShowEditPatient] = useState(false);
  const [patient, setPatient] = useState(new Models.Patient({}));
  const [editPatient, setEditPatient] = useState(new Models.Patient({}));

  const [surveys, setSurveys] = useState([]);
  const [surveysFiltered, setSurveysFiltered] = useState([]);

  const [showAddSurvey, setShowAddSurvey] = useState(false);
  const [newSurvey, setNewSurvey] = useState(new Models.Survey({}));

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
    show: false,
    survey: {},
  });

  //Data API
  const data = {
    getPatient: async () => {
      if (!patientId) {
        return;
      }
      setPatientLoaded(false);
      const _patientRes = await API.Patient.PatientGet({
        patientId,
      });
      const patientModel = new Models.Patient({ ..._patientRes });
      setPatient({ ...patientModel });
      setPatientLoaded(true);
      setEditPatient({
        ...patientModel,
        dateOfBirth: new Date(patientModel.dateOfBirth),
      });

      //set the new survey type
      const type = "AWV";
      setNewSurvey({
        ...newSurvey,
        appointmentDate: new Date(),
        sendPatientEmail: true,
        sendPatientText: true,
        type,
      });
    },
    getPatientSurveys: async () => {
      if (!patientId) {
        return;
      }

      const _surveysRes = await API.Survey.SurveysGet({
        patientId,
      });

      const sortedSurveys = _surveysRes
        .sort((a, b) => {
          return b.appointmentDate - a.appointmentDate;
        })
        .map((s) => {
          let status = "N/A";
          switch (s.status) {
            case "PENDING":
              status = "Outstanding";
              break;
            case "SUBMITTED":
              status = "Needs Review";
              break;
            case "REVIEWED":
              status = "Ready for Billing";
              break;
            case "BILLED":
              status = "Waiting Payment";
              break;
            case "PAID":
              status = "Paid";
              break;
            default:
              status = "N/A";
          }
          return {
            ...s,
            statusString: status,
          };
        });
      setSurveys(sortedSurveys);
      setSurveysFiltered(sortedSurveys);
    },
  };

  //Effects
  useEffect(() => {
    console.log("Patient Did Mount");
    data.getPatient();
    data.getPatientSurveys();
  }, []);

  useEffect(() => {
    if (
      patient &&
      patient.provider &&
      patient.organization &&
      patient.provider.name &&
      patient.organization.name
    ) {
      if (user && user.role === "ADMIN") {
        setCrumbs([
          {
            title: "Administration",
            link: `/`,
          },
          {
            title: patient.organization.name,
            link: `/organization`,
          },
          {
            title: patient.provider.name,
            link: `/provider/${patient.provider.id}`,
          },
          {
            title: `${patient.firstName} ${patient.lastName}`,
            link: `/patient/${patient.id}`,
          },
        ]);
      } else {
        setCrumbs([
          {
            title: patient.organization.name,
            link: `/organization`,
          },
          {
            title: patient.provider.name,
            link: `/provider/${patient.provider.id}`,
          },
          {
            title: `${patient.firstName} ${patient.lastName}`,
            link: `/patient/${patient.id}`,
          },
        ]);
      }
    }
  }, [patient, user]);

  useEffect(() => {
    console.log(searchQuery);
    //filter patients list

    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      const filteredSurveys = surveys.filter(
        (s) =>
          s.statusString.toLowerCase().startsWith(query) ||
          s.type.toLowerCase().startsWith(query) ||
          s.statusString.toLowerCase().startsWith(query)
      );
      console.log(filteredSurveys);
      setSurveysFiltered(filteredSurveys);
    } else {
      setSurveysFiltered(surveys);
    }
  }, [searchQuery]);

  //Handlers
  const handlers = {
    addSurveyOnClick: async () => {
      setShowAddSurvey(true);
    },
    addSurveyClose: async () => {
      setShowAddSurvey(false);
      setValidated(false);
      setValidated(false);

      setNewSurvey({
        ...newSurvey,
        appointmentDate: new Date(),
      });
    },
    addSurveySubmit: async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const form = e.currentTarget;
      const valid = form.checkValidity();
      setValidated(true);
      if (valid) {
        setSubmitting(true);
        try {
          const createSurveyData = {
            patientId: patient.id,
            sendPatientEmail: newSurvey.sendPatientEmail,
            sendPatientTextMessage: newSurvey.sendPatientText,
            appointmentDate: newSurvey.appointmentDate.getTime(),
            panels: newSurvey.panelOptions
              .filter((o) => o.selected)
              .map((o) => o.name),
          };
          console.log(createSurveyData);
          const res = await API.Survey.SurveyCreate({
            createSurveyData,
          });
          console.log(res);
          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Success!",
            message: "Survey successfully sent.",
            type: "success",
          });
          handlers.addSurveyClose();
          data.getPatientSurveys();
        } catch (err) {
          console.log(err);
          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Action Failed",
            message: "The survey was not sent, please try again.",
            type: "danger",
          });
        }
      }
    },

    deleteSurveyClick: async ({ survey }) => {
      //show confirmation Modal
      setShowDeleteConfirmModal({
        ...showDeleteConfirmModal,
        show: true,
        survey,
      });
    },
    surveyDeleteClose: async () => {
      //show confirmation Modal
      setShowDeleteConfirmModal({
        show: false,
        survey: {},
      });
    },
    surveyDeleteSubmit: async () => {
      setSubmitting(true);
      try {
        const res = await API.Survey.SurveyDelete({
          deleteSurveyId: showDeleteConfirmModal.survey.id,
        });
        console.log(res);
        setSubmitting(false);
        handlers.surveyDeleteClose();
        data.getPatientSurveys();
      } catch (err) {
        console.log(err);
        setSubmitting(false);
      }
    },
  };

  return (
    <>
      {patient ? (
        <>
          <PatientContainer>
            {patientLoaded &&
              (patient.hasNoInsurance || !patient.primaryInsurance.id) && (
                <Alert variant="danger">
                  This patient has NO insurance information on file.{" "}
                  <Link to={`/patient/edit/${patientId}`}>Edit</Link> patient
                  information to add insurance.
                </Alert>
              )}

            <Breadcrumbs crumbs={crumbs} />

            <PageHeader
              header1="Patient Profile"
              header2={`${patient.firstName} ${patient.lastName}`}
            />

            <DashboardStatsContainer>
              <DashboardStat>
                <StatType>
                  latest
                  <br /> survey
                </StatType>
                <StatValue>{`${
                  surveys.length ? surveys[0].statusString : "N/A"
                }`}</StatValue>
              </DashboardStat>
              <DashboardStat>
                <StatType>
                  latest
                  <br /> appointment
                </StatType>
                <StatValue>
                  {surveys.length
                    ? DateHelper.formatUTCEpochDateMMDDYYYY(
                        surveys[0].appointmentDate
                      )
                    : "N/A"}
                </StatValue>
              </DashboardStat>
            </DashboardStatsContainer>

            <CardCustom>
              <Card.Header style={{ background: "white" }}>
                <TableHeaderContainer>
                  <h4>Surveys</h4>

                  <TableHeaderContainerContent>
                    <SearchBoxContainer>
                      <Form.Control
                        required
                        type="text"
                        value={searchQuery}
                        placeholder="search surveys"
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </SearchBoxContainer>

                    <Button onClick={handlers.addSurveyOnClick}>
                      New Survey
                    </Button>
                  </TableHeaderContainerContent>
                </TableHeaderContainer>
              </Card.Header>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <TableHeaderCell>Appointment Date</TableHeaderCell>
                      <TableHeaderCell>Panels</TableHeaderCell>
                      <TableHeaderCell>Status</TableHeaderCell>
                      <TableHeaderCell>Submitted On</TableHeaderCell>
                      <TableHeaderCell>Created</TableHeaderCell>
                      <TableHeaderCell>Manage</TableHeaderCell>
                    </tr>
                  </thead>
                  <TableBody>
                    {surveysFiltered.map((survey, index) => {
                      return (
                        <tr key={`survey-${index}`}>
                          <TableCell>
                            <Link to={`/survey/${survey.id}`}>
                              {DateHelper.formatUTCEpochDateMMDDYYYY(
                                survey.appointmentDate
                              )}
                            </Link>
                          </TableCell>
                          <TableCell>{survey.type}</TableCell>

                          <TableCell>
                            <SurveyStatus status={survey.status}>
                              {survey.statusString}
                            </SurveyStatus>
                          </TableCell>
                          <TableCell>
                            {survey.dateSubmitted
                              ? DateHelper.formatUTCEpochDateMMDDYYYY(
                                  survey.dateSubmitted
                                )
                              : ""}
                          </TableCell>
                          <TableCell>
                            {DateHelper.formatUTCEpochDateMMDDYYYY(
                              survey.created
                            )}
                          </TableCell>
                          <TableCell>
                            <TableActionsDropdown align="end">
                              <Dropdown.Toggle
                                variant="link"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#D8D8D8",
                                    fontSize: "25px",
                                  }}
                                  size="lg"
                                  icon={faEllipsisH}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    handlers.deleteSurveyClick({ survey })
                                  }
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </TableActionsDropdown>
                          </TableCell>
                        </tr>
                      );
                    })}
                  </TableBody>
                </Table>
                {!surveys.length ? (
                  <TableNoRecordsPlaceHolderContainer>
                    <h2>There are no surveys associated with this patient</h2>
                    <div>
                      Click 'New Survey' to send a new IPPE or AWV survey to
                      this patient
                    </div>
                  </TableNoRecordsPlaceHolderContainer>
                ) : null}
              </CardBody>
            </CardCustom>

            <PatientInfoContainersWrapper>
              <PatientInfoContainer>
                <CardCustom>
                  <Card.Header style={{ background: "white" }}>
                    <TableHeaderContainer>
                      <h4>{`${patient.firstName} ${patient.lastName}`}</h4>

                      <ButtonLink to={`/patient/edit/${patientId}`}>
                        Edit
                      </ButtonLink>
                    </TableHeaderContainer>
                  </Card.Header>
                  <CardBody>
                    <PatientInfoLineItem>
                      <div>Patient First name</div>
                      <div>{patient.firstName}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Patient Middle Name</div>
                      <div>{patient.middleName}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Patient Last Name</div>
                      <div>{patient.lastName}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Phone Number</div>
                      <div>{PhoneHelper.formatPhoneNumber(patient.phone)}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Date of Birth</div>
                      <div>
                        {DateHelper.formatUTCEpochDateMMDDYYYY(
                          patient.dateOfBirth
                        )}
                      </div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Age</div>
                      <div>{patient.age}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Gender</div>
                      <div>{patient.gender}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Email</div>
                      <div>{patient.email}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Street Address</div>
                      <div>{patient.address.street}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Street Address 2</div>
                      <div>{patient.address.streetTwo}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>City</div>
                      <div>{patient.address.city}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>State</div>
                      <div>{patient.address.state}</div>
                    </PatientInfoLineItem>
                    <PatientInfoLineItem>
                      <div>Zip</div>
                      <div>{patient.address.zip}</div>
                    </PatientInfoLineItem>
                  </CardBody>
                </CardCustom>
              </PatientInfoContainer>

              <PatientInfoContainer>
                <CardCustom>
                  <Card.Header style={{ background: "white" }}>
                    <TableHeaderContainer>
                      <h4>Insurance Information</h4>

                      <ButtonLink to={`/patient/edit/${patientId}`}>
                        Edit
                      </ButtonLink>
                    </TableHeaderContainer>
                  </Card.Header>
                  <CardBody>
                    {patientLoaded &&
                      (patient.hasNoInsurance ||
                        !patient.primaryInsurance.id) && (
                        <Alert variant="danger">
                          This patient has no insurance on file
                        </Alert>
                      )}

                    {!patient.hasNoInsurance && patient.primaryInsurance.id && (
                      <>
                        <PatientInfoLineItem>
                          <div>Primary Insurer</div>
                          <div>{patient.primaryInsurance.insurerName}</div>
                        </PatientInfoLineItem>
                        <PatientInfoLineItem>
                          <div>Primary Insurance Type</div>
                          <div>{patient.primaryInsurance.type}</div>
                        </PatientInfoLineItem>
                        <PatientInfoLineItem>
                          <div>Primary Insurance ID</div>
                          <div>{patient.primaryInsurance.id}</div>
                        </PatientInfoLineItem>
                        <PatientInfoLineItem>
                          <div>Primary Insurance Plan Name</div>
                          <div>{patient.primaryInsurance.planName}</div>
                        </PatientInfoLineItem>
                        <PatientInfoLineItem>
                          <div>Primary Insurance Plan Type</div>
                          <div>{patient.primaryInsurance.planType}</div>
                        </PatientInfoLineItem>

                        {patient.hasSecondaryInsurance && (
                          <>
                            <Separator/>
                            <PatientInfoLineItem>
                              <div>Secondary Insurer</div>
                              <div>
                                {patient.secondaryInsurance.insurerName}
                              </div>
                            </PatientInfoLineItem>
                            <PatientInfoLineItem>
                              <div>Secondary Insurance Type</div>
                              <div>{patient.secondaryInsurance.type}</div>
                            </PatientInfoLineItem>
                            <PatientInfoLineItem>
                              <div>Secondary Insurance ID</div>
                              <div>{patient.secondaryInsurance.id}</div>
                            </PatientInfoLineItem>
                            <PatientInfoLineItem>
                              <div>Secondary Insurance Plan Name</div>
                              <div>{patient.secondaryInsurance.planName}</div>
                            </PatientInfoLineItem>
                            <PatientInfoLineItem>
                          <div>Secondary Insurance Plan Type</div>
                          <div>{patient.secondaryInsurance.planType}</div>
                        </PatientInfoLineItem>
                          </>
                        )}
                      </>
                    )}
                  </CardBody>
                </CardCustom>
              </PatientInfoContainer>
            </PatientInfoContainersWrapper>
          </PatientContainer>

          <Modal
            show={showEditPatient}
            headerText={"Edit Patient Info"}
            onClose={handlers.editPatientClose}
          >
            <PatientForm
              validated={validated}
              onCancel={handlers.editPatientClose}
              onSubmit={handlers.editPatientSubmit}
              submitting={submitting}
              patient={editPatient}
              setPatient={setEditPatient}
            />
          </Modal>

          <Modal
            show={showAddSurvey}
            headerText={"New Survey"}
            onClose={handlers.addSurveyClose}
          >
            <NewSurveyForm
              validated={validated}
              onCancel={handlers.addSurveyClose}
              onSubmit={handlers.addSurveySubmit}
              submitting={submitting}
              patient={patient}
              survey={newSurvey}
              setSurvey={setNewSurvey}
            />
          </Modal>

          <Modal
            show={showDeleteConfirmModal.show}
            headerText={"Delete Survey"}
            onClose={handlers.surveyDeleteClose}
          >
            <h5>Are you sure you want to delete this Survey?</h5>
            <br />
            <div>
              Appointment Date:{" "}
              {`${DateHelper.formatUTCEpochDateMMDDYYYY(
                showDeleteConfirmModal.survey.appointmentDate
              )}`}
            </div>
            <SubmitContainer>
              <CancelButton onClick={handlers.surveyDeleteClose}>
                Cancel
              </CancelButton>
              <Button
                disabled={submitting}
                onClick={handlers.surveyDeleteSubmit}
              >
                Delete
              </Button>
            </SubmitContainer>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default Patient;
