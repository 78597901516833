import styled from "styled-components";
import colors from "../../theme/colors";

const getStyles = ({ status }) => {
  console.log(status);
  switch (status) {
    case "PENDING":
      return `color: ${colors.surveyStatusPendingColor}; background: ${colors.surveyStatusPendingBackground}; border: 1px solid ${colors.surveyStatusPendingColor};
      `;
    case "SUBMITTED":
      return `color: ${colors.surveyStatusSubmittedColor}; background: ${colors.surveyStatusSubmittedBackground}; border: 1px solid ${colors.surveyStatusSubmittedColor};`;
    case "REVIEWED":
      return `color: ${colors.surveyStatusReviewedColor}; background:  ${colors.surveyStatusReviewedBackground}; border: 1px solid ${colors.surveyStatusReviewedColor};
        `;
    case "BILLED":
      return `color: ${colors.surveyStatusBilledColor}; background:  ${colors.surveyStatusBilledBackground}; border: 1px solid ${colors.surveyStatusBilledColor};`;
    case "PAID":
      return `color: ${colors.surveyStatusPaidColor}; background:  ${colors.surveyStatusPaidBackground}; border: 1px solid ${colors.surveyStatusPaidColor};`;
    default:
      return `color: ${colors.surveyStatusDefaultColor}; background:  ${colors.surveyStatusDefaultBackground}; border: 1px solid ${colors.surveyStatusDefaultColor};`;
  }
};

export default styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  ${({ status }) => getStyles({ status })}
`;
