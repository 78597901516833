import { Form } from "react-bootstrap";
import styled from "styled-components";
import Button from "../Button";

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const ErrorContainer = styled.div`
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const SurveyVitals = ({
  validated,
  onSubmit,
  onCancel,
  submitting,
  submittingError,
  editVitals,
  setEditVitals,
}) => {
  return (
    <Form noValidate validated={validated} onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="vitals.temperature">
        <Form.Label>Temperature (F)</Form.Label>
        <Form.Control
          type="text"
          maxLength="10"
          value={editVitals.vitals.temperature}
          placeholder="enter temperature"
          onChange={(e) => {
            setEditVitals({
              ...editVitals,
              vitals: { ...editVitals.vitals, temperature: e.target.value },
            });
          }}
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="vitals.height">
        <Form.Label>Height (Inches)</Form.Label>
        <Form.Control
          type="text"
          maxLength="10"
          value={editVitals.vitals.height}
          placeholder="enter height"
          onChange={(e) => {
            setEditVitals({
              ...editVitals,
              vitals: { ...editVitals.vitals, height: e.target.value },
            });
          }}
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="vitals.weight">
        <Form.Label>Weight (lbs)</Form.Label>
        <Form.Control
          type="text"
          maxLength="10"
          value={editVitals.vitals.weight}
          placeholder="enter weight"
          onChange={(e) => {
            setEditVitals({
              ...editVitals,
              vitals: { ...editVitals.vitals, weight: e.target.value },
            });
          }}
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="vitals.bmi">
        <Form.Label>BMI</Form.Label>
        <Form.Control
          type="text"
          maxLength="10"
          value={editVitals.vitals.bmi}
          placeholder="enter BMI"
          onChange={(e) => {
            setEditVitals({
              ...editVitals,
              vitals: { ...editVitals.vitals, bmi: e.target.value },
            });
          }}
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="vitals.bloodpressure">
        <Form.Label>Blood Pressure</Form.Label>
        <Form.Control
          type="text"
          maxLength="10"
          value={editVitals.vitals.bloodpressure}
          placeholder="enter blood pressure"
          onChange={(e) => {
            setEditVitals({
              ...editVitals,
              vitals: { ...editVitals.vitals, bloodpressure: e.target.value },
            });
          }}
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="vitals.visualacuity">
        <Form.Label>Visual Acuity</Form.Label>
        <Form.Control
          type="text"
          value={editVitals.vitals.visualacuity}
          placeholder="enter visual acuity"
          onChange={(e) => {
            setEditVitals({
              ...editVitals,
              vitals: { ...editVitals.vitals, visualacuity: e.target.value },
            });
          }}
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <SubmitContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <Button type="submit" disabled={submitting}>
          Save
        </Button>
      </SubmitContainer>
      <ErrorContainer>{submittingError}</ErrorContainer>
    </Form>
  );
};

export default SurveyVitals;
