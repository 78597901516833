import { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../../api";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import { Table, Form, Dropdown, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { addPopupNotification } from "../../components/PopupNotifications";
import { useSelector, useDispatch } from "react-redux";

const AdminContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TableActionsDropdown = styled(Dropdown)`
  .dropdown-toggle:after {
    display: none;
  }
`;

const TableBody = styled.tbody`
  border-top: none !important;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

const TableHeaderCell = styled.th`
  border: none;
  color: #a1a1a1 !important;
  background-color: #fafafa !important;
  font-weight: normal;
`;

const TableCell = styled.td`
  vertical-align: middle;
  white-space: nowrap;
`;

const CardBody = styled(Card.Body)`
  overflow: scroll;
`;

const CardCustom = styled(Card)`
  margin-bottom: 50px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const AdminDashboard = () => {
  //Redux
  const dispatch = useDispatch();

  //State
  const [showAddOrganization, setShowAddOrganization] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [newOrganization, setNewOrganization] = useState({
    name: "",
  });

  const GetOrganizations = async () => {
    const _organizations = await API.Organization.OrganizationsGetAll();
    setOrganizations(_organizations);
  };

  useEffect(() => {
    console.log("Admin Did Mount");
    GetOrganizations();
  }, []);

  //ORGANIZATION Handlers
  const handleAddOrganiationClick = () => {
    setShowAddOrganization(true);
  };
  const handleAddOrganiationClose = () => {
    setShowAddOrganization(false);
    setValidated(false);
    setSubmitting(false);
    setNewOrganization({ name: "" });
  };
  const handleAddOrganizationSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const createOrganizationData = {
      name: newOrganization.name,
    };

    const form = e.currentTarget;
    const valid = form.checkValidity();
    setValidated(true);
    if (valid) {
      setSubmitting(true);
      try {
        const res = await API.Organization.OrganizationCreate({
          createOrganizationData,
        });
        console.log(res);
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Success!",
          message: "Organization was created.",
          type: "success",
        });
        handleAddOrganiationClose();
        GetOrganizations();
      } catch (err) {
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Action Failed",
          message: "Organization was not created, please try again.",
          type: "danger",
        });
        console.log(err);
      }
    } else {
      addPopupNotification({
        dispatch,
        title: "Warning",
        message: "Please check required fields.",
        type: "warning",
      });
    }
  };

  return (
    <AdminContainer>
      <PageHeader header1="Admin" header2="Manage Organizations" />

      <CardCustom>
        <Card.Header style={{ background: "white" }}>
          <TableHeaderContainer>
            <h4>Organizations</h4>
            <Button onClick={handleAddOrganiationClick}>
              Add Organization
            </Button>
          </TableHeaderContainer>
        </Card.Header>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <TableHeaderCell>Organization Name</TableHeaderCell>
                <TableHeaderCell>Providers</TableHeaderCell>
                <TableHeaderCell>Staff</TableHeaderCell>
                <TableHeaderCell>Patients</TableHeaderCell>
                <TableHeaderCell>Surveys</TableHeaderCell>
                <TableHeaderCell>Date Created</TableHeaderCell>
                {/* <TableHeaderCell>Manage</TableHeaderCell> */}
              </tr>
            </thead>
            <TableBody>
              {organizations.map((organization, index) => {
                return (
                  <tr key={`org-${index}`}>
                    <TableCell>
                      <Link
                        to={`/organization`}
                        onClick={() => {
                          localStorage.organizationId = organization.id;
                          localStorage.organizationName = organization.name;
                        }}
                      >
                        {organization.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {(organization.providers &&
                        organization.providers.length) ||
                        "0"}
                    </TableCell>
                    <TableCell>
                      {(organization.staff && organization.staff.length) || "0"}
                    </TableCell>
                    <TableCell>{organization.patientCount || "0"}</TableCell>
                    <TableCell>{organization.surveyCount || "0"}</TableCell>
                    <TableCell>
                      {new Date(organization.created).toLocaleString("en-US")}
                    </TableCell>
                    {/* <TableCell>
                      <TableActionsDropdown align="end">
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                          <FontAwesomeIcon
                            style={{ color: "#D8D8D8", fontSize: "25px" }}
                            size="lg"
                            icon={faEllipsisH}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>Disable</Dropdown.Item>
                        </Dropdown.Menu>
                      </TableActionsDropdown>
                    </TableCell> */}
                  </tr>
                );
              })}
            </TableBody>
          </Table>
        </CardBody>
      </CardCustom>

      <Modal
        show={showAddOrganization}
        headerText={"Add New Organization"}
        onClose={handleAddOrganiationClose}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handleAddOrganizationSubmit}
        >
          <Form.Group className="mb-3" controlId="addOrganizationForm.name">
            <Form.Label>Organization Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="enter name"
              value={newOrganization.name}
              onChange={(e) => setNewOrganization({ name: e.target.value })}
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
          <SubmitContainer>
            <CancelButton onClick={handleAddOrganiationClose}>
              Cancel
            </CancelButton>
            <Button type="submit" disabled={submitting}>
              Save
            </Button>
          </SubmitContainer>
        </Form>
      </Modal>
    </AdminContainer>
  );
};

export default AdminDashboard;
