class DefaultState {
  constructor(messages = []) {
    this.popupMessages = messages;
  }
}
class PopUpNotificationMessage {
  constructor({ id, message, type = "success" }) {
    this.type = type;
    this.id = id;
    this.message = message;
  }
}

const PopupNotificationsReducer = (state = new DefaultState(), action) => {
  switch (action.type) {
    case "POPUP_NOTIFICATION_ADD":
      var newState = { ...state };
      const message = new PopUpNotificationMessage({
        id: action.data.id,
        message: action.data.message,
        type: action.data.type,
      });
      newState.popupMessages.push(message);
      setTimeout(function () {
        action.data.remove(message.id);
      }, 3000);
      return newState;
    case "POPUP_NOTIFICATION_REMOVE":
      const index = state.popupMessages.findIndex(
        (p) => p.id === action.data.id
      );
      if (index > -1) {
        return {
          ...state,
          popupMessages: [
            ...state.popupMessages.slice(0, index),
            ...state.popupMessages.slice(index + 1),
          ],
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default PopupNotificationsReducer;
