import { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../theme/colors";
import { useSelector, useDispatch } from "react-redux";
import API from "../../api";
import { Link, useParams } from "react-router-dom";
import { DateHelper } from "../../helpers";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import LinkButton from "../../components/Button/link";
import PageHeader from "../../components/PageHeader";
import Breadcrumbs from "../../components/BreadCrumbs";
import SurveyStatus from "../../components/SurveyStatus";
import { Table, Form, Dropdown, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { addPopupNotification } from "../../components/PopupNotifications";

const ProviderContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const TableActionsDropdown = styled(Dropdown)`
  .dropdown-toggle:after {
    display: none;
  }
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

const TableHeaderContainerContent = styled.div`
  display: flex;
`;

const TableHeaderCell = styled.th`
  border: none;
  color: #a1a1a1 !important;
  background-color: #fafafa !important;
  font-weight: normal;
`;

const TableBody = styled.tbody`
  border-top: none !important;
`;

const TableCell = styled.td`
  vertical-align: middle;
  white-space: nowrap;
`;

const CardBody = styled(Card.Body)`
  overflow: scroll;
`;

const CardCustom = styled(Card)`
  margin-bottom: 50px;
`;

const DashboardStatsContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  gap: 50px;
`;

const DashboardStat = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StatType = styled.div``;

const StatValue = styled.div`
  color: ${colors.purple};
  font-size: 45px;
  padding: 0 20px;
`;

const SearchBoxContainer = styled.div`
  margin-right: 10px;
`;

const Provider = () => {
  //Redux State
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //Utils
  const { providerId } = useParams();

  //State
  const [submitting, setSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(false);
  const [crumbs, setCrumbs] = useState([]);
  const [patientSearchQuery, setPatientSearchQuery] = useState();

  const [provider, setProvider] = useState({
    provider: {},
    patients: [],
    filteredPatients: [],
    surveys: [],
    organization: {},
  });

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
    show: false,
    patient: {},
  });

  //Effects
  useEffect(() => {
    console.log("Provider Did Mount");
    data.getProvider();
  }, []);

  useEffect(() => {
    if (
      provider &&
      provider.provider &&
      provider.organization &&
      provider.provider.name &&
      provider.organization.name
    ) {
      if (user && user.role === "ADMIN") {
        setCrumbs([
          {
            title: "Administration",
            link: `/`,
          },
          {
            title: provider.organization.name,
            link: `/organization`,
          },
          {
            title: provider.provider.name,
            link: `/provider/${provider.provider.id}`,
          },
        ]);
      } else {
        setCrumbs([
          {
            title: provider.organization.name,
            link: `/organization`,
          },
          {
            title: provider.provider.name,
            link: `/provider/${provider.provider.id}`,
          },
        ]);
      }
    }
  }, [provider, user]);

  useEffect(() => {
    //filter patients list

    if (patientSearchQuery) {
      const query = patientSearchQuery.toLowerCase();
      const filteredPatients = provider.patients.filter((p) => {
        let include = false;
        if (
          p.firstName.toLowerCase().startsWith(query) ||
          p.lastName.toLowerCase().startsWith(query) ||
          p.email.toLowerCase().startsWith(query) ||
          p.statusString.toLowerCase().startsWith(query)
        ) {
          include = true;
        }

        if (!p.hasNoInsurance && p.primaryInsurance && p.primaryInsurance.id) {
          if (p.primaryInsurance.id.startsWith(query)) {
            include = true;
          }
        }

        return include;
      });
      console.log(filteredPatients);
      setProvider({
        ...provider,
        filteredPatients,
      });
    } else {
      setProvider({
        ...provider,
        filteredPatients: provider.patients,
      });
    }
  }, [patientSearchQuery]);

  //Data API
  const data = {
    getProvider: async () => {
      if (!providerId) {
        return;
      }
      const _provider = await API.Provider.ProviderGet({
        providerId,
      });

      const patients = _provider.patients.map((patient) => {
        let status = "N/A";
        switch (patient.latestStatus) {
          case "PENDING":
            status = "Outstanding";
            break;
          case "SUBMITTED":
            status = "Needs Review";
            break;
          case "REVIEWED":
            status = "Ready for Billing";
            break;
          case "BILLED":
            status = "Waiting Payment";
            break;
          case "PAID":
            status = "Paid";
            break;
          default:
            status = "N/A";
        }
        return {
          ...patient,
          statusString: status,
        };
      });

      _provider.patients = patients;

      console.log(_provider);
      setProvider({
        ...provider,
        ..._provider,
        filteredPatients: _provider.patients,
      });
    },
  };

  //Handlers
  const handlers = {
    deletePatientClick: async ({ patient }) => {
      //show confirmation Modal
      setShowDeleteConfirmModal({
        ...showDeleteConfirmModal,
        show: true,
        patient,
      });
    },
    patientDeleteClose: async () => {
      //show confirmation Modal
      setShowDeleteConfirmModal({
        show: false,
        patient: {},
      });
    },
    patientDeleteSubmit: async () => {
      setSubmitting(true);
      try {
        const res = await API.Patient.PatientDelete({
          deletePatientId: showDeleteConfirmModal.patient.id,
        });
        console.log(res);
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Success!",
          message: "Your patient was deleted.",
          type: "success",
        });
        handlers.patientDeleteClose();
        data.getProvider();
      } catch (err) {
        console.log(err);
        setSubmitting(false);
        switch (err) {
          default:
            addPopupNotification({
              dispatch,
              title: "Action Failed",
              message: "Your patient was not deleted. Please try again.",
              type: "danger",
            });
            setSubmittingError("Error Deleting");
            break;
        }
      }
    },
  };

  return (
    <>
      {provider ? (
        <>
          <ProviderContainer>
            <Breadcrumbs crumbs={crumbs} />

            <PageHeader header1="Provider" header2={provider.provider.name} />

            <DashboardStatsContainer>
              <DashboardStat>
                <StatType>
                  Number of
                  <br /> Patients
                </StatType>
                <StatValue>{provider.patients.length}</StatValue>
              </DashboardStat>
              <DashboardStat>
                <StatType>
                  Total Outstanding <br />
                  Surveys
                </StatType>
                <StatValue>{provider.outstandingSurveyCount || "0"}</StatValue>
              </DashboardStat>
            </DashboardStatsContainer>

            <CardCustom>
              <Card.Header style={{ background: "white" }}>
                <TableHeaderContainer>
                  <h4>Patients</h4>

                  <TableHeaderContainerContent>
                    <SearchBoxContainer>
                      <Form.Control
                        required
                        type="text"
                        value={patientSearchQuery}
                        placeholder="search patients"
                        onChange={(e) => setPatientSearchQuery(e.target.value)}
                      />
                    </SearchBoxContainer>
                    <LinkButton to={`/provider/${providerId}/addpatient`}>
                      New Patient
                    </LinkButton>
                  </TableHeaderContainerContent>
                </TableHeaderContainer>
              </Card.Header>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <TableHeaderCell>First Name</TableHeaderCell>
                      <TableHeaderCell>Last Name</TableHeaderCell>
                      <TableHeaderCell>Survey Status</TableHeaderCell>
                      <TableHeaderCell>Primary Insurance ID</TableHeaderCell>
                      <TableHeaderCell>Email</TableHeaderCell>
                      <TableHeaderCell>DOB</TableHeaderCell>
                      <TableHeaderCell>Gender</TableHeaderCell>
                      <TableHeaderCell>Manage</TableHeaderCell>
                    </tr>
                  </thead>
                  <TableBody>
                    {provider.filteredPatients.map((patient, index) => {
                      return (
                        <tr key={`patient-${patient.id}`}>
                          <TableCell>
                            <Link to={`/patient/${patient.id}`}>
                              {patient.firstName}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={`/patient/${patient.id}`}>
                              {patient.lastName}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <SurveyStatus status={patient.latestStatus}>
                              {patient.statusString}
                            </SurveyStatus>
                          </TableCell>
                          <TableCell>
                            {!patient.hasNoInsurance &&
                              patient.primaryInsurance &&
                              patient.primaryInsurance.id && (
                                <>{patient.primaryInsurance.id}</>
                              )}
                            {(patient.hasNoInsurance ||
                              !patient.primaryInsurance) && <>No Insurance</>}
                          </TableCell>
                          <TableCell>{patient.email}</TableCell>
                          <TableCell>
                            {DateHelper.formatUTCEpochDateMMDDYYYY(
                              patient.dateOfBirth
                            )}
                          </TableCell>
                          <TableCell>{patient.gender}</TableCell>
                          <TableCell>
                            <TableActionsDropdown align="end">
                              <Dropdown.Toggle
                                variant="link"
                                id="dropdown-basic"
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#D8D8D8",
                                    fontSize: "25px",
                                  }}
                                  size="lg"
                                  icon={faEllipsisH}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    handlers.deletePatientClick({ patient })
                                  }
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </TableActionsDropdown>
                          </TableCell>
                        </tr>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardBody>
            </CardCustom>
          </ProviderContainer>

          <Modal
            show={showDeleteConfirmModal.show}
            headerText={"Delete Patient"}
            onClose={handlers.patientDeleteClose}
          >
            <h5>Are you sure you want to delete this Patient?</h5>
            <br />
            <div>
              Name:{" "}
              {`${showDeleteConfirmModal.patient.firstName} ${showDeleteConfirmModal.patient.lastName}`}
            </div>
            <div>Email: {showDeleteConfirmModal.patient.email}</div>
            <SubmitContainer>
              <CancelButton onClick={handlers.patientDeleteClose}>
                Cancel
              </CancelButton>
              <Button
                disabled={submitting}
                onClick={handlers.patientDeleteSubmit}
              >
                Delete
              </Button>
            </SubmitContainer>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default Provider;
