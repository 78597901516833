import { Auth } from "aws-amplify";
import { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import HCRpathLogoImage from "../../theme/images/hcrpath-logo.svg";
import Modal from "../Modal";
import Button from "../Button";
import colors from "../../theme/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

const NavigationWrapper = styled.div``;

const HCRpathLogoImageContainer = styled.img`
  max-width: 60px;
`;

const TopLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
  font-size: 12px;
  background: ${(props) =>
    props.showAdminNav ? colors.purple : "transparent"};
  a {
    text-decoration: none;
    color: ${(props) => (props.showAdminNav ? "white" : "#7f7f7f")};
  }
`;
const NavItem = styled(Nav.Item)`
  position: relative;
  margin: 0 20px;
  @media (max-width: 575px) {
    margin: 10px 0;
  }
`;

const NavLink = styled(Link)`
  position: relative;
  text-decoration: none;
  user-select: none;
  color: #7f7f7f;
  font-size: 14px;
  &:hover {
    color: #000000;
  }
  &.active::after {
    content: "";
    height: 4px;
    margin: 10px 0;
    width: 100%;
    background-color: ${colors.purple};
    position: absolute;
    bottom: -29px;
    left: 0;
    border-radius: 2px;
    @media (max-width: 575px) {
      bottom: 0px;
    }
  }
  @media (max-width: 575px) {
    margin: 0;
    padding: 20px 0px;
  }
`;

const CustomNavDropdownItem = styled(NavDropdown.Item)`
  &:active {
    background-color: #ccc !important;
  }
  &:hover {
    background: white;
    color: black;
  }
`;

const NavDropdownCustom = styled(NavDropdown)`
  font-size: 14px;
  padding: 0;
  > a {
    padding: 0 !important;
    &:after {
      vertical-align: top;
      margin-top: 10px;
    }
  }
`;

const NavDropdownTitle = styled.div`
  display: inline-block;
`;

const NavDropdownTitleName = styled.div`
  color: #000000;
  text-align: right;
`;

const NavDropdownTitleRole = styled.div`
  color: #a2a5b3;
  text-align: right;
  font-size: 12px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const KnowledgeBaseLink = styled.a`
  padding-right: 22px;
`;

const SupportModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} headerText={"Support"} onClose={handleClose}>
      <p>
        Need some help? You can email us at{" "}
        <a href="mailto:support@hcrpath.com">support@hcrpath.com</a>
      </p>
      <SubmitContainer>
        <Button onClick={handleClose}>Close</Button>
      </SubmitContainer>
    </Modal>
  );
};

const NormalNavBar = ({
  user,
  isAdmin,
  location,
  pathPieces,
  signOut,
  organizationId,
}) => {
  return (
    <Navbar
      style={{ padding: "10px 30px" }}
      collapseOnSelect
      expand="sm"
      bg="white"
      variant="light"
    >
      <Navbar.Brand>
        <Link to="/">
          <HCRpathLogoImageContainer
            src={HCRpathLogoImage}
            alt="HCRpath Logo"
          />
        </Link>
      </Navbar.Brand>
    </Navbar>
  );
};

const Navigation = () => {
  const [showSupportModal, setShowSupportModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  let pathPieces = location.pathname.split("/").filter((p) => p);

  let organizationId = user && user.organizationId ? user.organizationId : null;
  // if (pathPieces[0] === "organization") {
  //   organizationId = pathPieces[1];
  // }

  if (user && user.role) {
    user.roleString =
      user.role[0].toUpperCase() + user.role.toLowerCase().slice(1);
  }

  //is Admin Area
  const isAdmin = user.role === "ADMIN";
  const showAdminNav = pathPieces[0] === "admin" && isAdmin;

  const signOut = async () => {
    try {
      await Auth.signOut();
      //clear redux store
      dispatch({ type: "AUTH_USER_LOGOUT" });
      window.location.replace("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <NavigationWrapper>
      <SupportModal
        show={showSupportModal}
        handleClose={() => setShowSupportModal(false)}
      />
      <TopLinksWrapper showAdminNav={showAdminNav}>
        <div></div>
        <div>
          <KnowledgeBaseLink
            rel="noreferrer"
            alt="HCRpath Knowledge Base"
            href="https://www.hcrpath.com/knowledgebase/"
            target="_blank"
          >
            <FontAwesomeIcon
              style={{
                fontSize: "14px",
                marginRight: "5px",
              }}
              size="lg"
              icon={faQuestionCircle}
            />
            Knowledge Base
          </KnowledgeBaseLink>
          <Link to="#" onClick={() => setShowSupportModal(true)}>
            Support
          </Link>
        </div>
      </TopLinksWrapper>
      <NormalNavBar
        user={user}
        isAdmin={isAdmin}
        location={location}
        pathPieces={pathPieces}
        signOut={signOut}
        organizationId={organizationId}
      />
    </NavigationWrapper>
  );
};

export default Navigation;
