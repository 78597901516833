import { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../theme/colors";
import API from "../../api";
import { useParams } from "react-router-dom";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import DateInput from "../../components/DateInput";
import { Form, Card, ProgressBar } from "react-bootstrap";
import { DateHelper } from "../../helpers";
import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { addPopupNotification } from "../../components/PopupNotifications";
import { useSelector, useDispatch } from "react-redux";

const CustomAsyncTypeahead = styled(AsyncTypeahead)`
  width: 235px;
  input {
    height: 28px !important;
  }
  .dropdown-item {
    font-size: 14px !important;
  }
`;

const WelcomeHeader = styled.div`
  color: ${colors.purple};
  font-size: 30px;
  text-align: center;
  margin: 30px 0;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
  h4 {
    font-size: 20px;
    padding: 0 20px 5px 0;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
`;

const TableHeaderContainerContent = styled.div`
  display: flex;
  justify-content: center;
`;

const CardContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: auto;
`;

const CardBody = styled(Card.Body)`
  overflow: visible;
`;

const CardCustom = styled(Card)`
  margin-bottom: 50px;
`;

const CustomCardBody = styled.div`
  font-size: 14px;
  margin: 10px 0;
  color: ${(props) => props.color || colors.black};
`;

const CardHeader = styled(Card.Header)`
  background-color: white;
  padding: 15px 0;
`;

const CustomeCardTitle = styled.h4`
  color: ${(props) => props.color || colors.black};
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ErrorContainer = styled.div`
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const PatientInfoLineItem = styled.div`
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  padding: 12px;
  margin: 12px 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button:not(:first-of-type) {
    margin-left: 10px;
  }
`;

//SURVEY Components

const SurveyPanelContainer = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 20px;
`;

const SurveyTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SurveyTitleLabel = styled.div`
  font-weight: bold;
`;

const SurveyPatientLabel = styled.div`
  margin-left: 6px;
`;

const ProgressContainer = styled.div`
  margin: 20px 0px;
  .progress-bar {
    background-color: ${colors.green};
  }
`;

const SectionQuestionsContainer = styled.div`
  margin: 10px 0;
`;

const SectionContainer = styled.div`
  padding: 4px;
`;

const QuestionContainer = styled.div`
  padding: 15px 0 10px 0;
`;

const QuestionTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const SectionTitle = styled.div`
  font-size: 18px;
`;

const SectionSubTitle = styled.div`
  margin: 15px 0;
  font-size: 17px;
  font-weight: bold;
`;

const SectionBody = styled.div`
  max-width: 580px;
`;

const MultipleSelectContainer = styled.div`
  display: grid;
  gap: 6px 30px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
`;

const SingleSelectContainer = styled.div``;

const TextInput = styled.input.attrs({
  type: "text",
})`
  width: ${(props) => props.width || "100%"};
  border: 1px solid #ccc;
  border-width: 1px;
  border-radius: 4px;
`;

const SelectBox = styled.select`
  min-width: 200px;
  height: 24px;
  margin: 10px 0;
`;

const StyledCheckbox = styled.input.attrs((props) => ({
  type: props.type,
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const StyledCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin: 10px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    span {
      border-width: 1px;
    }
  }
`;

const StyledCheckboxCheckmark = styled.span`
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid ${colors.purple};
  background-color: ${(props) => (props.checked ? colors.purple : "#fff")};
  border-radius: 50%;
`;

const StyledCheckboxLabel = styled.span`
  width: 100%;
  border: ${(props) =>
    props.checked ? `1px solid ${colors.purple}` : "1px solid #F1EDFF"};
  padding: 7px;
  border-radius: 5px;
`;

const DynamicQuestionRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 10px 0;
  border-bottom: 1px solid #ccc;
  padding: 0 0 10px 0;
`;

const DynamicQuestionCellContainer = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const DynamicQuestionLabel = styled.label`
  font-size: 13px;
`;

const PatientSurvey = () => {
  //redux
  const dispatch = useDispatch();

  let { surveyId, lastName, phone, dateOfBirth } = useParams();

  if (dateOfBirth) {
    try {
      dateOfBirth = DateHelper.formatUTCEpochDateMMDDYYYY(
        parseInt(dateOfBirth)
      );
    } catch (err) {
      console.log(err);
      dateOfBirth = "";
    }
  }

  const [patientLastNameToVerify, setPatientLastNameToVerify] = useState(
    lastName
  );
  const [patientPhoneToVerify, setPatientPhoneToVerify] = useState(phone);
  const [patientDateOfBirthToVerify, setPatientDateOfBirthToVerify] = useState(
    dateOfBirth
  );

  const [validated, setValidated] = useState();
  const [submitting, setSubmitting] = useState();
  const [verified, setVerified] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [submitSurvey, setSubmitSurvey] = useState(false);

  const [showMentalHealthOutro, setShowMentalHealthOutro] = useState(false);
  const [showHearingHealthOutro, setShowHearingHealthOutro] = useState(false);

  const [medicationAutoFillList, setMedicationAutoFillList] = useState([]);
  const [loadingMedications, setLoadingMedications] = useState(false);

  const [currentPosition, setCurrentPosition] = useState({
    panel: 0,
    page: 0,
  });

  const [survey, setSurvey] = useState({});

  const dobYearsDropdownOptions = (() => {
    const currentYear = new Date().getFullYear();
    let years = [];
    let year = 1800;
    while (year <= currentYear) {
      years.push(year++);
    }
    years.sort((a, b) => b - a);
    return years;
  })();

  useEffect(() => {
    console.log("Survey Did Mount");
  }, []);

  useEffect(() => {
    console.log(currentPosition);
  }, [currentPosition]);

  useEffect(() => {
    const submit = async () => {
      const dateOfBirth = DateHelper.MMDDYYYYtoEpochUTC(
        patientDateOfBirthToVerify
      );
      const _survey = await API.Survey.SurveySaveProgress({
        surveyId,
        phone: patientPhoneToVerify,
        lastName: patientLastNameToVerify,
        dateOfBirth,
        survey,
      });
      setSurvey({ ..._survey });
      setSubmitSurvey(false);
      setSubmitting(false);
    };
    if (
      submitSurvey &&
      survey.complete &&
      patientPhoneToVerify &&
      patientLastNameToVerify &&
      patientDateOfBirthToVerify &&
      surveyId &&
      !submitting
    ) {
      setSubmitting(true);
      submit();
    }
  }, [
    submitSurvey,
    survey,
    surveyId,
    patientPhoneToVerify,
    patientLastNameToVerify,
    patientDateOfBirthToVerify,
    submitting,
  ]);

  useEffect(() => {
    if (!survey.panels) {
      return;
    }
    //set shoehearinghealth outro
    const cedraPanel = survey.panels.find((p) => p.type === "CEDRA");
    if (cedraPanel) {
      setShowHearingHealthOutro(true);
    } else {
      setShowHearingHealthOutro(false);
    }
    //set show mental health outro
    const phq9Panel = survey.panels.find((p) => p.type === "PHQ9");
    if (phq9Panel) {
      //Find risk answers
      if(phq9Panel.pages[0].sections[0].questions.find( q => q.options.find( o => o.value == q.answer) && q.options.find( o => o.value == q.answer).risk )) {
        setShowMentalHealthOutro(true);
      }else {
        setShowMentalHealthOutro(false);
      }
    } else {
      setShowMentalHealthOutro(false);
    }
  }, [survey]);

  const handlers = {
    submitPatientVerification: async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const form = e.currentTarget;
      const valid = form.checkValidity();
      setValidated(true);
      if (valid) {
        setSubmitting(true);
        try {
          const dateOfBirth = DateHelper.MMDDYYYYtoEpochUTC(
            patientDateOfBirthToVerify
          );
          const getProgressData = {
            surveyId: surveyId,
            phone: patientPhoneToVerify,
            lastName: patientLastNameToVerify,
            dateOfBirth,
          };

          console.log(getProgressData);
          const _survey = await API.Survey.SurveyGetProgress(getProgressData);

          addPopupNotification({
            dispatch,
            title: "Success!",
            message: "Verification passed.",
            type: "success",
          });

          setSurvey(_survey);

          setVerified(true);

          setSubmitting(false);
        } catch (err) {
          console.log(err);
          setVerified(false);
          setSubmitting(false);
          switch (err) {
            default:
              addPopupNotification({
                dispatch,
                title: "Action Failed",
                message: "Verification failed, please try again.",
                type: "danger",
              });

              break;
          }
        }
      } else {
        addPopupNotification({
          dispatch,
          title: "Warning",
          message: "Please check required fields.",
          type: "warning",
        });
      }
    },
    submitSurvey: async () => {
      setSurvey({ ...survey, complete: true });
      setSubmitSurvey(true);
      setShowSubmitted(true);
      window.scrollTo(0, 0);
    },
    startSurvey: async () => {
      setShowWelcome(false);
      window.scrollTo(0, 0);
    },
    nextPage: async () => {
      //save progress
      setSubmitting(true);

      //check if on last page on of panel
      const currentPanel = survey.panels[currentPosition.panel];
      if (currentPanel.pages.length === currentPosition.page + 1) {
        survey.panels[currentPosition.panel].complete = true;
        setSurvey({
          ...survey,
        });
      }

      const dateOfBirth = DateHelper.MMDDYYYYtoEpochUTC(
        patientDateOfBirthToVerify
      );

      const _survey = await API.Survey.SurveySaveProgress({
        surveyId,
        phone: patientPhoneToVerify,
        lastName: patientLastNameToVerify,
        dateOfBirth,
        survey,
      });

      addPopupNotification({
        dispatch,
        title: "Success!",
        message: "Survey progress saved.",
        type: "success",
      });

      setSurvey(_survey);
      setSubmitting(false);

      //Next Page
      const pageLength = _survey.panels[currentPosition.panel].pages.length - 1;
      //If we are on the last page move to the next panel
      if (pageLength === currentPosition.page) {
        //if there isnt' another panel submit the survey
        if (_survey.panels.length - 1 === currentPosition.panel) {
          handlers.submitSurvey();
        } else {
          //Move to the next Panel
          setCurrentPosition({
            panel: currentPosition.panel + 1,
            page: 0,
          });
        }
      } else {
        //Move to the next page
        if (showWelcome) {
          setShowWelcome(false);
        } else {
          setCurrentPosition({
            ...currentPosition,
            page: currentPosition.page + 1,
          });
        }
      }

      window.scrollTo(0, 0);
    },
    backPage: async () => {
      //If we are on the first page move to the previous panel
      if (currentPosition.page === 0) {
        //Move to the pervious Panel
        const targetPanel =
          currentPosition.panel - 1 < 0 ? 0 : currentPosition.panel - 1;
        if (currentPosition.panel === 0) {
          setShowWelcome(true);
        } else {
          setCurrentPosition({
            panel: targetPanel,
            page: survey.panels[targetPanel].pages.length - 1,
          });
        }
      } else {
        //Move to the pervious page
        setCurrentPosition({
          ...currentPosition,
          page: currentPosition.page - 1,
        });
      }

      window.scrollTo(0, 0);
    },
    medicationNameChange: async (query) => {
      setLoadingMedications(true);
      setMedicationAutoFillList([]);
      const medications = await API.Medication.MedicationSearch({
        query,
      });
      setMedicationAutoFillList(medications);
      setLoadingMedications(false);
      console.log(medications);
    },
  };

  const renderQuestion = ({ sectionIndex, questionIndex, question }) => {
    if (
      question.requiredGender &&
      question.requiredGender !== survey.patient.gender
    ) {
      return;
    }

    switch (question.type) {
      case "multiple-select":
        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <MultipleSelectContainer>
              {question.options.map((option, optionIndex) => {
                if (
                  option.requiredGender &&
                  option.requiredGender !== survey.patient.gender
                ) {
                  return null;
                }
                return (
                  <StyledCheckboxContainer
                    key={`survey.question-${questionIndex}.${optionIndex}`}
                    checked={option.answer}
                  >
                    <StyledCheckboxLabel checked={option.answer}>
                      {option.title}
                    </StyledCheckboxLabel>
                    <StyledCheckbox
                      type="checkbox"
                      value={option.value}
                      checked={option.answer}
                      onChange={(e) => {
                        survey.panels[currentPosition.panel].pages[
                          currentPosition.page
                        ].sections[sectionIndex].questions[
                          questionIndex
                        ].options[optionIndex].answer = e.target.checked;

                        setSurvey({ ...survey });
                      }}
                    />
                    <StyledCheckboxCheckmark
                      checked={option.answer}
                    ></StyledCheckboxCheckmark>
                  </StyledCheckboxContainer>
                );
              })}
            </MultipleSelectContainer>
          </QuestionContainer>
        );
      case "single-select":
        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <SingleSelectContainer>
              {question.options.map((option, optionIndex) => {
                const checked = question.answer == option.value;
                return (
                  <StyledCheckboxContainer
                    key={`survey.question-${questionIndex}.${optionIndex}`}
                    checked={checked}
                  >
                    <StyledCheckboxLabel checked={checked}>
                      {option.title}
                    </StyledCheckboxLabel>
                    <StyledCheckbox
                      type="radio"
                      value={option.value}
                      checked={checked}
                      onChange={(e) => {
                        survey.panels[currentPosition.panel].pages[
                          currentPosition.page
                        ].sections[sectionIndex].questions[
                          questionIndex
                        ].answer = e.target.value;

                        setSurvey({ ...survey });
                      }}
                    />
                    <StyledCheckboxCheckmark
                      checked={checked}
                    ></StyledCheckboxCheckmark>
                  </StyledCheckboxContainer>
                );
              })}
            </SingleSelectContainer>
          </QuestionContainer>
        );
      case "year-dropdown":
        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <SelectBox
              value={question.answer}
              onChange={(e) => {
                survey.panels[currentPosition.panel].pages[
                  currentPosition.page
                ].sections[sectionIndex].questions[questionIndex].answer =
                  e.target.value;

                console.log(e.target.value);
                setSurvey({ ...survey });
              }}
            >
              <option>Select Year</option>
              {dobYearsDropdownOptions.map((year) => {
                return (
                  <option key={`survey.question-${questionIndex}.${year}`}>
                    {year}
                  </option>
                );
              })}
            </SelectBox>
          </QuestionContainer>
        );
      case "dynamic":
        if (question.answers.length === 0) {
          let question =
            survey.panels[currentPosition.panel].pages[currentPosition.page]
              .sections[sectionIndex].questions[questionIndex];
          const newRow = JSON.parse(JSON.stringify(question.options));
          question.answers.push(newRow);
          setSurvey({ ...survey });
        }

        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <div>
              {question.answers.map((answers, answersIndex) => {
                return (
                  <DynamicQuestionRowContainer
                    key={`survey.question-${questionIndex}.${answersIndex}`}
                  >
                    {answers.map((answer, answerIndex) => {
                      return (
                        <DynamicQuestionCellContainer
                          key={`survey.question-${questionIndex}.${answersIndex}.${answerIndex}`}
                        >
                          <DynamicQuestionLabel>
                            {answer.title}
                          </DynamicQuestionLabel>
                          {answer.title === "Medication Name" ? (
                            <CustomAsyncTypeahead
                              filterBy={() => true}
                              size={"small"}
                              id="async-medication-name"
                              isLoading={loadingMedications}
                              labelKey="medication-name"
                              minLength={1}
                              onSearch={(value) => {
                                let question =
                                  survey.panels[currentPosition.panel].pages[
                                    currentPosition.page
                                  ].sections[sectionIndex].questions[
                                    questionIndex
                                  ];

                                const med = value;

                                question.answers[answersIndex][
                                  answerIndex
                                ].value = med;

                                setSurvey({ ...survey });

                                handlers.medicationNameChange(med);
                              }}
                              options={medicationAutoFillList}
                              placeholder="Search for a Medication..."
                              defaultInputValue={answer.value}
                              onChange={(e) => {
                                let question =
                                  survey.panels[currentPosition.panel].pages[
                                    currentPosition.page
                                  ].sections[sectionIndex].questions[
                                    questionIndex
                                  ];

                                const med = e[0];
                                question.answers[answersIndex][
                                  answerIndex
                                ].value = med;

                                setSurvey({ ...survey });
                              }}
                            />
                          ) : (
                            <TextInput
                              width="150px"
                              value={answer.value}
                              onChange={(e) => {
                                let question =
                                  survey.panels[currentPosition.panel].pages[
                                    currentPosition.page
                                  ].sections[sectionIndex].questions[
                                    questionIndex
                                  ];

                                question.answers[answersIndex][
                                  answerIndex
                                ].value = e.target.value;

                                setSurvey({ ...survey });
                              }}
                            />
                          )}
                        </DynamicQuestionCellContainer>
                      );
                    })}
                    <DynamicQuestionCellContainer>
                      <Button
                        padding="2px 10px"
                        onClick={() => {
                          let question =
                            survey.panels[currentPosition.panel].pages[
                              currentPosition.page
                            ].sections[sectionIndex].questions[questionIndex];
                          question.answers.splice(answersIndex, 1);
                          setSurvey({ ...survey });
                        }}
                      >
                        Remove
                      </Button>
                    </DynamicQuestionCellContainer>
                  </DynamicQuestionRowContainer>
                );
              })}
            </div>
            <Button
              backgroundcolor={colors.white}
              bordercolor={colors.purple}
              fontcolor={colors.purple}
              onClick={() => {
                let question =
                  survey.panels[currentPosition.panel].pages[
                    currentPosition.page
                  ].sections[sectionIndex].questions[questionIndex];
                const newRow = JSON.parse(JSON.stringify(question.options));
                question.answers.push(newRow);
                setSurvey({ ...survey });
              }}
            >
              {question.buttonTitle}
            </Button>
          </QuestionContainer>
        );
      case "text-input":
        return (
          <QuestionContainer>
            <QuestionTitle>{question.title}</QuestionTitle>
            <TextInput
              value={question.answer}
              onChange={(e) => {
                survey.panels[currentPosition.panel].pages[
                  currentPosition.page
                ].sections[sectionIndex].questions[questionIndex].answer =
                  e.target.value;

                setSurvey({ ...survey });
              }}
            />
          </QuestionContainer>
        );
      default:
        return null;
    }
  };

  if (!verified) {
    return (
      <SurveyPanelContainer>
        <WelcomeHeader>Welcome to HCRpath</WelcomeHeader>
        <CardContainerContainer>
          <CardCustom>
            <CardBody>
              <Form
                noValidate
                validated={validated}
                onSubmit={handlers.submitPatientVerification}
              >
                <CardHeader>
                  <CustomeCardTitle>Verify Your Identity</CustomeCardTitle>
                </CardHeader>

                <CustomCardBody>
                  You have been sent this survey because you have an upcoming
                  Annual Wellness Visit with your healthcare provider. In order
                  to save time at your appointment, we ask that you complete
                  this survey ahead of time at your convenience.
                </CustomCardBody>

                <CustomCardBody>
                  <b>
                    To start your Annual Wellness Visit survey, please verify
                    your identity by providing the information below and hitting
                    ‘Submit.’
                  </b>
                </CustomCardBody>
                <br />

                <Form.Group className="mb-3" controlId="verify.lastName">
                  <Form.Label>Patient Last Name*</Form.Label>
                  <Form.Control
                    required
                    minLength={1}
                    type="text"
                    value={patientLastNameToVerify}
                    placeholder="Enter Last Name"
                    onChange={(e) => setPatientLastNameToVerify(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Patient Last Name required
                  </Form.Control.Feedback>
                </Form.Group>

                <PhoneNumberInput
                  value={patientPhoneToVerify}
                  label="Patient Phone Number*"
                  onChange={({
                    formattedPhoneNumber,
                    unformattedPhoneNumber,
                  }) => setPatientPhoneToVerify(unformattedPhoneNumber)}
                />

                <DateInput
                  controlId="verify.dateofbirth"
                  label="Patient Date of Birth*"
                  value={patientDateOfBirthToVerify}
                  onChange={(value) => {
                    setPatientDateOfBirthToVerify(value);
                  }}
                />

                <SubmitContainer>
                  <Button type="submit" disabled={submitting}>
                    Submit
                  </Button>
                </SubmitContainer>
              </Form>
            </CardBody>
          </CardCustom>
        </CardContainerContainer>
      </SurveyPanelContainer>
    );
  } else if (verified && showWelcome) {
    return (
      <SurveyPanelContainer>
        <WelcomeHeader>Your Annual Wellness Visit Survey</WelcomeHeader>
        <CardContainerContainer>
          <CardCustom>
            <CardBody>
              <Form
                noValidate
                validated={validated}
                onSubmit={handlers.submitPatientVerification}
              >
                <CardHeader>
                  <CustomeCardTitle>Let's Get Started</CustomeCardTitle>
                </CardHeader>

                <CustomCardBody>
                  <b>Welcome, {survey.patient.firstName}</b>
                </CustomCardBody>

                <CustomCardBody color={colors.purple}>
                  <b>You should expect this survey to take about 20 minutes.</b>
                </CustomCardBody>

                <CustomCardBody>
                  The following information will help your doctor review your
                  historical health information, understand your current health
                  and highlight any risk factors. Your information will be saved
                  reducing the amount of paperwork for future office visits.
                  Based on your answers, you may be dynamically served
                  additional survey panels to collect more information.
                </CustomCardBody>

                <CustomCardBody color={colors.purple}>
                  Call 911 immediately if you believe you are experiencing a
                  medical emergency. Do not rely on communication through this
                  website for urgent medical needs.
                </CustomCardBody>

                <hr />

                <CustomCardBody>
                  Check to make sure the information below is correct. If it is
                  not, please continue with the survey and notify our staff
                  during your visit.
                </CustomCardBody>

                <CardBody>
                  <PatientInfoLineItem>
                    <div>Patient Name</div>
                    <div>
                      {survey.patient.firstName} {survey.patient.lastName}
                    </div>
                  </PatientInfoLineItem>
                  <PatientInfoLineItem>
                    <div>Date of Birth</div>
                    <div>
                      {DateHelper.formatUTCEpochDateMMDDYYYY(
                        survey.patient.dateOfBirth
                      )}
                    </div>
                  </PatientInfoLineItem>
                  <PatientInfoLineItem>
                    <div>Gender</div>
                    <div>{survey.patient.gender}</div>
                  </PatientInfoLineItem>
                </CardBody>

                <SubmitContainer>
                  <Button onClick={handlers.startSurvey}>Start Survey</Button>
                </SubmitContainer>
              </Form>
            </CardBody>
          </CardCustom>
        </CardContainerContainer>
      </SurveyPanelContainer>
    );
  } else if (verified && showSubmitted) {
    return (
      <SurveyPanelContainer>
        <div>
          <SurveyTitleContainer>
            <SurveyTitleLabel>Annual Wellness Visit Survey</SurveyTitleLabel>
            <SurveyPatientLabel>
              | {survey.patient.firstName} {survey.patient.lastName}
            </SurveyPatientLabel>
          </SurveyTitleContainer>
          <WelcomeHeader>
            Thank you, your answers have been submitted
          </WelcomeHeader>
          <CardContainerContainer>
            {showMentalHealthOutro && (
              <CardCustom>
                <CardBody>
                  <CardHeader style={{ background: "white" }}>
                    <CustomeCardTitle color={colors.purple}>
                      Mental Health Follow-up
                    </CustomeCardTitle>
                  </CardHeader>
                  <CustomCardBody>
                    Based on your answers, it is recommened that your doctor
                    follow up with you about finding mental health resources in
                    your area.
                  </CustomCardBody>
                  <CustomCardBody>
                    <b>
                      Let your doctor know you’d like more information, or a
                      referral to a local mental health specialist.
                    </b>
                  </CustomCardBody>
                </CardBody>
              </CardCustom>
            )}
            {showHearingHealthOutro && (
              <CardCustom>
                <CardBody>
                  <CardHeader style={{ background: "white" }}>
                    <CustomeCardTitle color={colors.purple}>
                      Hearing Health Follow-up
                    </CustomeCardTitle>
                  </CardHeader>
                  <CustomCardBody>
                    Based on your answers, it is recommened that your doctor
                    follow up with you about finding audiologists and hearing
                    health resources in your area.
                  </CustomCardBody>
                  <CustomCardBody>
                    <b>
                      Let your doctor know you’d like more information, or a
                      referral to a local audiologist or hearing health
                      specialist.
                    </b>
                  </CustomCardBody>
                </CardBody>
              </CardCustom>
            )}
            <CardCustom>
              <CardBody>
                <CardHeader style={{ background: "white" }}>
                  <CustomeCardTitle>You're done!</CustomeCardTitle>
                </CardHeader>
                <CustomCardBody>
                  Your doctor will review these answers with you during your
                  Annual Wellness visit. If you have any questions or need
                  further assistance, please contact your provider.
                </CustomCardBody>
                <CustomCardBody>
                  <b>You can now close out of this page.</b>
                </CustomCardBody>
              </CardBody>
            </CardCustom>
          </CardContainerContainer>
        </div>
      </SurveyPanelContainer>
    );
  } else if (verified && survey && survey.panels) {
    const totalPages = survey.panels.reduce(
      (previousValue, currentValue, currentIndex, array) => {
        return previousValue + currentValue.pages.length;
      },
      0
    );
    const completedPanels = survey.panels.slice(0, currentPosition.panel);
    const completedPages = completedPanels.reduce(
      (previousValue, currentValue, currentIndex, array) => {
        return previousValue + currentValue.pages.length;
      },
      0
    );

    //Add one to total pages to account for outro
    const completedPercent = Math.floor(
      ((completedPages + currentPosition.page + 1) / (totalPages + 1)) * 100
    );
    return (
      <SurveyPanelContainer>
        <div>
          <SurveyTitleContainer>
            <SurveyTitleLabel>
              {survey.panels[currentPosition.panel].title}
            </SurveyTitleLabel>
            <SurveyPatientLabel>
              | {survey.patient.firstName} {survey.patient.lastName}
            </SurveyPatientLabel>
          </SurveyTitleContainer>
          <ProgressContainer>
            <ProgressBar now={completedPercent} />
          </ProgressContainer>
          <CardCustom>
            <CardBody>
              <TableHeaderContainer>
                <CustomeCardTitle>
                  {
                    survey.panels[currentPosition.panel].pages[
                      currentPosition.page
                    ].title
                  }
                </CustomeCardTitle>
                <TableHeaderContainerContent>
                  <ActionButtonsContainer>
                    <Button
                      disabled={submitting}
                      backgroundcolor={colors.white}
                      bordercolor={colors.purple}
                      fontcolor={colors.purple}
                      onClick={handlers.backPage}
                    >
                      Back
                    </Button>

                    <Button disabled={submitting} onClick={handlers.nextPage}>
                      Continue
                    </Button>
                  </ActionButtonsContainer>
                </TableHeaderContainerContent>
              </TableHeaderContainer>
              {survey.panels[currentPosition.panel].pages[
                currentPosition.page
              ].sections.map((section, sectionIndex) => {
                return (
                  <SectionContainer key={`sectionContainer-${sectionIndex}`}>
                    <SectionTitle>{section.title}</SectionTitle>
                    <SectionSubTitle>{section.subtitle}</SectionSubTitle>
                    <SectionBody>{section.body}</SectionBody>
                    <SectionQuestionsContainer>
                      {section.questions.map((question, questionIndex) => {
                        return (
                          <div key={`questionContainer-${questionIndex}`}>
                            {renderQuestion({
                              sectionIndex,
                              questionIndex,
                              question,
                            })}
                          </div>
                        );
                      })}
                    </SectionQuestionsContainer>
                  </SectionContainer>
                );
              })}
              <ActionButtonsContainer>
                <Button
                  disabled={submitting}
                  backgroundcolor={colors.white}
                  bordercolor={colors.purple}
                  fontcolor={colors.purple}
                  onClick={handlers.backPage}
                >
                  Back
                </Button>
                <Button disabled={submitting} onClick={handlers.nextPage}>
                  Continue
                </Button>
              </ActionButtonsContainer>
            </CardBody>
          </CardCustom>
        </div>
      </SurveyPanelContainer>
    );
  }
};

export default PatientSurvey;
