class Survey {
  constructor({
    id = "",
    appointmentDate = new Date(),
    sendPatientEmail = false,
    type = "IPPE",
    panels = [],
  }) {
    this.id = id;
    this.appointmentDate = appointmentDate;
    this.sendPatientEmail = sendPatientEmail;
    this.type = type;
    this.panelOptions = [
      {
        name: "AWV",
        selected: true,
      },
      {
        name: "ADL",
        selected: false,
      },
    ];
  }
}

export default Survey;
