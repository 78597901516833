import { Modal } from "react-bootstrap";
import Button from "../Button";

const HCRModal = ({ children, show, onClose, onSubmit, headerText }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{headerText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {/*
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer> */}
    </Modal>
  );
};

export default HCRModal;
