import styled from "styled-components";
import PageHeader from "../../components/PageHeader";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AccountContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const Account = () => {
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("User Did Mount");
  }, []);
  return (
    <AccountContainer>
      <PageHeader header1="Account" header2={user.name} />
    </AccountContainer>
  );
};

export default Account;
