import { Button } from "react-bootstrap";
import styled from "styled-components";
import colors from "../../theme/colors";

const CustomButton = styled(Button)`
  background-color: ${(props) => props.backgroundcolor};
  background: ${(props) => props.backgroundcolor};
  border-color: ${(props) => props.bordercolor};
  color: ${(props) => props.fontcolor};
  padding: ${(props) => props.padding};
  box-shadow: none !important;
  outline: none !important;
  white-space: nowrap;
  &:hover,
  &:active {
    opacity: 0.8;
    color: ${(props) => props.fontcolor};
    background: ${(props) => props.backgroundcolor};
    background-color: ${(props) => props.backgroundcolor};
    border-color: ${(props) => props.bordercolor};
    box-shadow: none !important;
    outline: none !important;
  }

  &:hover {
    &:active {
      color: ${(props) => props.fontcolor};
      background: ${(props) => props.backgroundcolor};
      background-color: ${(props) => props.backgroundcolor};
      border-color: ${(props) => props.bordercolor};
      box-shadow: none !important;
      outline: none !important;
    }
  }

  &:focus {
    color: ${(props) => props.fontcolor};
    background: ${(props) => props.backgroundcolor};
    background-color: ${(props) => props.backgroundcolor};
    border-color: ${(props) => props.bordercolor};
    box-shadow: none !important;
    outline: none !important;
  }
  &:disabled {
    color: ${(props) => props.fontcolor};
    background: ${(props) => props.backgroundcolor};
    background-color: ${(props) => props.backgroundcolor};
    border-color: ${(props) => props.bordercolor};
    box-shadow: none !important;
    outline: none !important;
  }
`;

const HCRButton = ({
  fontcolor = colors.white,
  backgroundcolor = colors.purple,
  bordercolor = colors.purple,
  padding = "4px 20px",
  ...props
}) => {
  return (
    <CustomButton
      fontcolor={fontcolor}
      backgroundcolor={backgroundcolor}
      bordercolor={bordercolor}
      padding={padding}
      {...props}
    />
  );
};

export default HCRButton;
