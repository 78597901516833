import { useEffect } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import Button from "../Button";
import CheckboxRound from "../CheckboxRound";
import PhoneNumberInput from "../PhoneNumberInput";
import DateInput from "../DateInput";
import colors from "../../theme/colors";

const SubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const GenderContainer = styled.div`
  max-width: 290px;
`;

const RemoveButton = styled(Button)``;

const SubmitButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const InsuranceSectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: sticky;
  z-index: 999;
  top: 0px;
  background-color: ${colors.siteBackground};
`;

const InlineContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  > div {
    &:first-of-type {
      margin-right: 20px;
    }
    width: 100%;
  }
`;

const CustomForm = styled(Form)`
  max-width: 600px;
`;

const HasNoInsuranceContainer = styled.div`
  color: gray;
  margin: 20px 0;
  font-size: 14px;
`;

const InsuranceTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: 20px 0;
`;

const AddSecondaryButtonContainer = styled.div`
  margin: 40px 0;
`;

const Separator = styled.hr`
  margin: 30px 0;
`;

var states = [
  { name: "Alabama", abbrev: "AL" },
  { name: "Alaska", abbrev: "AK" },
  { name: "Arizona", abbrev: "AZ" },
  { name: "Arkansas", abbrev: "AR" },
  { name: "California", abbrev: "CA" },
  { name: "Colorado", abbrev: "CO" },
  { name: "Connecticut", abbrev: "CT" },
  { name: "Delaware", abbrev: "DE" },
  { name: "Florida", abbrev: "FL" },
  { name: "Georgia", abbrev: "GA" },
  { name: "Hawaii", abbrev: "HI" },
  { name: "Idaho", abbrev: "ID" },
  { name: "Illinois", abbrev: "IL" },
  { name: "Indiana", abbrev: "IN" },
  { name: "Iowa", abbrev: "IA" },
  { name: "Kansas", abbrev: "KS" },
  { name: "Kentucky", abbrev: "KY" },
  { name: "Louisiana", abbrev: "LA" },
  { name: "Maine", abbrev: "ME" },
  { name: "Maryland", abbrev: "MD" },
  { name: "Massachusetts", abbrev: "MA" },
  { name: "Michigan", abbrev: "MI" },
  { name: "Minnesota", abbrev: "MN" },
  { name: "Mississippi", abbrev: "MS" },
  { name: "Missouri", abbrev: "MO" },
  { name: "Montana", abbrev: "MT" },
  { name: "Nebraska", abbrev: "NE" },
  { name: "Nevada", abbrev: "NV" },
  { name: "New Hampshire", abbrev: "NH" },
  { name: "New Jersey", abbrev: "NJ" },
  { name: "New Mexico", abbrev: "NM" },
  { name: "New York", abbrev: "NY" },
  { name: "North Carolina", abbrev: "NC" },
  { name: "North Dakota", abbrev: "ND" },
  { name: "Ohio", abbrev: "OH" },
  { name: "Oklahoma", abbrev: "OK" },
  { name: "Oregon", abbrev: "OR" },
  { name: "Pennsylvania", abbrev: "PA" },
  { name: "Rhode Island", abbrev: "RI" },
  { name: "South Carolina", abbrev: "SC" },
  { name: "South Dakota", abbrev: "SD" },
  { name: "Tennessee", abbrev: "TN" },
  { name: "Texas", abbrev: "TX" },
  { name: "Utah", abbrev: "UT" },
  { name: "Vermont", abbrev: "VT" },
  { name: "Virginia", abbrev: "VA" },
  { name: "Washington", abbrev: "WA" },
  { name: "West Virginia", abbrev: "WV" },
  { name: "Wisconsin", abbrev: "WI" },
  { name: "Wyoming", abbrev: "WY" },
];

var mspCodes = [
  "12-Working Aged",
  "13-End-Stage Renal Disease (ESRD)",
  "14-No-Fault",
  "15-Worker's Comp",
  "19-WC set-aside",
  "43-Disabled",
  "47-Liability",
];

const PatientForm = ({
  validated,
  onSubmit,
  onCancel,
  submitting,
  organization,
  patient,
  setPatient,
  formUX,
  setFormUX,
  showSaveAddAnother = true,
}) => {
  let form = {};
  useEffect(() => {
    if (formUX.submitForm) {
      form.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  }, [formUX]);

  return (
    <CustomForm
      ref={(ref) => (form = ref)}
      noValidate
      validated={validated}
      onSubmit={onSubmit}
    >
      <SectionHeader>Patient Provider</SectionHeader>
      <Form.Select
        aria-label="Patient Provider"
        value={patient.providerId}
        onChange={(e) => setPatient({ ...patient, providerId: e.target.value })}
      >
        {organization.providers.map((provider) => {
          return (
            <option key={provider.id} value={provider.id}>
              {provider.name}
            </option>
          );
        })}
      </Form.Select>
      <Separator />

      <SectionHeader>Patient Information</SectionHeader>

      <Form.Group className="mb-3" controlId="addPatientForm.firstname">
        <Form.Label>Patient First Name*</Form.Label>
        <Form.Control
          required
          type="text"
          value={patient.firstName}
          placeholder="enter first name"
          onChange={(e) =>
            setPatient({ ...patient, firstName: e.target.value })
          }
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="addPatientForm.middlename">
        <Form.Label>Patient Middle Name*</Form.Label>
        <Form.Control
          required
          type="text"
          value={patient.middleName}
          placeholder="enter middle name"
          onChange={(e) =>
            setPatient({ ...patient, middleName: e.target.value })
          }
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="addPatientForm.lastname">
        <Form.Label>Patient Last Name*</Form.Label>
        <Form.Control
          required
          type="text"
          value={patient.lastName}
          placeholder="enter last name"
          onChange={(e) => setPatient({ ...patient, lastName: e.target.value })}
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <DateInput
        controlId="addPatientForm.dob"
        label="Date of Birth*"
        value={patient.dateOfBirth}
        onChange={(value) => {
          value = value || "";
          setPatient({
            ...patient,
            dateOfBirth: value,
          });
        }}
      />

      <GenderContainer>
        <Form.Group className="mb-3" controlId="addPatientForm.gender">
          <Form.Label>Gender*</Form.Label>
          <Form.Select
            required
            value={patient.gender}
            aria-label="gender"
            onChange={(e) =>
              setPatient({
                ...patient,
                gender: e.target.value,
              })
            }
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Form.Select>
          <Form.Control.Feedback></Form.Control.Feedback>
        </Form.Group>
      </GenderContainer>

      <Form.Group className="mb-3" controlId="addPatientForm.email">
        <Form.Label>Patient Email*</Form.Label>
        <Form.Control
          required
          type="email"
          value={patient.email}
          placeholder="enter email"
          onChange={(e) => setPatient({ ...patient, email: e.target.value })}
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <PhoneNumberInput
        value={patient.phone}
        label="Patient Phone Number*"
        onChange={({ formattedPhoneNumber, unformattedPhoneNumber }) =>
          setPatient({
            ...patient,
            phone: unformattedPhoneNumber,
          })
        }
      />

      <Separator />
      <SectionHeader>Patient Address</SectionHeader>

      <Form.Group className="mb-3" controlId="addPatientForm.address.street">
        <Form.Label>Street Address</Form.Label>
        <Form.Control
          // required
          type="text"
          value={patient.address.street}
          placeholder="enter street address"
          onChange={(e) =>
            setPatient({
              ...patient,
              address: { ...patient.address, street: e.target.value },
            })
          }
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="addPatientForm.address.streetTwo">
        <Form.Label>Street Address 2</Form.Label>
        <Form.Control
          // required
          type="text"
          value={patient.address.streetTwo}
          placeholder="enter street address 2"
          onChange={(e) =>
            setPatient({
              ...patient,
              address: { ...patient.address, streetTwo: e.target.value },
            })
          }
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="addPatientForm.address.city">
        <Form.Label>City</Form.Label>
        <Form.Control
          // required
          type="text"
          value={patient.address.city}
          placeholder="enter city"
          onChange={(e) =>
            setPatient({
              ...patient,
              address: { ...patient.address, city: e.target.value },
            })
          }
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="addPatientForm.address.state">
        <Form.Label>State</Form.Label>
        <Form.Select
          aria-label="State"
          value={patient.address.state}
          onChange={(e) =>
            setPatient({
              ...patient,
              address: { ...patient.address, state: e.target.value },
            })
          }
        >
          <option value="" disabled selected hidden>
            Select State...
          </option>
          {states.map((state) => {
            return (
              <option key={state.abbrev} value={state.abbrev}>
                {state.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="addPatientForm.address.zip">
        <Form.Label>Zip Code</Form.Label>
        <Form.Control
          // required
          maxLength="5"
          type="text"
          value={patient.address.zip}
          placeholder="enter zip code"
          onChange={(e) =>
            setPatient({
              ...patient,
              address: { ...patient.address, zip: e.target.value },
            })
          }
        />
        <Form.Control.Feedback></Form.Control.Feedback>
      </Form.Group>

      <Separator />
      <SectionHeader>Insurance Information</SectionHeader>
      <HasNoInsuranceContainer>
        <CheckboxRound
          value={"hasNoInsurance"}
          checked={patient.hasNoInsurance}
          key={"hasNoInsurance-checkbox"}
          label={
            "Select if patient has NO INSURANCE COVERAGE or cannot provide insurance information"
          }
          onChange={(e) => {
            setPatient({
              ...patient,
              hasNoInsurance: e.target.checked,
            });
          }}
        />
      </HasNoInsuranceContainer>

      {!patient.hasNoInsurance && (
        <>
          <InsuranceSectionHeader>
            <InsuranceTitle>Primary Insurance</InsuranceTitle>
          </InsuranceSectionHeader>
          <Form.Group
            className="mb-3"
            controlId="addPatientForm.primaryInsurance.id"
          >
            <Form.Label>Primary Insurance ID*</Form.Label>
            <Form.Control
              required
              minLength={6}
              maxLength={15}
              type="text"
              value={patient.primaryInsurance.id}
              placeholder="enter primary insurance ID"
              onChange={(e) =>
                setPatient({
                  ...patient,
                  primaryInsurance: {
                    ...patient.primaryInsurance,
                    id: e.target.value,
                  },
                })
              }
            />
            <Form.Control.Feedback type="invalid">
              Primary Insurance ID length required to be between 6 and 15
              characters
            </Form.Control.Feedback>
          </Form.Group>

          <div>Primary Insurance Type</div>

          {["medicare", "medicaid", "commercial"].map((type) => {
            return (
              <CheckboxRound
                value={type}
                checked={patient.primaryInsurance.type === type}
                key={`${type}-checkbox`}
                label={`${type[0].toUpperCase() + type.slice(1)}`}
                maxWidth="200px"
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    primaryInsurance: {
                      ...patient.primaryInsurance,
                      type: e.target.value,
                    },
                  });
                }}
              />
            );
          })}

          <Form.Group
            className="mb-3"
            controlId="addPatientForm.primaryInsurance.insurerName"
          >
            <Form.Label>Insurer Name</Form.Label>
            <Form.Control
              type="text"
              value={patient.primaryInsurance.insurerName}
              placeholder="enter insurer name"
              onChange={(e) =>
                setPatient({
                  ...patient,
                  primaryInsurance: {
                    ...patient.primaryInsurance,
                    insurerName: e.target.value,
                  },
                })
              }
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="addPatientForm.primaryInsurance.planName"
          >
            <Form.Label>Plan Name</Form.Label>
            <Form.Control
              type="text"
              value={patient.primaryInsurance.planName}
              placeholder="enter plan name"
              onChange={(e) =>
                setPatient({
                  ...patient,
                  primaryInsurance: {
                    ...patient.primaryInsurance,
                    planName: e.target.value,
                  },
                })
              }
            />
          </Form.Group>

          <div>Plan Type</div>
          {["HMO", "PPO", "POS"].map((type) => {
            return (
              <CheckboxRound
                value={type}
                checked={patient.primaryInsurance.planType === type}
                key={`${type}-checkbox`}
                label={type}
                maxWidth="200px"
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    primaryInsurance: {
                      ...patient.primaryInsurance,
                      planType: e.target.value,
                    },
                  });
                }}
              />
            );
          })}

          <Form.Group
            className="mb-3"
            controlId="addPatientForm.primaryInsurance.groupNumber"
          >
            <Form.Label>Group Number</Form.Label>
            <Form.Control
              type="text"
              value={patient.primaryInsurance.groupNumber}
              placeholder="enter group number"
              onChange={(e) =>
                setPatient({
                  ...patient,
                  primaryInsurance: {
                    ...patient.primaryInsurance,
                    groupNumber: e.target.value,
                  },
                })
              }
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="addPatientForm.primaryInsurance.primaryInsuredFirstName"
          >
            <Form.Label>Primary Insured First Name</Form.Label>
            <Form.Control
              type="text"
              value={patient.primaryInsurance.primaryInsuredFirstName}
              placeholder="enter insured first name"
              onChange={(e) =>
                setPatient({
                  ...patient,
                  primaryInsurance: {
                    ...patient.primaryInsurance,
                    primaryInsuredFirstName: e.target.value,
                  },
                })
              }
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="addPatientForm.primaryInsurance.primaryInsuredMiddleName"
          >
            <Form.Label>Primary Insured Middle Name</Form.Label>
            <Form.Control
              type="text"
              value={patient.primaryInsurance.primaryInsuredMiddleName}
              placeholder="enter insured middle name"
              onChange={(e) =>
                setPatient({
                  ...patient,
                  primaryInsurance: {
                    ...patient.primaryInsurance,
                    primaryInsuredMiddleName: e.target.value,
                  },
                })
              }
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="addPatientForm.primaryInsurance.primaryInsuredLastName"
          >
            <Form.Label>Primary Insured Last Name</Form.Label>
            <Form.Control
              type="text"
              value={patient.primaryInsurance.primaryInsuredLastName}
              placeholder="enter insured last name"
              onChange={(e) =>
                setPatient({
                  ...patient,
                  primaryInsurance: {
                    ...patient.primaryInsurance,
                    primaryInsuredLastName: e.target.value,
                  },
                })
              }
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="addPatientForm.primaryInsurance.primaryInsuredSuffixName"
          >
            <Form.Label>Primary Insured Suffix</Form.Label>
            <Form.Control
              type="text"
              value={patient.primaryInsurance.primaryInsuredSuffixName}
              placeholder="enter insured suffix"
              onChange={(e) =>
                setPatient({
                  ...patient,
                  primaryInsurance: {
                    ...patient.primaryInsurance,
                    primaryInsuredSuffixName: e.target.value,
                  },
                })
              }
            />
          </Form.Group>

          <div>Primary Insured Relationship to Patient</div>

          {["self", "parent", "spouse or partner"].map((type) => {
            return (
              <CheckboxRound
                value={type}
                checked={
                  patient.primaryInsurance.primaryRelationshipToPatient === type
                }
                key={`${type}-checkbox`}
                label={`${type[0].toUpperCase() + type.slice(1)}`}
                maxWidth="200px"
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    primaryInsurance: {
                      ...patient.primaryInsurance,
                      primaryRelationshipToPatient: e.target.value,
                    },
                  });
                }}
              />
            );
          })}

          <DateInput
            controlId="addPatientForm.primaryInsurance.primaryInsuredDateOfBirth"
            label="Primary Insured Date of Birth"
            value={patient.primaryInsurance.primaryInsuredDateOfBirth}
            onChange={(value) => {
              value = value || "";
              setPatient({
                ...patient,
                primaryInsurance: {
                  ...patient.primaryInsurance,
                  primaryInsuredDateOfBirth: value,
                },
              });
            }}
          />

          {patient.hasSecondaryInsurance ? (
            <>
              <Separator />
              <InsuranceSectionHeader>
                <InsuranceTitle>Secondary Insurance</InsuranceTitle>
                <RemoveButton
                  padding="2px 10px"
                  fontcolor={colors.red}
                  backgroundcolor={colors.white}
                  bordercolor={colors.red}
                  onClick={() => {
                    setPatient({
                      ...patient,
                      hasSecondaryInsurance: false,
                      secondaryInsurance: { type: "medicare", id: "" },
                    });
                  }}
                >
                  Remove
                </RemoveButton>
              </InsuranceSectionHeader>
              <Form.Group
                className="mb-3"
                controlId="addPatientForm.secondaryInsurance.id"
              >
                <Form.Label>Secondary Insurance ID*</Form.Label>
                <Form.Control
                  required
                  minLength={6}
                  maxLength={15}
                  type="text"
                  value={patient.secondaryInsurance.id}
                  placeholder="enter secondary insurance ID"
                  onChange={(e) =>
                    setPatient({
                      ...patient,
                      secondaryInsurance: {
                        ...patient.secondaryInsurance,
                        id: e.target.value,
                      },
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Secondary Insurance ID length required to be between 6 and 15
                  characters
                </Form.Control.Feedback>
              </Form.Group>

              <div>Secondary Insurance Type</div>

              {["medicare", "medicaid", "commercial"].map((type) => {
                return (
                  <CheckboxRound
                    value={type}
                    checked={patient.secondaryInsurance.type === type}
                    key={`${type}-checkbox`}
                    label={`${type[0].toUpperCase() + type.slice(1)}`}
                    maxWidth="200px"
                    onChange={(e) => {
                      setPatient({
                        ...patient,
                        secondaryInsurance: {
                          ...patient.secondaryInsurance,
                          type: e.target.value,
                        },
                      });
                    }}
                  />
                );
              })}

              {patient.secondaryInsurance.type === "medicare" && (
                <GenderContainer>
                  <Form.Group
                    className="mb-3"
                    controlId="addPatientForm.secondaryInsurance.mspCode"
                  >
                    <Form.Label>MSP Code*</Form.Label>
                    <Form.Select
                      required
                      value={patient.secondaryInsurance.mspCode}
                      onChange={(e) =>
                        setPatient({
                          ...patient,
                          secondaryInsurance: {
                            ...patient.secondaryInsurance,
                            mspCode: e.target.value,
                          },
                        })
                      }
                    >
                      <option value="" disabled selected hidden>
                        Select MSP Code...
                      </option>
                      {mspCodes.map((code) => {
                        return <option value={code}>{code}</option>;
                      })}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                </GenderContainer>
              )}

              <Form.Group
                className="mb-3"
                controlId="addPatientForm.secondaryInsurance.insurerName"
              >
                <Form.Label>Insurer Name</Form.Label>
                <Form.Control
                  type="text"
                  value={patient.secondaryInsurance.insurerName}
                  placeholder="enter insurer name"
                  onChange={(e) =>
                    setPatient({
                      ...patient,
                      secondaryInsurance: {
                        ...patient.secondaryInsurance,
                        insurerName: e.target.value,
                      },
                    })
                  }
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="addPatientForm.secondaryInsurance.planName"
              >
                <Form.Label>Plan Name</Form.Label>
                <Form.Control
                  type="text"
                  value={patient.secondaryInsurance.planName}
                  placeholder="enter plan name"
                  onChange={(e) =>
                    setPatient({
                      ...patient,
                      secondaryInsurance: {
                        ...patient.secondaryInsurance,
                        planName: e.target.value,
                      },
                    })
                  }
                />
              </Form.Group>

              <div>Plan Type</div>
              {["HMO", "PPO", "POS"].map((type) => {
                return (
                  <CheckboxRound
                    value={type}
                    checked={patient.secondaryInsurance.planType === type}
                    key={`${type}-checkbox`}
                    label={type}
                    maxWidth="200px"
                    onChange={(e) => {
                      setPatient({
                        ...patient,
                        secondaryInsurance: {
                          ...patient.secondaryInsurance,
                          planType: e.target.value,
                        },
                      });
                    }}
                  />
                );
              })}

              <Form.Group
                className="mb-3"
                controlId="addPatientForm.secondaryInsurance.groupNumber"
              >
                <Form.Label>Group Number</Form.Label>
                <Form.Control
                  type="text"
                  value={patient.secondaryInsurance.groupNumber}
                  placeholder="enter group number"
                  onChange={(e) =>
                    setPatient({
                      ...patient,
                      secondaryInsurance: {
                        ...patient.secondaryInsurance,
                        groupNumber: e.target.value,
                      },
                    })
                  }
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="addPatientForm.secondaryInsurance.secondaryInsuredFirstName"
              >
                <Form.Label>Primary Insured First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={patient.secondaryInsurance.primaryInsuredFirstName}
                  placeholder="enter insured first name"
                  onChange={(e) =>
                    setPatient({
                      ...patient,
                      secondaryInsurance: {
                        ...patient.secondaryInsurance,
                        primaryInsuredFirstName: e.target.value,
                      },
                    })
                  }
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="addPatientForm.secondaryInsurance.secondaryInsuredMiddleName"
              >
                <Form.Label>Primary Insured Middle Name</Form.Label>
                <Form.Control
                  type="text"
                  value={patient.secondaryInsurance.primaryInsuredMiddleName}
                  placeholder="enter insured middle name"
                  onChange={(e) =>
                    setPatient({
                      ...patient,
                      secondaryInsurance: {
                        ...patient.secondaryInsurance,
                        primaryInsuredMiddleName: e.target.value,
                      },
                    })
                  }
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="addPatientForm.secondaryInsurance.secondaryInsuredLastName"
              >
                <Form.Label>Primary Insured Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={patient.secondaryInsurance.primaryInsuredLastName}
                  placeholder="enter insured last name"
                  onChange={(e) =>
                    setPatient({
                      ...patient,
                      secondaryInsurance: {
                        ...patient.secondaryInsurance,
                        primaryInsuredLastName: e.target.value,
                      },
                    })
                  }
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="addPatientForm.secondaryInsurance.primaryInsuredSuffixName"
              >
                <Form.Label>Primary Insured Suffix</Form.Label>
                <Form.Control
                  type="text"
                  value={patient.secondaryInsurance.primaryInsuredSuffixName}
                  placeholder="enter insured suffix"
                  onChange={(e) =>
                    setPatient({
                      ...patient,
                      secondaryInsurance: {
                        ...patient.secondaryInsurance,
                        primaryInsuredSuffixName: e.target.value,
                      },
                    })
                  }
                />
              </Form.Group>

              <div>Primary Insured Relationship to Patient</div>

              {["self", "parent", "spouse or partner"].map((type) => {
                return (
                  <CheckboxRound
                    value={type}
                    checked={
                      patient.secondaryInsurance
                        .primaryRelationshipToPatient === type
                    }
                    key={`${type}-checkbox`}
                    label={`${type[0].toUpperCase() + type.slice(1)}`}
                    maxWidth="200px"
                    onChange={(e) => {
                      setPatient({
                        ...patient,
                        secondaryInsurance: {
                          ...patient.secondaryInsurance,
                          primaryRelationshipToPatient: e.target.value,
                        },
                      });
                    }}
                  />
                );
              })}

              <DateInput
                controlId="addPatientForm.secondaryInsurance.secondaryInsuredDateOfBirth"
                label="Primary Insured Date of Birth"
                value={patient.secondaryInsurance.primaryInsuredDateOfBirth}
                onChange={(value) => {
                  value = value || "";
                  setPatient({
                    ...patient,
                    secondaryInsurance: {
                      ...patient.secondaryInsurance,
                      primaryInsuredDateOfBirth: value,
                    },
                  });
                }}
              />
            </>
          ) : (
            <AddSecondaryButtonContainer>
              <Button
                fontcolor={colors.purple}
                backgroundcolor={colors.white}
                bordercolor={colors.purple}
                onClick={() => {
                  setPatient({
                    ...patient,
                    hasSecondaryInsurance: true,
                  });
                }}
              >
                + Add Secondary Insurance
              </Button>
            </AddSecondaryButtonContainer>
          )}
        </>
      )}

      <SubmitContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SubmitButtons>
          {showSaveAddAnother && (
            <Button
              fontcolor={colors.purple}
              backgroundcolor={colors.white}
              bordercolor={colors.purple}
              disabled={submitting}
              onClick={() => {
                setFormUX({
                  ...formUX,
                  addAnotherAfterSave: true,
                  submitForm: true,
                });
              }}
            >
              Save and Add Another
            </Button>
          )}

          <Button
            disabled={submitting}
            onClick={() => {
              setFormUX({
                ...formUX,
                addAnotherAfterSave: false,
                submitForm: true,
              });
            }}
          >
            Save
          </Button>
        </SubmitButtons>
      </SubmitContainer>
    </CustomForm>
  );
};

export default PatientForm;
