import { Form } from "react-bootstrap";

const DateInput = ({
  onChange,
  value,
  label = "Date",
  controlId = "x.dateInput",
}) => {
  function formatDateInput(value) {
    try {
      if (!value) return value;

      // clean the input for any non-digit values.
      const dateNumber = value.replace(/[^\d]/g, "");

      const dateNumberLength = dateNumber.length;

      if (dateNumberLength < 3) {
        return dateNumber;
      }

      if (dateNumberLength < 5) {
        return `${dateNumber.slice(0, 2)}/${dateNumber.slice(2)}`;
      }

      return `${dateNumber.slice(0, 2)}/${dateNumber.slice(
        2,
        4
      )}/${dateNumber.slice(4, 8)}`;
    } catch (err) {
      console.log(err);
      return "";
    }
  }

  function handleOnChange(e) {
    const value = formatDateInput(e.target.value);
    onChange(value);
  }

  return (
    <Form.Group className="mb-3" controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        required
        pattern="(0[1-9]|1[0-2])/(0[1-9]|1[0-9]|2[0-9]|3[0-1])/[1-2][0-9]{3}"
        type="text"
        placeholder="MM/DD/YYYY"
        onChange={(e) => handleOnChange(e)}
        value={value}
      />
      <Form.Control.Feedback></Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">
        Date required format (MM/DD/YYYY)
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default DateInput;
