class Patient {
  constructor({
    id = "",
    firstName = "",
    middleName = "",
    lastName = "",
    email = "",
    dateOfBirth = "",
    age = "",
    gender = "female",
    organizationId = "",
    providerId = "",
    created = "",
    createdByUserId = "",
    archiveStatus = false,
    surveys = [],
    organization = {},
    provider = {},
    phone = "",
    hasNoInsurance = false,
    hasSecondaryInsurance = false,
    primaryInsurance = {
      id: "",
      type: "medicare",
      insurerName: "",
      planName: "",
      planType: "HMO", //[HMO,PPO,POS]
      groupNumber: "",
      primaryInsuredSuffixName: "",
      primaryInsuredFirstName: "",
      primaryInsuredMiddleName: "",
      primaryInsuredLastName: "",
      primaryRelationshipToPatient: "self", //[self, parent, spouse or partner]
      primaryInsuredDateOfBirth: "",
    },
    secondaryInsurance = {
      id: "",
      type: "medicare",
      insurerName: "",
      planName: "",
      planType: "HMO", //[HMO,PPO,POS]
      groupNumber: "",
      primaryInsuredSuffixName: "",
      primaryInsuredFirstName: "",
      primaryInsuredMiddleName: "",
      primaryInsuredLastName: "",
      primaryRelationshipToPatient: "self", //[self, parent, spouse or partner]
      primaryInsuredDateOfBirth: "",
      mspCode: "",
    },
    address = {
      street: "",
      streetTwo: "",
      city: "",
      state: "",
      zip: "",
    },
  }) {
    this.id = id;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.email = email;
    this.name = `${firstName} ${lastName}`;
    this.dateOfBirth = dateOfBirth;
    this.age = age;
    this.gender = gender;
    this.organizationId = organizationId;
    this.providerId = providerId;
    this.created = created;
    this.createdByUserId = createdByUserId;
    this.archiveStatus = archiveStatus;
    this.surveys = surveys;
    this.filteredSurveys = surveys;
    this.organization = organization;
    this.provider = provider;
    this.phone = phone;
    this.hasNoInsurance = hasNoInsurance;
    this.hasSecondaryInsurance = hasSecondaryInsurance;
    this.primaryInsurance = primaryInsurance;
    this.secondaryInsurance = secondaryInsurance;
    this.address = address;
  }
}

export default Patient;
