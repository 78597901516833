import axios from "axios";

const MedicationSearch = ({ query }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search`,
        {
          params: {
            terms: query,
          },
        }
      );
      resolve(response.data[1]);
    } catch (err) {
      reject(err);
    }
  });
};

const Methods = {
  MedicationSearch,
};

export default Methods;
