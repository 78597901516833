import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../theme/colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const noPrintMixIn = css`
  @media print {
    * {
      display: none !important;
    }
  }
`;

const BreadCrumbsContainer = styled.div`
  display: flex;
  width: 100%;
  ${noPrintMixIn}
`;

const ListElement = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItemElement = styled.li`
  display: inline;
  font-size: 14px;
  a {
    color: #737373;
    text-decoration: none;
    &:hover {
      color: ${colors.gray};
    }
  }
  &:last-child {
    a {
      color: #c0c0c0;
    }
  }
`;

const BreadCrumbs = ({ crumbs = [] }) => {
  return (
    <BreadCrumbsContainer>
      <ListElement>
        {crumbs.map((crumb, i) => {
          return (
            <ListItemElement key={`crumb-${i}`}>
              {i !== 0 && (
                <FontAwesomeIcon
                  style={{
                    fontSize: "14px",
                    margin: "0 8px 0 8px",
                    color: "#C0C0C0",
                  }}
                  icon={faAngleRight}
                />
              )}

              <Link to={crumb.link}>{crumb.title}</Link>
            </ListItemElement>
          );
        })}
      </ListElement>
    </BreadCrumbsContainer>
  );
};

export default BreadCrumbs;
