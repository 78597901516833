import styled from "styled-components";

const HeaderContainer = styled.div`
  font-size: 20px;
  margin: 30px 0;
`;

const HeaderTwo = styled.span`
  color: #a1a1a1;
`;

const PageHeader = ({ header1, header2 }) => {
  return (
    <HeaderContainer>
      <b>{header1}</b>
      <HeaderTwo> | {header2}</HeaderTwo>
    </HeaderContainer>
  );
};

export default PageHeader;
