import User from "./user";
import Organization from "./organization";
import Provider from "./provider";
import Patient from "./patient";
import Survey from "./survey";
import Medication from "./medication";
import Activity from "./activity";

const Endpoints = {
  User,
  Organization,
  Provider,
  Patient,
  Survey,
  Medication,
  Activity
};

export default Endpoints;
