import { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../theme/colors";
import API from "../../api";
import { Link, useParams, useLocation } from "react-router-dom";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import Breadcrumbs from "../../components/BreadCrumbs";
import { Table, Form, Dropdown, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import Models from "../../models";
import { addPopupNotification } from "../../components/PopupNotifications";

const UsersContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TableActionsDropdown = styled(Dropdown)`
  .dropdown-toggle:after {
    display: none;
  }
`;

const TableBody = styled.tbody`
  border-top: none !important;
`;

const ErrorContainer = styled.div`
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  @media (max-width: 700px) {
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const TableHeaderContainerContent = styled.div`
  display: flex;
  gap: 8px;
`;

const TableHeaderCell = styled.th`
  border: none;
  color: #a1a1a1 !important;
  background-color: #fafafa !important;
  font-weight: normal;
`;

const TableCell = styled.td`
  vertical-align: middle;
  white-space: nowrap;
`;

const CardBody = styled(Card.Body)`
  overflow: scroll;
`;

const CardCustom = styled(Card)`
  margin-bottom: 50px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const DashboardStatsContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  gap: 50px;
`;

const DashboardStat = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StatType = styled.div``;

const StatValue = styled.div`
  color: ${colors.purple};
  font-size: 45px;
  padding: 0 20px;
`;

const Users = () => {
  //Redux state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  //State
  const [organizationId, setOrganizationId] = useState();
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(false);
  const [crumbs, setCrumbs] = useState([]);

  const _organization = new Models.Organization({
    id: organizationId,
  });

  const [organization, setOrganization] = useState({
    ..._organization,
  });

  const [newProvider, setNewProvider] = useState({
    name: "",
    email: "",
    npi: "",
  });
  const [newStaff, setNewStaff] = useState({
    name: "",
    email: "",
  });

  const [showAddProvider, setShowAddProvider] = useState(false);
  const [showAddStaff, setShowAddStaff] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
    show: false,
    user: {},
  });

  //Effects
  useEffect(() => {
    console.log("Users Did Mount");
  }, []);

  useEffect(() => {
    if (organizationId) {
      data.getOrganization();
    }
  }, [organizationId]);

  useEffect(() => {
    if (organization && user.role === "ADMIN" && organization.name) {
      setCrumbs([
        {
          title: "Administration",
          link: `/`,
        },
        {
          title: organization.name,
          link: `/organization`,
        },
        {
          title: "Members",
          link: `/members`,
        },
      ]);
    }
  }, [organization, user]);

  useEffect(() => {
    if (user && user.role === "ADMIN") {
      const _organizationId = localStorage.organizationId;
      if (!_organizationId) {
        window.location = "/";
        return;
      }
      setOrganizationId(_organizationId);
    } else if (user && user.organizationId) {
      setOrganizationId(user.organizationId);
    }
  }, [user]);

  //Data API
  const data = {
    getOrganization: async () => {
      if (!organizationId) {
        return;
      }
      const _organization = await API.Organization.OrganizationGet({
        organizationId,
      });

      const members = [..._organization.staff, ..._organization.providers]
        .map((member) => {
          let roleString;
          let allowDelete = false;
          switch (member.role) {
            case "ADMIN":
              roleString = "Admin";
              break;
            case "PROVIDER":
              roleString = "Provider";
              if (member.patientCount === 0) {
                allowDelete = true;
              }
              break;
            case "STAFF":
              roleString = "Staff";
              allowDelete = true;
              break;
            default:
              break;
          }

          return {
            ...member,
            roleString,
            allowDelete,
          };
        })
        .sort((a, b) => {
          //By Number
          return b.created - a.created;

          //By String
          // const nameA = a.name.toUpperCase();
          // const nameB = b.name.toUpperCase();
          // if (nameA < nameB) {
          //   return -1;
          // }
          // if (nameA > nameB) {
          //   return 1;
          // }
          // return 0;
        });

      const pendingInvites = members.filter((m) => m.invitePending).length;

      const organizationModel = {
        ..._organization,
        members,
        pendingInvites,
      };
      setOrganization(organizationModel);
    },
  };

  //Handlers
  const handlers = {
    addProviderOnClick: async () => {
      setShowAddProvider(true);
    },
    addProviderClose: async () => {
      setShowAddProvider(false);
      setValidated(false);
      setSubmittingError("");
      setNewProvider({
        name: "",
        email: "",
        npi: "",
      });
    },
    addProviderSubmit: async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const createUserData = {
        name: newProvider.name,
        email: newProvider.email,
        npi: newProvider.npi,
        role: "PROVIDER",
        organizationId: organization.id,
      };

      const form = e.currentTarget;
      const valid = form.checkValidity();
      setValidated(true);
      if (valid) {
        setSubmitting(true);
        try {
          const res = await API.User.UserCreate({
            createUserData,
          });
          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Success!",
            message: "Provider added to your organization.",
            type: "success",
          });
          handlers.addProviderClose();
          data.getOrganization();
        } catch (err) {
          console.log(err);
          setSubmitting(false);
          switch (err) {
            case "UsernameExistsException":
              setSubmittingError("Account with that Email already exists");
              addPopupNotification({
                dispatch,
                title: "Action Failed",
                message:
                  "Provider was not added. Account with that email already exists. please try again.",
                type: "danger",
              });
              break;
            default:
              addPopupNotification({
                dispatch,
                title: "Action Failed",
                message: "Provider was not added, please try again.",
                type: "danger",
              });
              setSubmittingError("Error Saving");
              break;
          }
        }
      } else {
        addPopupNotification({
          dispatch,
          title: "Warning",
          message: "Please check required fields.",
          type: "warning",
        });
      }
    },
    addStaffOnClick: async () => {
      setShowAddStaff(true);
    },
    addStaffClose: async () => {
      setShowAddStaff(false);
      setValidated(false);
      setSubmittingError("");
      setSubmitting(false);
      setNewStaff({
        name: "",
        email: "",
      });
    },
    addStaffSubmit: async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const createUserData = {
        name: newStaff.name,
        email: newStaff.email,
        role: "STAFF",
        organizationId: organization.id,
      };

      const form = e.currentTarget;
      const valid = form.checkValidity();
      setValidated(true);
      if (valid) {
        setSubmitting(true);
        try {
          const res = await API.User.UserCreate({
            createUserData,
          });
          console.log(res);
          setSubmitting(false);
          addPopupNotification({
            dispatch,
            title: "Success!",
            message: "Staff member added to your organization.",
            type: "success",
          });
          handlers.addStaffClose();
          data.getOrganization();
        } catch (err) {
          console.log(err);
          setSubmitting(false);
          switch (err) {
            case "UsernameExistsException":
              setSubmittingError("Account with that Email already exists");
              addPopupNotification({
                dispatch,
                title: "Action Failed",
                message:
                  "Staff Member was not added. Account with that email already exists. please try again.",
                type: "danger",
              });
              break;
            default:
              addPopupNotification({
                dispatch,
                title: "Action Failed",
                message: "Staff member was not added, please try again.",
                type: "danger",
              });
              setSubmittingError("Error Saving");
              break;
          }
        }
      } else {
        addPopupNotification({
          dispatch,
          title: "Warning",
          message: "Please check required fields.",
          type: "warning",
        });
      }
    },

    staffDeleteClick: async ({ user }) => {
      setShowDeleteConfirmModal({
        show: true,
        user,
      });
    },
    staffDeleteClose: async () => {
      setSubmitting(false);
      setShowDeleteConfirmModal({
        show: false,
        user: {},
      });
    },
    staffDeleteSubmit: async () => {
      try {
        setSubmitting(true);
        await API.User.UserDelete({
          deleteUserId: showDeleteConfirmModal.user.id,
        });
        addPopupNotification({
          dispatch,
          title: "Success!",
          message: "Member deleted from organization.",
          type: "success",
        });
        setSubmitting(false);
        handlers.staffDeleteClose();
        data.getOrganization();
      } catch (err) {
        addPopupNotification({
          dispatch,
          title: "Action Failed",
          message:
            "Member was not deleted from organization, please try again.",
          type: "danger",
        });
      }
    },
  };

  return (
    <>
      <UsersContainer>
        <Breadcrumbs crumbs={crumbs} />
        <PageHeader
          header1="Organization Members"
          header2={organization.name}
        />

        <DashboardStatsContainer>
          <DashboardStat>
            <StatType>
              Number of
              <br /> Staff
            </StatType>
            <StatValue>
              {(organization.staff && organization.staff.length) || "0"}
            </StatValue>
          </DashboardStat>
          <DashboardStat>
            <StatType>
              Number of
              <br /> Providers
            </StatType>
            <StatValue>
              {(organization.providers && organization.providers.length) || "0"}
            </StatValue>
          </DashboardStat>
          <DashboardStat>
            <StatType>
              Pending
              <br /> Invites
            </StatType>
            <StatValue>{organization.pendingInvites || "0"}</StatValue>
          </DashboardStat>
        </DashboardStatsContainer>

        <CardCustom>
          <Card.Header style={{ background: "white" }}>
            <TableHeaderContainer>
              <h4>Organization Members</h4>
              <TableHeaderContainerContent>
                <Button
                  backgroundcolor={colors.white}
                  bordercolor={colors.purple}
                  fontcolor={colors.purple}
                  onClick={handlers.addProviderOnClick}
                >
                  Add Provider
                </Button>

                <Button onClick={handlers.addStaffOnClick}>Add Staff</Button>
              </TableHeaderContainerContent>
            </TableHeaderContainer>
          </Card.Header>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Role</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Date Created</TableHeaderCell>
                  <TableHeaderCell>Manage</TableHeaderCell>
                </tr>
              </thead>
              <TableBody>
                {organization.members.map((member, index) => {
                  return (
                    <tr key={`staff-${index}`}>
                      <TableCell>
                        {/*<Link to={`/user/${staff.id}`}>{staff.name}</Link>*/}
                        {member.name}
                      </TableCell>
                      <TableCell>{member.roleString}</TableCell>
                      <TableCell>{member.email}</TableCell>
                      <TableCell>
                        {member.invitePending ? "Invite Pending" : "Active"}
                      </TableCell>
                      <TableCell>
                        {new Date(member.created).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell>
                        <TableActionsDropdown align="end">
                          <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <FontAwesomeIcon
                              style={{ color: "#D8D8D8", fontSize: "25px" }}
                              size="lg"
                              icon={faEllipsisH}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              disabled={!member.allowDelete}
                              onClick={() =>
                                handlers.staffDeleteClick({ user: member })
                              }
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </TableActionsDropdown>
                      </TableCell>
                    </tr>
                  );
                })}
              </TableBody>
            </Table>
          </CardBody>
        </CardCustom>
      </UsersContainer>

      <Modal
        show={showAddStaff}
        headerText={"Add Staff"}
        onClose={handlers.addStaffClose}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handlers.addStaffSubmit}
        >
          <Form.Group className="mb-3" controlId="addStaffForm.name">
            <Form.Label>Staff Name</Form.Label>
            <Form.Control
              required
              type="text"
              value={newStaff.name}
              placeholder="enter name"
              onChange={(e) =>
                setNewStaff({ ...newStaff, name: e.target.value })
              }
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="addStaffForm.email">
            <Form.Label>Staff Email</Form.Label>
            <Form.Control
              required
              type="email"
              value={newStaff.email}
              placeholder="enter email"
              onChange={(e) =>
                setNewStaff({ ...newStaff, email: e.target.value })
              }
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>

          <SubmitContainer>
            <CancelButton onClick={handlers.addStaffClose}>Cancel</CancelButton>
            <Button type="submit" disabled={submitting}>
              Save
            </Button>
          </SubmitContainer>
          <ErrorContainer>{submittingError}</ErrorContainer>
        </Form>
      </Modal>

      <Modal
        show={showAddProvider}
        headerText={"Add New Provider"}
        onClose={handlers.addProviderClose}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={handlers.addProviderSubmit}
        >
          <Form.Group className="mb-3" controlId="addProviderForm.name">
            <Form.Label>Provider Name</Form.Label>
            <Form.Control
              required
              type="text"
              value={newProvider.name}
              placeholder="enter name"
              onChange={(e) =>
                setNewProvider({ ...newProvider, name: e.target.value })
              }
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="addProviderForm.email">
            <Form.Label>Provider Email</Form.Label>
            <Form.Control
              required
              type="email"
              value={newProvider.email}
              placeholder="enter email"
              onChange={(e) =>
                setNewProvider({ ...newProvider, email: e.target.value })
              }
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="addProviderForm.npi">
            <Form.Label>Provider NPI</Form.Label>
            <Form.Control
              required
              type="text"
              minLength={10}
              maxLength={10}
              value={newProvider.npi}
              placeholder="enter npi"
              onChange={(e) =>
                setNewProvider({ ...newProvider, npi: e.target.value })
              }
            />
            <Form.Control.Feedback type="invalid">
              NPI length required to be 10 characters
            </Form.Control.Feedback>
          </Form.Group>
          <SubmitContainer>
            <CancelButton onClick={handlers.addProviderClose}>
              Cancel
            </CancelButton>
            <Button type="submit" disabled={submitting}>
              Save
            </Button>
          </SubmitContainer>
          <ErrorContainer>{submittingError}</ErrorContainer>
        </Form>
      </Modal>

      <Modal
        show={showDeleteConfirmModal.show}
        headerText={"Delete Staff User"}
        onClose={handlers.staffDeleteClose}
      >
        <h5>Are you sure you want to delete this staff member?</h5>
        <br />
        <div>Name: {showDeleteConfirmModal.user.name}</div>
        <div>Email: {showDeleteConfirmModal.user.email}</div>
        <SubmitContainer>
          <CancelButton onClick={handlers.staffDeleteClose}>
            Cancel
          </CancelButton>
          <Button disabled={submitting} onClick={handlers.staffDeleteSubmit}>
            Delete
          </Button>
        </SubmitContainer>
      </Modal>
    </>
  );
};

export default Users;
