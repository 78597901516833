import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import API from "../../api";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import { Table, Form, Dropdown, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { addPopupNotification } from "../../components/PopupNotifications";

const AdminContainer = styled.div`
  min-height: 200px;
  margin: 20px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TableActionsDropdown = styled(Dropdown)`
  .dropdown-toggle:after {
    display: none;
  }
`;
const TableBody = styled.tbody`
  border-top: none !important;
`;

const ErrorContainer = styled.div`
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

const TableHeaderCell = styled.th`
  border: none;
  color: #a1a1a1 !important;
  background-color: #fafafa !important;
  font-weight: normal;
`;

const TableCell = styled.td`
  vertical-align: middle;
  white-space: nowrap;
`;

const CardBody = styled(Card.Body)`
  overflow: scroll;
`;

const CardCustom = styled(Card)`
  margin-bottom: 50px;
`;

const CancelButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const Admin = () => {
  //Redux
  const dispatch = useDispatch();
  //State
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState({
    show: false,
    user: {},
  });
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [newAdmin, setNewAdmin] = useState({
    name: "",
    email: "",
  });
  const [admins, setAdmins] = useState([]);

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingError, setSubmittingError] = useState(false);

  const GetAdmins = async () => {
    const _admins = await API.User.UserGetAdmins();
    console.log(_admins);
    setAdmins(_admins);
  };

  useEffect(() => {
    console.log("Admin Did Mount");
    GetAdmins();
  }, []);

  //ADMIN Handlers
  const handleAddAdminClick = () => {
    setShowAddAdmin(true);
  };
  const handleAddAdminClose = () => {
    setShowAddAdmin(false);
    setValidated(false);
    setSubmitting(false);
    setNewAdmin({ name: "", email: "" });
    setSubmittingError("");
  };
  const handleAdminDelete = async ({ user }) => {
    setShowDeleteConfirmModal({
      show: true,
      user,
    });
  };
  const handleCloseDeleteConfirmModal = () => {
    setShowDeleteConfirmModal({
      show: false,
      user: {},
    });
  };

  const deleteAdminUser = async () => {
    try {
      setSubmitting(true);
      const res = await API.User.UserDelete({
        deleteUserId: showDeleteConfirmModal.user.id,
      });
      setSubmitting(false);
      addPopupNotification({
        dispatch,
        title: "Success!",
        message: "Admin was deleted.",
        type: "success",
      });
      handleCloseDeleteConfirmModal();
      GetAdmins();
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      addPopupNotification({
        dispatch,
        title: "Action Failed",
        message: "Admin was not deleted. Please try again.",
        type: "danger",
      });
    }
  };

  const handleAddAdminSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const createUserData = {
      name: newAdmin.name,
      email: newAdmin.email,
      role: "ADMIN",
    };

    const form = e.currentTarget;
    const valid = form.checkValidity();
    setValidated(true);
    if (valid) {
      setSubmitting(true);
      try {
        const res = await API.User.UserCreate({
          createUserData,
        });
        console.log(res);
        setSubmitting(false);
        addPopupNotification({
          dispatch,
          title: "Success!",
          message: "Admin invite sent.",
          type: "success",
        });
        handleAddAdminClose();
        GetAdmins();
      } catch (err) {
        console.log(err);
        setSubmitting(false);
        switch (err) {
          case "UsernameExistsException":
            setSubmittingError("Account with that Email already exists");
            addPopupNotification({
              dispatch,
              title: "Action Failed",
              message:
                "Admin was not created. Account with that email already exists. please try again.",
              type: "danger",
            });
            break;
          default:
            setSubmittingError("Error Saving");
            break;
        }
      }
    } else {
      addPopupNotification({
        dispatch,
        title: "Warning",
        message: "Please check required fields.",
        type: "warning",
      });
    }
  };

  return (
    <AdminContainer>
      <PageHeader header1="Admin" header2="Manage Admin Users" />
      <CardCustom>
        <Card.Header style={{ background: "white" }}>
          <TableHeaderContainer>
            <h4>Admin Users</h4>
            <Button onClick={handleAddAdminClick}>Add Admin User</Button>
          </TableHeaderContainer>
        </Card.Header>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <TableHeaderCell>Admin Name</TableHeaderCell>
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Date Created</TableHeaderCell>
                <TableHeaderCell>Manage</TableHeaderCell>
              </tr>
            </thead>
            <TableBody>
              {admins.map((admin, index) => {
                return (
                  <tr key={`user-${index}`}>
                    <TableCell>
                      {/*<Link to={`/admin/user/${admin.id}`}>{admin.name}</Link>*/}

                      {admin.name}
                    </TableCell>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>
                      {admin.invitePending ? "Invite Pending" : "Active"}
                    </TableCell>
                    <TableCell>
                      {new Date(admin.created).toLocaleString("en-US")}
                    </TableCell>
                    <TableCell>
                      <TableActionsDropdown align="end">
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                          <FontAwesomeIcon
                            style={{ color: "#D8D8D8" }}
                            size="lg"
                            icon={faEllipsisH}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleAdminDelete({ user: admin })}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </TableActionsDropdown>
                    </TableCell>
                  </tr>
                );
              })}
            </TableBody>
          </Table>
        </CardBody>
      </CardCustom>
      <Modal
        show={showDeleteConfirmModal.show}
        headerText={"Delete Admin User"}
        onClose={handleCloseDeleteConfirmModal}
      >
        <h5>Are you sure you want to delete this user?</h5>
        <br />
        <div>Name: {showDeleteConfirmModal.user.name}</div>
        <div>Email: {showDeleteConfirmModal.user.email}</div>
        <SubmitContainer>
          <CancelButton onClick={handleCloseDeleteConfirmModal}>
            Cancel
          </CancelButton>
          <Button disabled={submitting} onClick={deleteAdminUser}>
            Delete
          </Button>
        </SubmitContainer>
      </Modal>

      <Modal
        show={showAddAdmin}
        headerText={"Add New Admin User"}
        onClose={handleAddAdminClose}
      >
        <Form noValidate validated={validated} onSubmit={handleAddAdminSubmit}>
          <Form.Group className="mb-3" controlId="addAdminForm.name">
            <Form.Label>Admin Name</Form.Label>
            <Form.Control
              required
              type="text"
              value={newAdmin.name}
              placeholder="enter name"
              onChange={(e) =>
                setNewAdmin({ ...newAdmin, name: e.target.value })
              }
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="addAdminForm.email">
            <Form.Label>Admin Email</Form.Label>
            <Form.Control
              required
              type="email"
              value={newAdmin.email}
              placeholder="enter email"
              onChange={(e) =>
                setNewAdmin({ ...newAdmin, email: e.target.value })
              }
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
          <SubmitContainer>
            <CancelButton onClick={handleAddAdminClose}>Cancel</CancelButton>
            <Button type="submit" disabled={submitting}>
              Save
            </Button>
          </SubmitContainer>
          <ErrorContainer>{submittingError}</ErrorContainer>
        </Form>
      </Modal>
    </AdminContainer>
  );
};

export default Admin;
