class User {
  constructor() {
    this.id = "";
    this.email = "";
    this.name = "";
    this.loggedIn = false;
  }
}

const UserReducer = (state = new User(), action) => {
  switch (action.type) {
    case "USER_UPDATE_USER":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default UserReducer;
