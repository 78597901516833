import Amplify, { Auth } from "aws-amplify";

let baseUrl = "";
let cognito = {};

console.log("REACT_APP_ENV", process.env.REACT_APP_ENV);

console.log("REACT_APP_API_ENDPOINT", process.env.REACT_APP_API_ENDPOINT);

console.log("REACT_APP_COGNITO_REGION", process.env.REACT_APP_COGNITO_REGION);

console.log(
  "REACT_APP_COGNITO_USER_POOL_ID",
  process.env.REACT_APP_COGNITO_USER_POOL_ID
);

console.log(
  "REACT_APP_COGNITO_APP_CLIENT_ID",
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID
);

baseUrl = process.env.REACT_APP_API_ENDPOINT;
cognito = {
  REGION: process.env.REACT_APP_COGNITO_REGION,
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
};

const config = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "us-west-2",
    activity: `${baseUrl}/activity/`,
    user: `${baseUrl}/user/`,
    userAdmins: `${baseUrl}/user/admins/`,
    organization: `${baseUrl}/organization/`,
    organizations: `${baseUrl}/organizations/`,
    provider: `${baseUrl}/provider/`,
    patient: `${baseUrl}/patient/`,
    survey: `${baseUrl}/survey/`,
    surveyStatus: `${baseUrl}/survey/status`,
    surveyNotes: `${baseUrl}/survey/notes`,
    surveyAssessment: `${baseUrl}/survey/assessment`,
    surveyPlan: `${baseUrl}/survey/plan`,
    surveyVitals: `${baseUrl}/survey/vitals`,
    surveys: `${baseUrl}/surveys/`,
    surveyProgress: `${baseUrl}/survey/progress`,
    surveyProgressAuth: `${baseUrl}/survey/progress/auth`,
  },
  cognito,
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "activity",
        endpoint: config.apiGateway.activity,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "user",
        endpoint: config.apiGateway.user,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "user/admins",
        endpoint: config.apiGateway.userAdmins,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "organization",
        endpoint: config.apiGateway.organization,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "organizations",
        endpoint: config.apiGateway.organizations,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "provider",
        endpoint: config.apiGateway.provider,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "patient",
        endpoint: config.apiGateway.patient,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "survey",
        endpoint: config.apiGateway.survey,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "survey/status",
        endpoint: config.apiGateway.surveyStatus,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "survey/notes",
        endpoint: config.apiGateway.surveyNotes,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "survey/assessment",
        endpoint: config.apiGateway.surveyAssessment,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "survey/plan",
        endpoint: config.apiGateway.surveyPlan,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "survey/vitals",
        endpoint: config.apiGateway.surveyVitals,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "surveys",
        endpoint: config.apiGateway.surveys,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
      {
        name: "survey/progress",
        endpoint: config.apiGateway.surveyProgress,
        region: config.apiGateway.REGION,
        // custom_header: async () => {
        //   let session = await Auth.currentSession();
        //   return {
        //     Authorization: session.idToken.jwtToken,
        //   };
        // },
      },
      {
        name: "survey/progress/auth",
        endpoint: config.apiGateway.surveyProgressAuth,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let session = await Auth.currentSession();
          return {
            Authorization: session.idToken.jwtToken,
          };
        },
      },
    ],
  },
});
